import { Box, Grid, Typography, useTheme } from '@mui/material';
import React, { useMemo } from 'react';

import { ICategoryTemplateProps } from '@orgnc/core/lib/themes/templates/category';

import {
  Breadcrumbs,
  FeaturedImage,
  Tag,
} from '@orgnc/core/components/themes/Elements/components';
import { ContentStyling } from '@orgnc/core/components/molecules/PageContent';
import { ClusterLayout, StackLayout } from '@orgnc/core/components/primitives';

const TbDirectoryTemplateContent: React.FunctionComponent<
  ICategoryTemplateProps
> = ({ categoryPageData, options }) => {
  // Category object
  const { category } = categoryPageData;
  // Use MUI theme for styling
  const theme = useTheme();
  const { typography } = theme;

  // Check if featured image exists
  const hasFeaturedImage = Boolean(
    category.displayOptions?.featuredImage?.sourceUrl
  );

  // Define pagination logic
  const includeBreadcrumbs = !options?.disableBreadcrumbs;
  const showSubcategories = !category.displayOptions?.hideSubcategoryPills;
  const disableTitle = Boolean(options?.disableTitle);
  const subcategoryTagVariant = String(options?.subcategoryTagVariant || '');
  const breadcrumbs = useMemo(() => {
    if (!includeBreadcrumbs) return [];

    // spread prevents mutations
    const bc = [{ text: 'Home', url: '/' }];

    if (category.ancestors?.nodes && category.ancestors.nodes.length > 0) {
      category.ancestors.nodes.forEach((ancestor) => {
        if (ancestor?.name && ancestor?.uri) {
          bc.push({ text: ancestor.name, url: ancestor.uri });
        }
      });
    }

    return bc;
  }, [category.ancestors?.nodes, includeBreadcrumbs]);
  return (
    <StackLayout space={theme.spacing(5)}>
      {/* Render the category header. */}
      {breadcrumbs.length > 0 && (
        <Box
          sx={{
            px: { xs: 3, md: 10 },
          }}
        >
          <Breadcrumbs
            separator={''}
            breadcrumbs={breadcrumbs}
            sx={{
              textTransform: 'uppercase',
              a: {
                fontWeight: 600,
                color: 'primary.main',
              },
            }}
          />
        </Box>
      )}
      <Box
        sx={{
          px: { xs: 3, md: 10 },
          [`data-rawhtml="1"] p`]: {
            maxWidth: 'unset !important',
          },
        }}
      >
        <Grid container spacing={3} alignItems="center">
          {hasFeaturedImage && (
            <Grid item xs={12} md={4}>
              <FeaturedImage
                alt={
                  category.displayOptions?.featuredImage?.altText ||
                  `${category.name || 'Category'} Image`
                }
                ratio="1:1"
                src={category.displayOptions?.featuredImage?.sourceUrl || ''}
              />
            </Grid>
          )}
          <Grid item xs={12} md={hasFeaturedImage ? 8 : 12}>
            <Box
              sx={{
                textAlign: hasFeaturedImage ? 'left' : 'left',
                display: 'flex',
                flexDirection: 'column',
                alignItems: hasFeaturedImage ? 'flex-start' : 'center',
              }}
            >
              <StackLayout space={theme.spacing(2)}>
                {!disableTitle && (
                  <Typography
                    variant="h1"
                    sx={{
                      fontFamily: typography?.fonts?.sans?.primary,
                      fontWeight: 600,
                      color: ({ palette }) => palette.text.primary,
                    }}
                  >
                    {category.name}
                  </Typography>
                )}
                {category?.description && (
                  <ContentStyling
                    enabled
                    useUncomfortableCPL={false}
                    sx={{
                      [`[data-rawhtml="1"] p`]: {
                        maxWidth: 'unset',
                        mb: 2,
                        textAlign: hasFeaturedImage ? 'left' : 'center',
                      },
                    }}
                  >
                    <Typography
                      component="div" // this can't be a <p> since description HTML may contain another <p> tag
                      dangerouslySetInnerHTML={{
                        __html: category.description,
                      }}
                      data-rawhtml="1"
                      sx={{
                        color: 'text.secondary',
                        fontFamily: typography.fonts?.sans?.primary,
                        mt: 2,
                        mb: 1,
                        alignSelf: 'center',
                        maxWidth: '100%',
                        iframe: {
                          border: 0,
                        },
                        img: {
                          mr: 2,
                        },
                        strong: {
                          display: 'flex',
                          mt: 3,
                          a: {
                            color: '#3F7BCC !important',
                            textDecoration: 'unset !important',
                            transition: '.2s ease',
                            '&:hover': {
                              filter: 'brightness(1.6)',
                            },
                          },
                        },
                      }}
                      variant="h6"
                    />
                  </ContentStyling>
                )}
                {showSubcategories &&
                categoryPageData?.category?.children?.nodes?.length ? (
                  <ClusterLayout
                    align="center"
                    justify="center"
                    space={theme.spacing(1)}
                  >
                    {category.children?.nodes?.map((tag) => (
                      <Tag
                        key={tag?.slug}
                        href={tag?.uri || ''}
                        label={tag?.name || ''}
                        variant={subcategoryTagVariant}
                      />
                    ))}
                  </ClusterLayout>
                ) : null}
              </StackLayout>
            </Box>
          </Grid>
        </Grid>
      </Box>
      {/* Section for subcategories */}
      {
        <Box sx={{ px: { xs: 3, md: 10 } }}>
          <StackLayout space={theme.spacing(6)}>
            {/* Grid layout for directory feed */}
            <Box>
              <Grid container spacing={3}>
                {category?.children?.nodes?.map((categoryItem) => {
                  if (!categoryItem) return null;

                  // First check if the child category has its own featuredImage
                  let imageUrl =
                    categoryItem.displayOptions?.featuredImage?.sourceUrl || '';
                  let imageAlt = `${categoryItem.name || 'Category'} Image`;

                  // If no featured image on category, fall back to the first post with an image
                  if (!imageUrl) {
                    // Find the first post with a featured image using find() method
                    const postWithImage = categoryItem.posts?.nodes?.find(
                      (post) => post?.featuredImage?.node?.sourceUrl
                    );

                    // Extract image URL and alt text from post
                    imageUrl =
                      postWithImage?.featuredImage?.node?.sourceUrl || '';
                    imageAlt =
                      postWithImage?.featuredImage?.node?.altText ||
                      postWithImage?.featuredImageAltText ||
                      `${categoryItem.name || 'Category'} Image`;
                  }

                  return (
                    <Grid item xs={12} sm={6} md={4} key={categoryItem.name}>
                      <Box
                        component="a"
                        href={categoryItem.uri || '#'}
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          height: '100%',
                          borderRadius: 1,
                          overflow: 'hidden',
                          cursor: 'pointer',
                          textDecoration: 'none',
                        }}
                      >
                        {/* Image Container */}
                        <Box
                          sx={{
                            position: 'relative',
                            width: '100%',
                            paddingBottom: '75%', // 4:3 ratio
                            backgroundColor: 'action.hover',
                            overflow: 'hidden',
                          }}
                        >
                          <Box
                            sx={{
                              position: 'absolute',
                              top: 0,
                              left: 0,
                              width: '100%',
                              height: '100%',
                              '& > div': {
                                width: '100%',
                                height: '100%',
                              },
                              '& img': {
                                objectFit: 'cover',
                                width: '100%',
                                height: '100%',
                              },
                            }}
                          >
                            {imageUrl && (
                              <FeaturedImage
                                ratio="4:3"
                                src={imageUrl}
                                alt={imageAlt}
                                style={{
                                  width: '100%',
                                  height: '100%',
                                  display: 'block',
                                  objectFit: 'cover',
                                }}
                              />
                            )}
                          </Box>
                        </Box>

                        {/* Category Name */}
                        <Box
                          sx={{
                            py: 3,
                            px: 0.2,
                            flexGrow: 1,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <Typography
                            variant="h5"
                            component="div"
                            sx={{
                              width: '100%',
                              fontFamily: typography?.fonts?.sans?.primary,
                              color: 'primary.main',
                              fontWeight: 600,
                              fontSize: '19px',
                              transition: 'color .25s ease',
                              '&:hover': {
                                color: 'secondary.main',
                              },
                            }}
                          >
                            {categoryItem.name}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                  );
                })}
              </Grid>
            </Box>
          </StackLayout>
        </Box>
      }
    </StackLayout>
  );
};

export default TbDirectoryTemplateContent;
