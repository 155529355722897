import { alpha, darken, getContrastRatio, lighten, Theme } from '@mui/material';
import {
  applyFonts,
  defaultThemeOptions,
} from '@orgnc/core/components/themes/Default/muiTheme';
import { IGetDefaultThemeParams } from './default-theme-type';
import { merriWeather, merriWeatherSans } from '../../utils/localFonts';

export const getDefaultTheme = (params: IGetDefaultThemeParams): Theme => {
  const { font, color } = params;
  const { primary, secondary, brand, text, error = '#ea252a' } = color;
  const {
    primary: textPrimary = '#0a0a0a',
    secondary: textSecondary = '#505050',
  } = text ?? {};
  const { main, text: brandText, highlight, listenLive = '#ea252a' } = brand;
  const headerHighlight = highlight ?? lighten(main, 0.1);

  const themeOptions = {
    ...defaultThemeOptions,
    palette: {
      ...defaultThemeOptions.palette,
      primary: {
        main: primary,
        light: lighten(primary, 0.75),
        dark: darken(primary, 0.3),
      },
      secondary: {
        main: secondary,
        light: lighten(secondary, 0.95),
        dark: darken(secondary, 0.3),
      },
      brand: {
        header: main,
        headerHighlight,
        text: brandText,
        listenLive,
      },
      text: {
        primary: textPrimary,
        secondary: textSecondary,
      },
      error: {
        main: error,
        light: alpha(error, 0.5),
        dark: alpha(error, 0.9),
        contrastText: getContrastRatio(error, '#fff') > 4.5 ? '#fff' : '#111',
      },
    },
    typography: {
      caption: {
        fontSize: '0.625rem',
      },
    },
    components: {
      MuiChip: {
        styleOverrides: {
          root: {
            variants: [
              {
                props: { variant: 'large' },
                style: {
                    fontSize: 'calc(16px + 3 * ((100vw - 600px) / 600))',
                },
              },
            ],
          },
        },
      },
    },
  };

  const theme = applyFonts(themeOptions, {
    base: `${merriWeatherSans.style.fontFamily}`,
    sans: `${merriWeatherSans.style.fontFamily}`,
    serif: `${merriWeather.style.fontFamily}`,
    ...font,
  });

  return theme;
};
