import { Box, Link, Link as MuiLink, Typography } from '@mui/material';
import { ubuntu } from '@/utils/localFonts';
import React from 'react';

import { AcfTopStoryBlockProps } from '@orgnc/core/components/blocks/Blocks/types';

import { FrameLayout } from '@orgnc/core/components/primitives';
import {
  DisablePrefetchLink,
  FastlyOptimizedImage,
} from '@orgnc/core/components/atoms';
import { formatDate } from '../../../lib/utilities';

const AcfTopStoryBlockHeroLayoutOW = ({
  area,
  className,
  frequency,
  post,
}: AcfTopStoryBlockProps) => {
  return (
    <Box
      sx={{ position: 'relative', overflow: 'hidden' }}
      component="article"
      className={className}
      data-og-block-area={area}
      data-og-block-nth={frequency}
    >
      {post.featuredImage && (
        <Box
          sx={{
            ':after': {
              content: '""',
              position: 'absolute',
              inset: 0,
              background: 'linear-gradient(to top, black 0%, transparent 45%)',
            },
          }}
        >
          <FrameLayout ratio={'16:7'}>
            <FastlyOptimizedImage
              alt={post.featuredImage?.node?.altText || ''}
              src={post.featuredImage?.node?.sourceUrl || ''}
            />
          </FrameLayout>
        </Box>
      )}
      {/* Children */}
      <Box
        sx={{
          position: 'absolute',
          inset: 0,
          display: 'flex',
          flexDirection: 'column',
          zIndex: 1,
          color: 'white',
          p: {
            md: 2,
            xs: 0,
          },
          a: {
            color: 'white',
            textDecoration: 'none',

            ':hover': {
              textDecoration: 'underline',
            },
          },
        }}
      >
        <DisablePrefetchLink passHref href={post.uri || 'a'}>
          <Box
            sx={{
              display: 'flex',
              flex: 1,
              flexDirection: 'column',
              px: 4,
              py: 2,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flex: 1,
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
            >
              {/* Article Author(s) Underline */}
              {post?.categories?.nodes?.length ? (
                <DisablePrefetchLink
                  href={post?.categories?.nodes[0]?.uri || '#'}
                  passHref
                >
                  <MuiLink
                    aria-label={post?.categories?.nodes[0]?.name || ''}
                    fontWeight={700}
                    fontSize={16}
                    sx={{
                      display: 'inline-flex',
                      fontWeight: 700,
                      textTransform: 'uppercase',
                      textDecoration: 'none',
                      alignSelf: 'flex-start',
                      px: 2.5,
                      py: 0.5,
                      '&:hover': {
                        color: 'secondary.main',
                      },
                    }}
                    variant="caption"
                  >
                    {post?.categories?.nodes[0]?.name}
                  </MuiLink>
                </DisablePrefetchLink>
              ) : null}

              {/* Title */}
              <Box>
                <DisablePrefetchLink href={post?.uri || '#'} passHref>
                  <Typography
                    aria-label={post?.title || ''}
                    component="a"
                    variant="h3"
                    fontWeight={400}
                    fontFamily={ubuntu.style.fontFamily}
                    sx={{
                      pr: {
                        lg: '40%',
                      },
                      fontSize: {
                        xs: 24,
                        md: 48,
                      },
                    }}
                  >
                    {post?.title}
                  </Typography>
                </DisablePrefetchLink>

                {/* Subtitle */}
                <Box
                  sx={{
                    alignItems: 'center',
                    display: {
                      xs: 'none',
                      sm: 'flex',
                    },
                    fontSize: {
                      xs: 10,
                      md: 14,
                    },
                  }}
                >
                  {post?.authorTags?.nodes?.length && (
                    <DisablePrefetchLink
                      href={post?.authorTags.nodes[0]?.uri || '#'}
                      passHref
                    >
                      <Link
                        aria-label={post?.authorTags.nodes[0]?.name || ''}
                        fontWeight={700}
                        padding={'20px 0'}
                        variant="caption"
                        sx={{
                          color: 'common.white',
                          transition: 'color 0.3s ease-in-out',
                          fontSize: 'inherit',
                          '&:hover': {
                            color: 'secondary.main',
                          },
                          textDecoration: 'none',
                          textTransform: 'uppercase',
                        }}
                      >
                        By {post?.authorTags.nodes[0]?.name}
                      </Link>
                    </DisablePrefetchLink>
                  )}
                  <Box
                    sx={{
                      color: 'common.white',
                      fontSize: 'inherit',
                      fontWeight: 700,
                      padding: '0 4px',
                    }}
                  >
                    ⋅
                  </Box>
                  <Box
                    sx={{
                      color: 'common.white',
                      fontSize: 'inherit',
                      fontWeight: 700,
                    }}
                  >
                    {formatDate(post?.date || '')}
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </DisablePrefetchLink>
      </Box>
    </Box>
  );
};

export default AcfTopStoryBlockHeroLayoutOW;
