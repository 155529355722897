import { gql } from 'graphql-request';
import { CategoryTemplateQuery } from '@orgnc/core/lib/themes/queries';
import {
  IDirectoryFeaturedPostsQuery,
  IDirectoryFeaturedPostsQueryVariables,
} from '@orgnc/core/generated/graphql-operations';

const query = gql`
  query DirectoryFeaturedPostsQuery($dbId: Int) {
    posts(
      where: {
        tag: "featured"
        orderby: { field: TITLE, order: ASC }
        categoryId: $dbId
        offsetPagination: { size: 50, offset: 0 }
      }
    ) {
      nodes {
        id
        title
        slug
        uri
        excerpt
        primaryCategory {
          id
          uri
          name
        }
        featuredImage {
          node {
            altText
            caption
            mediaDetails {
              width
              height
            }
            mediaItemUrl
          }
        }
      }
      pageInfo {
        offsetPagination {
          hasMore
          hasPrevious
          total
        }
      }
    }
  }
`;

const directoryFeaturedPostsQuery: CategoryTemplateQuery<
  IDirectoryFeaturedPostsQuery,
  IDirectoryFeaturedPostsQueryVariables
> = {
  name: 'directoryFeaturedPostsQuery',
  query,
  queryArguments: (contentData) => {
    return {
      dbId: contentData.databaseId || 0,
    };
  },
};

export default directoryFeaturedPostsQuery;
