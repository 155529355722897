import React, { useMemo } from 'react';

import { DisablePrefetchLink } from '@orgnc/core/components/atoms';
import {
  FeaturedImage,
  FeaturedImageButton,
} from '@orgnc/core/components/themes/Elements/components';
import { Box, Link, useTheme } from '@mui/material';
import { IArticleGridProps } from '@orgnc/core/components/blocks/AcfArticleListBlock/Layouts';
import AdsBlock from './AdsBlock/AdsBlock';

const ArticlesGalleryAndAdsLayout: React.FC<IArticleGridProps> = ({
  hasImageLazyLoad = true,
  posts,
}) => {
  const theme = useTheme();
  const primaryPosts = useMemo(() => {
    if (Array.isArray(posts)) {
      if (posts.length > 0) {
        return (
          <Box
            sx={{
              display: 'grid', // Grid layout
              gridTemplateColumns: {
                xs: '1fr', // One column on xs
                sm: 'repeat(3, 1fr)', // Three columns
              },
              gap: 3, // Adjust space between items
              padding: 0, // Remove padding from the list
            }}
          >
            {posts.map((post, index) => {
              // Create the post item component
              const postItem = (
                <DisablePrefetchLink
                  key={post.databaseId}
                  href={post.uri || ''}
                  passHref
                >
                  <Box
                    component="article"
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'flex-start',
                      position: 'relative',
                      boxSizing: 'border-box',
                      '&:before': {
                        transition: 'background-color 0.3s ease-in-out',
                        content: '""',
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                        background:
                          'linear-gradient(to top, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0))',
                        display: 'block',
                        zIndex: ({ zIndex }) => zIndex.appBar - 2,
                      },
                    }}
                  >
                    {post.featuredImage?.node?.sourceUrl && (
                      <FeaturedImageButton>
                        <FeaturedImage
                          ratio="3:4"
                          loading={hasImageLazyLoad ? 'lazy' : 'eager'}
                          src={post.featuredImage.node.sourceUrl}
                        />
                      </FeaturedImageButton>
                    )}
                    <Box
                      sx={{
                        position: 'absolute',
                        bottom: 0,
                        left: 0,
                        p: 2,
                        color: 'brand.text',
                        zIndex: ({ zIndex }) => zIndex.appBar - 1,
                      }}
                    >
                      {post?.title && (
                        <Box sx={{ lineHeight: 1 }}>
                          <DisablePrefetchLink href={post?.uri || '#'} passHref>
                            <Link
                              dangerouslySetInnerHTML={{ __html: post.title }}
                              variant={'h4'}
                              sx={{
                                transition: 'color 0.3s ease-in-out',
                                '&:hover': {
                                  color: 'primary.main',
                                },
                                textDecoration: 'none',
                                color: 'inherit',
                                fontSize: '18px',
                                fontWeight: 400,
                                fontFamily:
                                  theme.typography?.fonts?.serif?.primary,
                                lineHeight: 1,
                              }}
                            />
                          </DisablePrefetchLink>
                        </Box>
                      )}
                    </Box>
                  </Box>
                </DisablePrefetchLink>
              );

              // After the 3rd post (index 2), render both the ad and the post
              if (index === 3) {
                const adsKey = `ad-after-post-${post.databaseId}`;
                return (
                  <React.Fragment key={`fragment-${post.databaseId}`}>
                    <AdsBlock key={adsKey} />
                    {postItem}
                  </React.Fragment>
                );
              }

              // For all other posts, just render the post
              return postItem;
            })}
          </Box>
        );
      }
    }

    return null;
  }, [hasImageLazyLoad, posts, theme.typography?.fonts?.serif?.primary]);

  if (!primaryPosts) return null;

  return primaryPosts;
};

export default ArticlesGalleryAndAdsLayout;
