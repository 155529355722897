import { Box, Card, IconButton } from '@mui/material';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  PropsWithChildren,
} from 'react';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { DisablePrefetchLink } from '@orgnc/core/components/atoms';
import {
  FeaturedImage,
  FeaturedImageButton,
} from '@orgnc/core/components/themes/Elements/components';

import Typography from '@mui/material/Typography';
import { merriWeather } from '@/utils/localFonts';
import { RenderBlockProps } from '@orgnc/core/components/blocks/Blocks/helpers/RenderBlock';

interface BlockItem {
  image: {
    sourceUrl: string;
    title: string;
    mediaDetails: {
      width: number;
      height: number;
    };
  };
  subtitle: string;
  title: string;
  url: string;
}

interface Post {
  databaseId: number;
  uri?: string;
  title?: string;
  featuredImage?: {
    node?: {
      sourceUrl?: string;
    };
  };
  categories?: {
    nodes: Array<{
      uri?: string;
      name?: string;
    }>;
  };
}

// Define a simpler type for the custom block data
interface CustomBlock {
  acfScriptmagArticlesShowcase?: {
    items: BlockItem[];
  };
  // Include any other required properties from IBlock
  name?: string;
}

// Create extended props interface
interface ScriptmagMainArticlesShowcaseProps
  extends Omit<RenderBlockProps, 'block'> {
  posts?: Post[];
  block: CustomBlock;
}

// Create a component that accepts our props
const AcfArticleShowcaseLayout = (
  props: PropsWithChildren<ScriptmagMainArticlesShowcaseProps>
) => {
  const { block } = props;
  const hasImageLazyLoad = true;
  const posts = props?.posts;
  const containerRef = useRef<HTMLElement>(null);
  const contentRef = useRef<HTMLElement>(null);
  const itemWidth = 150;
  const [visibleItems, setVisibleItems] = useState(5);

  // Access blockItems from the block prop
  const blockItems = block?.acfScriptmagArticlesShowcase?.items;

  // Determine which data source to use
  const useBlockItems = !posts?.length && blockItems && blockItems.length > 0;

  // Calculate the number of visible items based on container width
  useEffect(() => {
    const handleResize = () => {
      if (containerRef.current) {
        const containerWidth = containerRef.current.offsetWidth;
        const newVisibleItems = Math.max(
          1,
          Math.floor(containerWidth / itemWidth)
        );
        setVisibleItems(newVisibleItems);
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Handle left/right scroll
  const handleScroll = useCallback(
    (direction: number) => {
      if (contentRef.current) {
        const scrollAmount = direction * itemWidth * visibleItems; // Scroll by the width of visible items
        contentRef.current.scrollLeft += scrollAmount;
      }
    },
    [visibleItems]
  );

  const renderCarousel = useMemo(() => {
    // If we have no data to render, return null
    if (
      (!posts || posts.length === 0) &&
      (!blockItems || blockItems.length === 0)
    ) {
      return null;
    }

    return (
      <Box
        ref={containerRef}
        sx={{
          position: 'relative',
          width: useBlockItems ? 'calc(100% - 60px)' : '100%',
          overflow: 'hidden', // Hide content that overflows the container
          px: useBlockItems ? '30px' : 0,
        }}
      >
        {/* Left Navigation */}
        <IconButton
          onClick={() => handleScroll(-1)}
          className="nav-button"
          sx={{
            display: {
              lg: 'none',
            },
            position: 'absolute',
            left: '10px',
            top: '50%',
            transform: 'translateY(-50%)',
            zIndex: 2,
            backgroundColor: 'secondary.main',
            color: 'white',
            opacity: 0.5,
            transition: '.2s ease-in-out',
            '&:hover': {
              opacity: 1,
              filter: 'brightness(1.2)',
              backgroundColor: 'secondary.main',
            },
          }}
        >
          <KeyboardArrowLeftIcon />
        </IconButton>

        {/* Scrollable Content */}
        <Box
          ref={contentRef}
          sx={{
            display: 'flex',
            overflowX: 'hidden', // Disable horizontal scrolling
            scrollBehavior: 'smooth', // Smooth scrolling effect
            width: '100%', // Full width of the container
            gap: 2,
            justifyContent: 'space-between',
          }}
        >
          {useBlockItems && blockItems
            ? // Render blockItems
              blockItems.map((item: BlockItem, index: number) => (
                <Box
                  key={index}
                  sx={{
                    width: { xs: '150px', md: '220px' }, // Adjust size based on screen
                  }}
                >
                  <Card
                    component="article"
                    sx={{
                      position: 'relative',
                      width: { xs: '150px', md: '218px' }, // Adjust size based on screen
                      height: { xs: '82px', md: '111px' },
                    }}
                    raised={false}
                  >
                    {item.image?.sourceUrl && (
                      <DisablePrefetchLink
                        key={index}
                        href={item.url || ''}
                        passHref
                      >
                        <FeaturedImageButton>
                          <FeaturedImage
                            ratio="16:9"
                            loading={hasImageLazyLoad ? 'lazy' : 'eager'}
                            src={item.image.sourceUrl}
                          />
                        </FeaturedImageButton>
                      </DisablePrefetchLink>
                    )}
                  </Card>
                  {item?.title && (
                    <Box
                      sx={{
                        mt: 2,
                      }}
                    >
                      <DisablePrefetchLink href={item?.url || '#'} passHref>
                        <>
                          <Typography
                            component="a"
                            variant="caption"
                            href={item?.url || ''}
                            sx={{
                              color: 'secondary.main',
                              textDecoration: 'none',
                              textTransform: 'uppercase',
                              transition: '0.3s ease-in-out',
                              '&:hover': {
                                filter: 'brightness(1.2)',
                              },
                              fontWeight: 600,
                              fontSize: '11px',
                              display: 'block',
                              lineHeight: 1.2,
                            }}
                          >
                            {item?.subtitle}
                          </Typography>
                          <Typography
                            component="a"
                            href={item?.url || ''}
                            sx={{
                              mt: 2,
                              color: 'primary.main',
                              opacity: '.6',
                              textDecoration: 'none',
                              fontWeight: 400,
                              fontSize: '16px',
                              display: 'block',
                              lineHeight: 1.2,
                              fontFamily: merriWeather.className,
                              transition: 'color 0.3s ease-in-out',
                              '&:hover': {
                                color: 'secondary.main',
                              },
                            }}
                          >
                            {item?.title}
                          </Typography>
                        </>
                      </DisablePrefetchLink>
                    </Box>
                  )}
                </Box>
              ))
            : // Render posts
              posts?.map((post: Post) => (
                <Box
                  key={post.databaseId}
                  sx={{
                    width: { xs: '150px', md: '220px' }, // Adjust size based on screen
                  }}
                >
                  <Card
                    component="article"
                    sx={{
                      position: 'relative',
                      width: { xs: '150px', md: '218px' }, // Adjust size based on screen
                      height: { xs: '82px', md: '111px' },
                    }}
                    raised={false}
                  >
                    {post.featuredImage?.node?.sourceUrl && (
                      <DisablePrefetchLink
                        key={post.databaseId}
                        href={post.uri || ''}
                        passHref
                      >
                        <FeaturedImageButton>
                          <FeaturedImage
                            ratio="16:9"
                            loading={hasImageLazyLoad ? 'lazy' : 'eager'}
                            src={post.featuredImage.node.sourceUrl}
                          />
                        </FeaturedImageButton>
                      </DisablePrefetchLink>
                    )}
                  </Card>
                  {post?.title && (
                    <Box
                      sx={{
                        mt: 2,
                      }}
                    >
                      <DisablePrefetchLink href={post?.uri || '#'} passHref>
                        <>
                          <Typography
                            component="a"
                            variant="caption"
                            href={post?.categories?.nodes[0]?.uri || ''}
                            sx={{
                              color: 'secondary.main',
                              textDecoration: 'none',
                              textTransform: 'uppercase',
                              transition: '0.3s ease-in-out',
                              '&:hover': {
                                filter: 'brightness(1.2)',
                              },
                              fontWeight: 600,
                              fontSize: '11px',
                              display: 'block',
                              lineHeight: 1.2,
                            }}
                          >
                            {post?.categories?.nodes[0]?.name}
                          </Typography>
                          <Typography
                            component="a"
                            href={post?.uri || ''}
                            sx={{
                              mt: 2,
                              color: 'primary.main',
                              opacity: '.6',
                              textDecoration: 'none',
                              transition: '0.3s ease-in-out',
                              '&:hover': {
                                filter: 'brightness(1.2)',
                              },
                              fontWeight: 400,
                              fontSize: '16px',
                              display: 'block',
                              lineHeight: 1.2,
                              fontFamily: merriWeather.className,
                            }}
                          >
                            {post?.title}
                          </Typography>
                        </>
                      </DisablePrefetchLink>
                    </Box>
                  )}
                </Box>
              ))}
        </Box>

        {/* Right Navigation */}
        <IconButton
          onClick={() => handleScroll(1)}
          className="nav-button"
          sx={{
            display: {
              lg: 'none',
            },
            position: 'absolute',
            right: '10px',
            top: '50%',
            transform: 'translateY(-50%)',
            zIndex: 2,
            backgroundColor: 'secondary.main',
            color: 'white',
            opacity: 0.5,
            transition: '.2s ease-in-out',
            '&:hover': {
              opacity: 1,
              filter: 'brightness(1.2)',
              backgroundColor: 'secondary.main',
            },
          }}
        >
          <KeyboardArrowRightIcon />
        </IconButton>
      </Box>
    );
  }, [handleScroll, hasImageLazyLoad, posts, blockItems, useBlockItems]);

  return renderCarousel;
};

// Simple export without type assertion - we'll handle it in theme.ts
export default AcfArticleShowcaseLayout;
