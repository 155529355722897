import { Box, Link as MuiLink, Typography } from '@mui/material';
import React from 'react';

import { AcfTopStoryBlockProps } from '@orgnc/core/components/blocks/Blocks/types';

import { FrameLayout } from '@orgnc/core/components/primitives';
import {
  DisablePrefetchLink,
  FastlyOptimizedImage,
} from '@orgnc/core/components/atoms';

const AcfTopStoryBlockHeroLayoutNN = ({
  area,
  className,
  frequency,
  post,
}: AcfTopStoryBlockProps) => {
  return (
    <Box
      sx={{ position: 'relative', overflow: 'hidden' }}
      component="article"
      className={className}
      data-og-block-area={area}
      data-og-block-nth={frequency}
    >
      {post.featuredImage && (
        <Box
          sx={{
            ':after': {
              content: '""',
              position: 'absolute',
              inset: 0,
              background: 'linear-gradient(to top, black 0%, transparent 45%)',
            },
            '.somesome': {
              objectFit: 'cover',
              objectPosition: 'top center',
            },
          }}
        >
          <FrameLayout ratio={'16:10'}>
            <FastlyOptimizedImage
              alt={post.featuredImage?.node?.altText || ''}
              src={post.featuredImage?.node?.sourceUrl || ''}
              className="somesome"
            />
          </FrameLayout>
        </Box>
      )}
      {/* Children */}
      <Box
        sx={{
          position: 'absolute',
          inset: 0,
          display: 'flex',
          flexDirection: 'column',
          zIndex: 1,
          color: 'white',
          p: {
            md: 2,
            xs: 0,
          },
          a: {
            color: 'white',
            textDecoration: 'none',

            ':hover': {
              textDecoration: 'underline',
            },
          },
        }}
      >
        <DisablePrefetchLink passHref href={post.uri || 'a'}>
          <Box
            sx={{
              display: 'flex',
              flex: 1,
              flexDirection: 'column',
              p: 4,
              pb: 0,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flex: 1,
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              {/* Article Author(s) Underline */}
              {post?.categories?.nodes?.length ? (
                <DisablePrefetchLink
                  href={post?.categories.nodes[0]?.uri || '#'}
                  passHref
                >
                  <MuiLink
                    aria-label={post?.categories.nodes[0]?.name || ''}
                    fontWeight={700}
                    fontSize={16}
                    sx={{
                      color: 'black !important',
                      px: 2,
                      backgroundColor: 'brand.header',
                      fontWeight: 700,
                      textTransform: 'uppercase',
                      letterSpacing: '0.05em',
                    }}
                  >
                    {post?.categories.nodes[0]?.name}
                  </MuiLink>
                </DisablePrefetchLink>
              ) : null}

              {/* Title */}
              <Box
                sx={{
                  px: '8%',
                  textAlign: 'center',
                  backgroundColor: 'rgba(0,0,0,0.3)',
                  lineHeight: 1.15,
                }}
              >
                <DisablePrefetchLink href={post?.uri || '#'} passHref>
                  <Typography
                    aria-label={post?.title || ''}
                    component="a"
                    sx={{
                      fontFamily: ({ typography }) =>
                        typography.fonts?.sans?.primary,
                      fontWeight: '700',
                      fontSize: {
                        md: '45px',
                        sm: '24px',
                      },
                      lineHeight: 1.15,
                      '&:hover': {
                        textDecoration: 'none',
                      },
                    }}
                  >
                    {post?.title}
                  </Typography>
                </DisablePrefetchLink>
              </Box>
            </Box>
          </Box>
        </DisablePrefetchLink>
      </Box>
    </Box>
  );
};

export default AcfTopStoryBlockHeroLayoutNN;
