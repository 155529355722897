import { gql } from 'graphql-request';
import { ArticleTemplateQuery } from '@orgnc/core/lib/themes/queries';
import {
  IDisqusOptionsQuery,
  IDisqusOptionsQueryVariables,
} from '@orgnc/core/generated/graphql-operations';

const query = gql`
  query DisqusOptions($dbId: ID!) {
    post(id: $dbId, idType: DATABASE_ID) {
      databaseId
      disqusOptions {
        disqusEnabled
        disqusPostId
      }
    }
  }
`;

const postDisqusCommentsQuery: ArticleTemplateQuery<
  IDisqusOptionsQuery,
  IDisqusOptionsQueryVariables
> = {
  name: 'disqusCommentsQuery',
  query,
  queryArguments: (contentData) => {
    return {
      dbId: contentData.databaseId?.toString() || '',
    };
  },
};

export default postDisqusCommentsQuery;
