import React from 'react';
import { IArticle } from '@orgnc/core/lib/wordpress/api/adapters/articleAdapter';
import RegisteredBlockType from '@orgnc/core/components/blocks/Blocks/types';
import DisqusComments from '@/components/DisqusComments';
import ProductRecommendations from '@/components/ProductRecommendations';
import { getDomainThemeId, MuiThemeType } from '@/themes';

const INJECT_AD_EVERY_N_PARAGRAPH = 5;

/**
 * Injects ad blocks into article content after every nth paragraph
 */
export const injectAds = (article: IArticle): IArticle => {
  const addAdAfterEveryNthBlock = (
    blocks: IArticle['blocks']
  ): IArticle['blocks'] => {
    let adBlockFrequency = 0;
    return blocks.reduce((updatedBlocks, currentBlock, index) => {
      const isAdPlacementEligible =
        (index + 1) % INJECT_AD_EVERY_N_PARAGRAPH === 0 &&
        currentBlock.name !== RegisteredBlockType.CoreHeadingBlock;

      const updatedBlock = { ...currentBlock, order: updatedBlocks.length };

      const googleAdBlock = isAdPlacementEligible
        ? {
            name: 'google-ad-tag',
            order: updatedBlocks.length + 1,
            zone: 'in_content',
            sizes: [
              [300, 250],
              [728, 90],
              'fluid',
              [1, 2],
              [300, 50],
              [300, 100],
            ],
            mapping: [
              [
                [1024, 768],
                [[300, 250], [728, 90], 'fluid', [1, 2]],
              ],
              [
                [0, 0],
                [
                  [300, 50],
                  [300, 100],
                  [300, 250],
                ],
              ],
            ],
            // eslint-disable-next-line no-plusplus
            frequency: `${++adBlockFrequency}`,
            attributes: {},
            innerBlocks: [],
          }
        : null;

      // Add the current block, optional ad block, and optional stn-player block
      return [
        ...updatedBlocks,
        updatedBlock,
        ...(googleAdBlock ? [googleAdBlock] : []),
      ];
    }, [] as IArticle['blocks']);
  };

  const newBlocks = addAdAfterEveryNthBlock(article.blocks);

  return {
    ...article,
    blocks: newBlocks,
  };
};

/**
 * Type for Disqus options from the GraphQL API
 */
interface DisqusOptions {
  disqusEnabled?: string | null;
  disqusPostId?: string | null;
  __typename?: string;
}

const productRecommendationsIdMap = {
  [MuiThemeType.HORTMAG]: 55,
  [MuiThemeType.WRITERSDIGEST]: 66,
  [MuiThemeType.SCRIPTMAG]: 63,
  [MuiThemeType.MILITARYTRADER]: 56,
  [MuiThemeType.NUMISMATICNEWS]: 53,
  [MuiThemeType.OLDCARSWEEKLY]: 58,
  [MuiThemeType.SPORTSCOLLECTORSDIGEST]: 64,
};

/**
 * Renders content after the article
 */
export const renderAfterContent = (
  hostname: string | null,
  url: string,
  disqusOptions?: DisqusOptions | null,
  postTitle?: string
) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const aimId =
    productRecommendationsIdMap[
      getDomainThemeId(hostname) as keyof typeof productRecommendationsIdMap
    ] || 0;

  return (
    <>
      {aimId ? <ProductRecommendations url={url} aimId={aimId} /> : null}
      {disqusOptions &&
        disqusOptions.disqusEnabled !== 'no' &&
        disqusOptions.disqusPostId && (
          <DisqusComments
            disqusPostId={disqusOptions.disqusPostId}
            url={url}
            title={postTitle || ''}
          />
        )}
    </>
  );
};
