import { merriWeather, openSans } from '../utils/localFonts';
import { getDefaultTheme } from './default-theme/default-theme';

export default getDefaultTheme({
  color: {
    primary: '#000000',
    secondary: '#e42414',
    error: '#D73F30',
    brand: {
      main: '#C5362A',
      text: 'rgb(255, 255, 255)',
    },
    text: {
      primary: '#000000',
      secondary: '#000000',
    },
  },
  font: {
    base: `${openSans.style.fontFamily}`,
    sans: `${openSans.style.fontFamily}`,
    serif: `${merriWeather.style.fontFamily}`,
  },
});
