import { Box, Card, IconButton, Link, useTheme } from '@mui/material';
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { DisablePrefetchLink } from '@orgnc/core/components/atoms';
import {
  FeaturedImage,
  FeaturedImageButton,
} from '@orgnc/core/components/themes/Elements/components';
import { IArticleGridProps } from '@orgnc/core/components/blocks/AcfArticleListBlock/Layouts';

const CurcularCardLayout: React.FC<IArticleGridProps> = ({
  hasImageLazyLoad = true,
  posts,
}) => {
  const theme = useTheme();
  const containerRef = useRef<HTMLElement>(null); // Ref for the carousel container
  const contentRef = useRef<HTMLElement>(null); // Ref for the scrollable content
  const itemWidth = 150; // Width of each item
  const [visibleItems, setVisibleItems] = useState(5); // Items visible in the viewport

  // Calculate the number of visible items based on container width
  useEffect(() => {
    const handleResize = () => {
      if (containerRef.current) {
        const containerWidth = containerRef.current.offsetWidth;
        const newVisibleItems = Math.max(
          1,
          Math.floor(containerWidth / itemWidth)
        );
        setVisibleItems(newVisibleItems);
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Handle left/right scroll
  const handleScroll = useCallback((direction: number) => {
    if (contentRef.current) {
      const scrollAmount = direction * itemWidth * visibleItems; // Scroll by the width of visible items
      contentRef.current.scrollLeft += scrollAmount;
    }
  }, [visibleItems]);

  const primaryPosts = useMemo(() => {
    if (Array.isArray(posts)) {
      if (posts.length > 0) {
        return (
          <Box
            ref={containerRef}
            sx={{
              position: 'relative',
              width: '100%',
              overflow: 'hidden', // Hide content that overflows the container
            }}
          >
            {/* Left Navigation */}
            <IconButton
              onClick={() => handleScroll(-1)}
              className="nav-button"
              sx={{
                position: 'absolute',
                left: '10px',
                top: '50%',
                transform: 'translateY(-50%)',
                zIndex: 2,
                backgroundColor: 'white',
              }}
            >
              <KeyboardArrowLeftIcon />
            </IconButton>

            {/* Scrollable Content */}
            <Box
              ref={contentRef}
              sx={{
                display: 'flex',
                overflowX: 'auto', // Enable horizontal scrolling
                scrollBehavior: 'smooth', // Smooth scrolling effect
                width: '100%', // Full width of the container
                gap: 2,
                justifyContent: 'space-between',
              }}
            >
              {posts.map((post) => (
                <Box
                  key={post.databaseId}
                  sx={{
                    width: { xs: '150px', md: '202px' }, // Adjust size based on screen
                  }}
                >
                  <Card
                    component="article"
                    sx={{
                      position: 'relative',
                      width: { xs: '150px', md: '202px' }, // Adjust size based on screen
                      height: { xs: '150px', md: '202px' },
                      borderRadius: '50%', // Make it circular
                      boxShadow: 3,
                    }}
                    raised={false}
                  >
                    {post.featuredImage?.node?.sourceUrl && (
                      <DisablePrefetchLink
                        key={post.databaseId}
                        href={post.uri || ''}
                        passHref
                      >
                        <FeaturedImageButton>
                          <FeaturedImage
                            ratio="1:1"
                            loading={hasImageLazyLoad ? 'lazy' : 'eager'}
                            src={post.featuredImage.node.sourceUrl}
                          />
                        </FeaturedImageButton>
                      </DisablePrefetchLink>
                    )}
                  </Card>
                  {post?.title && (
                    <Box
                      sx={{
                        mt: 2,
                      }}
                    >
                      <DisablePrefetchLink href={post?.uri || '#'} passHref>
                        <Link
                          dangerouslySetInnerHTML={{ __html: post.title }}
                          variant="body2"
                          sx={{
                            color: 'secondary.main',
                            textDecoration: 'none',
                            fontFamily: theme.typography?.fonts?.serif?.primary,
                            transition: 'color 0.3s ease-in-out',
                            '&:hover': {
                              color: 'primary.main',
                            },
                          }}
                        />
                      </DisablePrefetchLink>
                    </Box>
                  )}
                </Box>
              ))}
            </Box>

            {/* Right Navigation */}
            <IconButton
              onClick={() => handleScroll(1)}
              className="nav-button"
              sx={{
                position: 'absolute',
                right: '10px',
                top: '50%',
                transform: 'translateY(-50%)',
                zIndex: 2,
                backgroundColor: 'white',
              }}
            >
              <KeyboardArrowRightIcon />
            </IconButton>
          </Box>
        );
      }
    }
    return null;
  }, [handleScroll, hasImageLazyLoad, posts, theme.typography?.fonts?.serif?.primary]);

  if (!primaryPosts) return null;

  return primaryPosts;
};

export default CurcularCardLayout;
