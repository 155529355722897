import React, { useMemo } from 'react';

import { DisablePrefetchLink } from '@orgnc/core/components/atoms';
import {
  FeaturedImage,
  FeaturedImageButton,
} from '@orgnc/core/components/themes/Elements/components';
import { Box, Link, useTheme } from '@mui/material';
import Typography from '@mui/material/Typography';
import { IArticleGridProps } from '@orgnc/core/components/blocks/AcfArticleListBlock/Layouts';
import { formatDate } from '@/lib/utilities/strings';

const NnArticlesListLayout: React.FC<IArticleGridProps> = ({
  hasImageLazyLoad = true,
  posts,
}) => {
  const theme = useTheme();

  const primaryPosts = useMemo(() => {
    if (Array.isArray(posts)) {
      if (posts.length > 0) {
        return (
          <Box
            sx={{
              display: { xs: 'grid', md: 'grid' },
              gridTemplateColumns: {
                xs: '1fr',
                md: 'repeat(3, 1fr)',
              },
              gap: { xs: 3, md: 4 },
            }}
          >
            {posts.map((post) => (
              <DisablePrefetchLink
                key={post.databaseId}
                href={post.uri || ''}
                passHref
              >
                <Box
                  component="article"
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    position: 'relative',
                    boxSizing: 'border-box',
                  }}
                >
                  {post.featuredImage?.node?.sourceUrl && (
                    <FeaturedImageButton>
                      <FeaturedImage
                        ratio="1:1"
                        loading={hasImageLazyLoad ? 'lazy' : 'eager'}
                        src={post.featuredImage.node.sourceUrl}
                      />
                    </FeaturedImageButton>
                  )}
                  {post.primaryCategory && (
                    <Typography
                      component="a"
                      variant="caption"
                      href={post?.primaryCategory.uri || ''}
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        color: 'black',
                        textDecoration: 'none',
                        textTransform: 'uppercase',
                        transition: '0.3s ease-in-out',
                        '&:hover': {
                          filter: 'brightness(1.2)',
                        },
                        fontWeight: 600,
                        fontSize: '12px',
                        letterSpacing: '0.06em',
                        pt: 2,
                        pb: 1,
                      }}
                    >
                      <Box
                        sx={{
                          display: 'inline-flex',
                          backgroundColor: 'brand.header',
                          px: 1.5,
                        }}
                      >
                        {post?.primaryCategory.name}
                      </Box>
                    </Typography>
                  )}
                  <Box
                    sx={{
                      py: 0,
                      color: 'text.primary',
                    }}
                  >
                    {post?.title && (
                      <Box
                        sx={{
                          pt: 0.5,
                          lineHeight: 1,
                          textAlign: 'center',
                        }}
                      >
                        <DisablePrefetchLink href={post?.uri || '#'} passHref>
                          <Link
                            dangerouslySetInnerHTML={{ __html: post.title }}
                            sx={{
                              textDecoration: 'none',
                              color: 'secondary.main',
                              fontSize: 17,
                              lineHeight: 1.25,
                              fontFamily:
                                theme.typography?.fonts?.serif?.primary,
                              transition: 'color 0.3s ease-in-out',
                              '&:hover': {
                                color: 'secondary.main',
                              },
                            }}
                          />
                        </DisablePrefetchLink>
                      </Box>
                    )}
                  </Box>
                  {post?.excerpt && (
                    <Box
                      sx={{
                        mt: 1.5,
                        color: 'text.secondary',
                        fontSize: '14px',
                        fontWeight: 600,
                        lineHeight: 1.2,
                        opacity: 0.75,
                        cursor: 'pointer',
                        textAlign: 'center',
                      }}
                      dangerouslySetInnerHTML={{ __html: post.excerpt }}
                    />
                  )}
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    {post?.authorTags?.nodes?.length && (
                      <DisablePrefetchLink
                        href={post?.authorTags.nodes[0]?.uri || '#'}
                        passHref
                      >
                        <Link
                          aria-label={post?.authorTags.nodes[0]?.name || ''}
                          fontWeight={700}
                          padding={'20px 0'}
                          variant="caption"
                          sx={{
                            color: 'secondary.main',
                            fontSize: '14px',
                            transition: 'color 0.3s ease-in-out',
                            '&:hover': {
                              color: 'secondary.main',
                            },
                            textDecoration: 'none',
                            textTransform: 'uppercase',
                          }}
                        >
                          By {post?.authorTags.nodes[0]?.name}
                        </Link>
                      </DisablePrefetchLink>
                    )}
                    <Box
                      sx={{
                        color: 'secondary.main',
                        fontSize: '14px',
                        fontWeight: 700,
                        padding: '0 4px',
                      }}
                    >
                      ⋅
                    </Box>
                    <Box
                      sx={{
                        color: 'secondary.main',
                        fontSize: '14px',
                        fontWeight: 700,
                      }}
                    >
                      {formatDate(post?.date || '')}
                    </Box>
                  </Box>
                </Box>
              </DisablePrefetchLink>
            ))}
          </Box>
        );
      }
    }

    return null;
  }, [hasImageLazyLoad, posts, theme.typography?.fonts?.serif?.primary]);

  if (!primaryPosts) return null;

  return primaryPosts;
};

export default NnArticlesListLayout;
