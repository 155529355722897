import { Box, Divider, Stack, useMediaQuery, useTheme } from '@mui/material';
import React, { useMemo } from 'react';

import useContentSettings from '@orgnc/core/hooks/useContentSettings';
import useTemplateQuery from '@orgnc/core/hooks/useTemplateQuery';
import useThemeSettings from '@orgnc/core/hooks/useThemeSettings';

import { addDropCapToFirstParagraphBlock } from '@orgnc/core/components/blocks/Blocks/helpers/blockUtils';

import {
  ArticleOfframp,
  FeaturedImage,
  HedArticle,
  PostAuthorBio,
  Tag,
} from '@orgnc/core/components/themes/Elements/components';
import nonNullable from '@orgnc/core/lib/utils/types/nonNullable';
import postCommentsQuery from '@orgnc/core/lib/wordpress/api/queries/postCommentsQuery';
import RegisteredBlockType from '@orgnc/core/components/blocks/Blocks/types';
import PageContent from '@orgnc/core/components/molecules/PageContent';
import ShareButton from '@orgnc/core/components/molecules/ShareButton';
import SponsoredBrand from '@orgnc/core/components/molecules/SponsoredBrand';
import CommentsSection from '@orgnc/core/components/organisms/CommentsSection';
import {
  BoxLayout,
  CenterLayout,
  ClusterLayout,
  SidebarLayout,
  StackLayout,
} from '@orgnc/core/components/primitives';
import GoogleAdTag from '@/components/GoogleAdTag';
import { IAimArticleTemplateProps } from './ArticleTemplateContent';

const NnArticleTemplateContent: React.FunctionComponent<
  IAimArticleTemplateProps
> = ({
  afterContentSlot,
  beforeContentSlot,
  options,
  post,
  hideableFeaturedImage,
}) => {
  const theme = useTheme();
  const isBelowSmBreakpoint = useMediaQuery(theme.breakpoints.down('sm'));
  const { data: contentSettings } = useContentSettings();
  const { data: postCommentsData } = useTemplateQuery(postCommentsQuery, post);
  const { data: themeSettings } = useThemeSettings();

  const comments =
    postCommentsData?.post?.comments?.nodes?.filter(nonNullable) ?? [];

  // TODO: Figure out a way to pass variants as typed props and not as a untyped generic option
  const smallTemplateVariant = options?.variant === 'small';
  const includeRegularFeaturedImage =
    !options?.disableFeaturedImage &&
    !post.featuredImageOptions?.hideFromArticle;

  const includeFeaturedImage = hideableFeaturedImage?.enabled
    ? hideableFeaturedImage.featuredImage !== undefined
    : includeRegularFeaturedImage;

  const includeBreadcrumbs = !options?.disableBreadcrumbs;
  const includeCategoryLinkInHeds = !options?.disableCategoryLink;
  const includeAuthorLinkInHeds = !options?.disableAuthorLink;
  const relatedStoriesTitle = String(
    options?.relatedStoriesTitle || 'Related Stories'
  );

  const featuredImage = hideableFeaturedImage?.enabled
    ? hideableFeaturedImage.featuredImage
    : post.featuredImage.node;

  // Integrate drop cap functionality for the introductory paragraph contained within all blocks.
  const blocks =
    contentSettings?.dropCap?.option && !post.autoDropCapOption?.disableDropCap
      ? addDropCapToFirstParagraphBlock(post.blocks)
      : post.blocks;

  // Content blocks contain a product table block
  const blocksContainProductTableBlock = post.blocks.some(
    (block) => block.name === RegisteredBlockType.AcfProductTableBlock
  );

  const shareButton = useMemo(
    () => (
      <ShareButton
        data={{
          uri: post.uri,
          media: featuredImage?.sourceUrl,
          text: post.title,
        }}
        buttonText="Share this story"
        variant="contained"
      />
    ),
    [featuredImage?.sourceUrl, post.uri, post.title]
  );

  const articleContent = (
    <StackLayout space={theme.spacing(4)}>
      {/* Featured Image */}
      {includeFeaturedImage && featuredImage && smallTemplateVariant && (
        <FeaturedImage
          alt={featuredImage.altText || ''}
          caption={featuredImage.caption || ''}
          credit={featuredImage.mediaAdditionalData?.mediaCredit?.mcName || ''}
          loading="eager"
          src={featuredImage.sourceUrl || ''}
        />
      )}

      {post.sponsored?.brand ? (
        <Box>
          <Divider />
          <SponsoredBrand
            enabled
            text={post.sponsored.text || ''}
            brand={post.sponsored.brand}
            sx={{ my: 1 }}
          />
          <Divider />
        </Box>
      ) : null}
      {/* Top Share Button */}
      <Box sx={{ textAlign: 'right' }}>{shareButton}</Box>

      {/* Article Body */}
      <PageContent
        blocks={blocks}
        useUncomfortableCPL={Boolean(options?.useUncomfortableCPL)}
        includeContentBlockStyling
      />

      {/* Bottom Share Button */}
      {shareButton}

      {/* Tags */}
      {post.tags.nodes.length > 0 && (
        <Box
          sx={{
            borderTop: `1px solid ${theme.palette.text.disabled}`,
            borderBottom: `1px solid ${theme.palette.text.disabled}`,
          }}
        >
          <BoxLayout padding={`${theme.spacing(5)} ${theme.spacing(3)}`}>
            <ClusterLayout justify="center">
              {post.tags.nodes.map((tag) => (
                <Tag
                  key={tag.slug}
                  href={tag.uri || ''}
                  label={tag.name || ''}
                />
              ))}
            </ClusterLayout>
          </BoxLayout>
        </Box>
      )}

      {/* Author Bio */}
      {post.authorTags.nodes.length > 0 && (
        <PostAuthorBio authorTag={post.authorTags.nodes[0]} />
      )}
    </StackLayout>
  );

  return (
    <>
      <StackLayout space="0">
        {/* Header */}
        <Box px={isBelowSmBreakpoint ? 3 : 10} py={5}>
          <HedArticle
            align={smallTemplateVariant ? 'left' : 'center'}
            includeCategoryTags={includeCategoryLinkInHeds}
            includeBreadcrumbs={includeBreadcrumbs}
            post={post}
          />
        </Box>

        {/* Featured Image */}
        {includeFeaturedImage && featuredImage && !smallTemplateVariant && (
          <FeaturedImage
            alt={featuredImage.altText || ''}
            caption={featuredImage.caption || ''}
            credit={
              featuredImage.mediaAdditionalData?.mediaCredit?.mcName || ''
            }
            loading="eager"
            src={featuredImage.sourceUrl || ''}
          />
        )}
      </StackLayout>

      <BoxLayout
        padding={
          isBelowSmBreakpoint
            ? `${smallTemplateVariant ? 0 : theme.spacing(3)} ${theme.spacing(
                3
              )} ${theme.spacing(10)}`
            : `${smallTemplateVariant ? 0 : theme.spacing(5)} ${theme.spacing(
                10
              )} ${theme.spacing(10)}`
        }
      >
        <Box
          sx={{
            [`[data-rawhtml='1'] li > a`]: {
              color: theme.palette.secondary.main,
              textDecorationColor: theme.palette.secondary.main,
              transition: '.25s ease-in-out',
              '&:hover': {
                filter: 'brightness(1.2)',
              },
            },
            [`[data-rawhtml='1'] p > a`]: {
              color: theme.palette.brand.header,
              textDecorationColor: theme.palette.brand.header,
              transition: '.25s ease-in-out',
              '&:hover': {
                filter: 'brightness(1.5)',
              },
            },
            [`[data-og-block-type="core/group"]`]: {
              maxWidth: '600px',
              marginLeft: 'auto',
              marginRight: 'auto',
            },
            '.text-uppercase': {
              textTransform: 'uppercase',
            },
            '.contents-section': {
              px: 1,
              py: 2,
              backgroundColor: '#ADADAD',
            },
          }}
        >
          <StackLayout space={theme.spacing(10)}>
            {/* Article Content */}
            {!blocksContainProductTableBlock ? (
              // Sidebar Layout (Default layout)
              <SidebarLayout
                contentMin="55%"
                side="right"
                sideWidth="300px"
                space={theme.spacing(10)}
              >
                {/* Main */}
                <Box component="main">
                  {articleContent}
                  {afterContentSlot}
                </Box>

                {/* Sidebar */}
                <Stack component="aside" spacing={3}>
                  {/* Related Stories */}
                  <GoogleAdTag
                    zone="sidebar"
                    sizes={[[300, 250]]}
                    mapping={[
                      [
                        [1024, 768],
                        [
                          [300, 250],
                          [300, 600],
                        ],
                      ],
                    ]}
                  />
                </Stack>
              </SidebarLayout>
            ) : (
              // Centered layout content (if content blocks contain a product table block)
              <CenterLayout maxWidth="840px">
                <Box component="main">
                  {beforeContentSlot}
                  {articleContent}
                  {afterContentSlot}
                </Box>
              </CenterLayout>
            )}
          </StackLayout>
          {themeSettings?.wpComments?.enabled && post.showComments && (
            <CommentsSection postId={post.databaseId} comments={comments} />
          )}
          <ArticleOfframp
            excludeCategoryLink={!includeCategoryLinkInHeds}
            excludeAuthorLink={!includeAuthorLinkInHeds}
            post={post}
            relatedStoriesTitle={relatedStoriesTitle}
          />
        </Box>
      </BoxLayout>
    </>
  );
};

export default NnArticleTemplateContent;
