import { RenderBlockProps } from '@orgnc/core/components/blocks/Blocks/helpers/RenderBlock';
import { BlockFragment } from '@orgnc/core/lib/wordpress/api/fragments/blocksFragment';
import GoogleAdTag from '@/components/GoogleAdTag';
import SizeMappingArray = googletag.SizeMappingArray;
import GeneralSize = googletag.GeneralSize;

export type GoogleAdTagBlockProps = {
  frequency: string;
  zone: string;
  sizes?: GeneralSize;
  tags?: string[];
  mapping?: SizeMappingArray;
};

const GoogleAdTagBlock: React.FunctionComponent<RenderBlockProps> = ({
  block,
}) => {
  const props = block as unknown as BlockFragment<GoogleAdTagBlockProps>;
  return (
    <GoogleAdTag
      zone={props.zone}
      sizes={props.sizes}
      mapping={props.mapping}
      tags={props.tags}
      frequency={props.frequency}
      preserveSpace={false}
    />
  );
};

export default GoogleAdTagBlock;
