import { Box } from '@mui/material';
import { useCallback, useContext, useEffect } from 'react';
import Script from 'next/script';
import { HostnameContext } from '@/utils/useCanonicalDomain';
import { useRouter } from 'next/router';

interface ProductRecommendationProps {
  url: string;
  aimId: number;
}

const ProductRecommendations = ({ url, aimId }: ProductRecommendationProps) => {
  const hostname = useContext(HostnameContext);
  const router = useRouter();

  // use history change counter to trigger script to load for the same page on internal navigation
  const historyChangeCount =
    typeof window !== 'undefined' ? window.productHistoryChangeCount || 0 : 0;
  let uri = '';
  try {
    const parsedUrl = new URL(url);
    uri = parsedUrl.pathname;
  } catch {
    uri = '/';
  }
  const absoluteUrl = hostname ? `https://${hostname}${uri}` : uri;
  const scriptBaseUrl =
    'https://cached-recommendations.ahpc.us/api/v2/tempest-callback/?callback=initProductRecommendations';
  const scriptId = `product-recommendation-${url}-${historyChangeCount}`;

  const scriptSrc = `${scriptBaseUrl}&z=${aimId}&${new URLSearchParams({
    url: absoluteUrl,
    i: historyChangeCount.toString(),
  }).toString()}`;

  const routeChangeCallback = useCallback(() => {
    if (typeof window === 'undefined') return;
    if (!window.productHistoryChangeCount) {
      window.productHistoryChangeCount = 1;
    } else {
      window.productHistoryChangeCount += 1;
    }
  }, []);

  useEffect(() => {
    router.events.on('routeChangeComplete', routeChangeCallback);
    return () => {
      router.events.off('routeChangeComplete', routeChangeCallback);
    };
  }, [router.events, routeChangeCallback]);

  return (
    <>
      <Script id="init-product-recommendations">
        {`
        window.initProductRecommendations = (data) => {
          if (!data || !data.js) return;
          // cleanup before init
          var contents = document.querySelectorAll('.m-detail--contents.l-content-well');
          for (i = 0; i < contents.length; i++) {
              contents[i].innerHTML = '';
              break;
          }
          var evalFunction = new Function(data.js);
          evalFunction();
        };`}
      </Script>
      <Script id={scriptId} src={scriptSrc} strategy="lazyOnload" />
      <Box className="m-detail--contents l-content-well" />
    </>
  );
};

export default ProductRecommendations;

declare global {
  interface Window {
    initProductRecommendations?: (data: { js: string }) => void;
    productHistoryChangeCount?: number;
  }
}
