import {
  Box,
  Divider,
  Stack,
  useMediaQuery,
  useTheme,
  Typography,
  Link,
  BoxProps,
} from '@mui/material';
import React, { useMemo } from 'react';

import useContentSettings from '@orgnc/core/hooks/useContentSettings';
import useTemplateQuery from '@orgnc/core/hooks/useTemplateQuery';
import useThemeSettings from '@orgnc/core/hooks/useThemeSettings';

import { addDropCapToFirstParagraphBlock } from '@orgnc/core/components/blocks/Blocks/helpers/blockUtils';

import {
  ArticleOfframp,
  Breadcrumbs,
  FeaturedImage,
  PostAuthorBio,
  Tag,
} from '@orgnc/core/components/themes/Elements/components';
import nonNullable from '@orgnc/core/lib/utils/types/nonNullable';
import postCommentsQuery from '@orgnc/core/lib/wordpress/api/queries/postCommentsQuery';
import RegisteredBlockType from '@orgnc/core/components/blocks/Blocks/types';
import PageContent from '@orgnc/core/components/molecules/PageContent';
import ShareButton from '@orgnc/core/components/molecules/ShareButton';
import SponsoredBrand from '@orgnc/core/components/molecules/SponsoredBrand';
import CommentsSection from '@orgnc/core/components/organisms/CommentsSection';
import {
  BoxLayout,
  CenterLayout,
  ClusterLayout,
  SidebarLayout,
  StackLayout,
} from '@orgnc/core/components/primitives';
import GoogleAdTag from '@/components/GoogleAdTag';
import { formatDate } from '@/lib/utilities/strings';
import { DisablePrefetchLink } from '@orgnc/core/components/atoms';
import { IArticle } from '@orgnc/core/lib/wordpress/api/adapters/articleAdapter';
import { IAimArticleTemplateProps } from './ArticleTemplateContent';

const ArticleHeaderContent: React.FunctionComponent<{
  post: IArticle;
  color: BoxProps['color'];
}> = ({ post, color = 'white' }) => {
  return (
    <>
      {/* Primary category */}
      {post?.primaryCategory && (
        <Typography sx={{ mb: 1 }}>{post?.primaryCategory.name}</Typography>
      )}
      {/* Title */}
      {post.title && (
        <Typography
          component="h1"
          variant="h1"
          sx={{
            color,
            fontWeight: 400,
            mb: 1,
            fontSize: {
              md: '36px',
              sm: '26px',
            },
            fontFamily: ({ typography }) => typography?.fonts?.serif?.primary,
          }}
          dangerouslySetInnerHTML={{ __html: post.title }}
        />
      )}

      {/* Excerpt */}
      {post.excerpt && (
        <Box
          sx={{
            mt: 1,
            mb: 2,
            fontWeight: 400,
            opacity: 0.9,
            display: { xs: 'none', sm: 'block' },
          }}
          dangerouslySetInnerHTML={{ __html: post.excerpt }}
        />
      )}

      {/* Author and Date */}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {/* Author */}
        {post.authorTags?.nodes?.length > 0 && (
          <DisablePrefetchLink
            href={post.authorTags.nodes[0]?.uri || '#'}
            passHref
          >
            <Link
              aria-label={post.authorTags.nodes[0]?.name || ''}
              fontWeight={700}
              variant="caption"
              sx={{
                color,
                textDecoration: 'none',
                textTransform: 'uppercase',
              }}
            >
              By {post.authorTags.nodes[0]?.name}
            </Link>
          </DisablePrefetchLink>
        )}

        {/* Separator */}
        <Box
          sx={{
            color,
            fontSize: '10px',
            fontWeight: 700,
            padding: '0 4px',
          }}
        >
          ⋅
        </Box>

        {/* Date */}
        <Box
          sx={{
            color,
            fontSize: '10px',
            fontWeight: 700,
          }}
        >
          {formatDate(post?.date || '')}
        </Box>
      </Box>
    </>
  );
};

const ScdArticleTemplateContent: React.FunctionComponent<
  IAimArticleTemplateProps
> = ({
  afterContentSlot,
  beforeContentSlot,
  options,
  post,
  hideableFeaturedImage,
}) => {
  const theme = useTheme();
  const isBelowSmBreakpoint = useMediaQuery(theme.breakpoints.down('sm'));
  const { data: contentSettings } = useContentSettings();
  const { data: postCommentsData } = useTemplateQuery(postCommentsQuery, post);
  const { data: themeSettings } = useThemeSettings();

  const comments =
    postCommentsData?.post?.comments?.nodes?.filter(nonNullable) ?? [];

  // TODO: Figure out a way to pass variants as typed props and not as a untyped generic option
  const smallTemplateVariant = options?.variant === 'small';
  const includeFeaturedImage = hideableFeaturedImage?.enabled
    ? hideableFeaturedImage.featuredImage !== undefined
    : true;
  const includeBreadcrumbs = !options?.disableBreadcrumbs;
  const includeCategoryLinkInHeds = !options?.disableCategoryLink;
  const includeAuthorLinkInHeds = !options?.disableAuthorLink;
  const relatedStoriesTitle = String(
    options?.relatedStoriesTitle || 'Related Stories'
  );

  const featuredImage = hideableFeaturedImage?.enabled
    ? hideableFeaturedImage.featuredImage
    : post.featuredImage.node;

  // Breadcrumbs
  const postBreadcrumbs = useMemo(() => {
    // breadcrumbs !== false backwards-compatibility for the deprecated `breadcrumbs` prop,
    // which used to default to true
    const shouldIncludeBreadcrumbs =
      includeBreadcrumbs && post.breadcrumbs && post.breadcrumbs.length > 0;

    if (shouldIncludeBreadcrumbs && post.breadcrumbs) {
      // spread prevents mutations
      const bc = [...post.breadcrumbs];

      // add a home item
      bc.unshift({ text: 'Home', url: '/' });

      // remove the last element (the current page)
      bc.pop();

      return bc;
    }

    return null;
  }, [includeBreadcrumbs, post.breadcrumbs]);

  // Integrate drop cap functionality for the introductory paragraph contained within all blocks.
  const blocks =
    contentSettings?.dropCap?.option && !post.autoDropCapOption?.disableDropCap
      ? addDropCapToFirstParagraphBlock(post.blocks)
      : post.blocks;

  // Content blocks contain a product table block
  const blocksContainProductTableBlock = post.blocks.some(
    (block) => block.name === RegisteredBlockType.AcfProductTableBlock
  );

  const shareButton = useMemo(
    () => (
      <ShareButton
        data={{
          uri: post.uri,
          media: featuredImage?.sourceUrl,
          text: post.title,
        }}
        buttonText="Share this story"
        variant="contained"
      />
    ),
    [featuredImage?.sourceUrl, post.uri, post.title]
  );

  const articleContent = (
    <StackLayout space={theme.spacing(4)}>
      {post.sponsored?.brand ? (
        <Box>
          <Divider />
          <SponsoredBrand
            enabled
            text={post.sponsored.text || ''}
            brand={post.sponsored.brand}
            sx={{ my: 1 }}
          />
          <Divider />
        </Box>
      ) : null}
      {/* Top Share Button */}
      <Box
        sx={{
          button: {
            backgroundColor: 'brand.header',
          },
        }}
      >
        {shareButton}
      </Box>
      {/* Article Body */}
      <PageContent
        blocks={blocks}
        useUncomfortableCPL={Boolean(options?.useUncomfortableCPL)}
        includeContentBlockStyling
      />

      {/* Bottom Share Button */}
      <Box
        sx={{
          button: {
            backgroundColor: 'brand.header',
          },
        }}
      >
        {shareButton}
      </Box>
      {/* Tags */}
      {post.tags.nodes.length > 0 && (
        <Box
          sx={{
            borderTop: `1px solid ${theme.palette.text.disabled}`,
            borderBottom: `1px solid ${theme.palette.text.disabled}`,
          }}
        >
          <BoxLayout padding={`${theme.spacing(5)} ${theme.spacing(3)}`}>
            <ClusterLayout justify="center">
              {post.tags.nodes.map((tag) => (
                <Tag
                  key={tag.slug}
                  href={tag.uri || ''}
                  label={tag.name || ''}
                />
              ))}
            </ClusterLayout>
          </BoxLayout>
        </Box>
      )}

      {/* Author Bio */}
      {post.authorTags.nodes.length > 0 && (
        <PostAuthorBio authorTag={post.authorTags.nodes[0]} />
      )}
    </StackLayout>
  );

  return (
    <>
      {/* Breadcrumbs */}
      {postBreadcrumbs && (
        <Box
          sx={{
            mt: 2,
            px: {
              md: 10,
              sm: 10,
              xs: 3,
            },
          }}
        >
          <Breadcrumbs breadcrumbs={postBreadcrumbs} sx={{ mb: 2 }} />
        </Box>
      )}
      {/* Featured Image */}
      {includeFeaturedImage && featuredImage && smallTemplateVariant && (
        <Box sx={{ mt: 4, position: 'relative' }}>
          <FeaturedImage
            alt={featuredImage.altText || ''}
            loading="eager"
            src={featuredImage.sourceUrl || ''}
          />

          {/* Overlay content */}
          <Box
            sx={{
              position: 'absolute',
              bottom: 0,
              left: 0,
              right: 0,
              padding: {
                md: 10,
                sm: 10,
                xs: 3,
              },
              pb: {
                md: 5,
                sm: 3,
              },
              background: 'linear-gradient(transparent, rgba(0,0,0,0.7))',
              color: 'white',
            }}
          >
            <ArticleHeaderContent color="white" post={post} />
            {/* Media credits */}
            {featuredImage.caption ||
              (featuredImage.mediaAdditionalData?.mediaCredit?.mcName && (
                <Typography
                  variant="caption"
                  component="div"
                  sx={{
                    mt: 1,
                    fontStyle: 'italic',
                  }}
                >
                  {featuredImage.caption}
                  {featuredImage.mediaAdditionalData?.mediaCredit?.mcName &&
                    `Credit: ${featuredImage.mediaAdditionalData?.mediaCredit?.mcName}`}
                </Typography>
              ))}
          </Box>
        </Box>
      )}

      {!includeFeaturedImage && (
        <Box
          sx={{
            pt: 4,
            px: {
              md: 10,
              sm: 10,
              xs: 3,
            },
          }}
        >
          <ArticleHeaderContent color="text.primary" post={post} />
        </Box>
      )}

      <BoxLayout
        padding={
          isBelowSmBreakpoint
            ? `${smallTemplateVariant ? 0 : theme.spacing(3)} ${theme.spacing(
                3
              )} ${theme.spacing(10)}`
            : `${smallTemplateVariant ? 0 : theme.spacing(5)} ${theme.spacing(
                10
              )} ${theme.spacing(10)}`
        }
      >
        <Box
          sx={{
            mt: 6,
            [`
            h2[data-rawhtml="1"],
            h3[data-rawhtml="1"]
            `]: {
              ml: 0,
            },
            [`
        [data-og-block-type="core/paragraph"],
        [data-og-block-type="core/paragraph"] p,
        [data-rawhtml="1"] p
        `]: {
              maxWidth: 'unset !important',
            },
            [`[data-rawhtml='1'] li > a`]: {
              color: theme.palette.secondary.main,
              textDecorationColor: theme.palette.secondary.main,
              transition: '.25s ease-in-out',
              '&:hover': {
                filter: 'brightness(1.2)',
              },
            },
            [`[data-rawhtml='1'] p > a`]: {
              color: theme.palette.brand.header,
              textDecorationColor: theme.palette.brand.header,
              transition: '.25s ease-in-out',
              '&:hover': {
                filter: 'brightness(1.5)',
              },
            },
            [`[data-og-block-type="core/group"]`]: {
              maxWidth: '600px',
              marginLeft: 'auto',
              marginRight: 'auto',
            },
            '.text-uppercase': {
              textTransform: 'uppercase',
            },
            '.contents-section': {
              px: 1,
              py: 2,
              backgroundColor: '#ADADAD',
            },
          }}
        >
          <StackLayout space={theme.spacing(10)}>
            {/* Article Content */}
            {!blocksContainProductTableBlock ? (
              // Sidebar Layout (Default layout)
              <SidebarLayout
                contentMin="55%"
                side="right"
                sideWidth="300px"
                space={theme.spacing(10)}
              >
                {/* Main */}
                <Box component="main">
                  {articleContent}
                  {afterContentSlot}
                </Box>

                {/* Sidebar */}
                <Stack component="aside" spacing={3}>
                  {/* Related Stories */}
                  <GoogleAdTag
                    zone="sidebar"
                    sizes={[[300, 250]]}
                    mapping={[
                      [
                        [1024, 768],
                        [
                          [300, 250],
                          [300, 600],
                        ],
                      ],
                    ]}
                  />
                </Stack>
              </SidebarLayout>
            ) : (
              // Centered layout content (if content blocks contain a product table block)
              <CenterLayout maxWidth="840px">
                <Box component="main">
                  {beforeContentSlot}
                  {articleContent}
                  {afterContentSlot}
                </Box>
              </CenterLayout>
            )}
          </StackLayout>
          {themeSettings?.wpComments?.enabled && post.showComments && (
            <CommentsSection postId={post.databaseId} comments={comments} />
          )}
          <ArticleOfframp
            excludeCategoryLink={!includeCategoryLinkInHeds}
            excludeAuthorLink={!includeAuthorLinkInHeds}
            post={post}
            relatedStoriesTitle={relatedStoriesTitle}
          />
        </Box>
      </BoxLayout>
    </>
  );
};

export default ScdArticleTemplateContent;
