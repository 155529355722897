import React, { useMemo } from 'react';

import Link from '@orgnc/core/components/atoms/DisablePrefetchLink';
import { DisablePrefetchLink } from '@orgnc/core/components/atoms';
import { Box, Link as A, useTheme } from '@mui/material';
import Typography from '@mui/material/Typography';
import { IArticleGridProps } from '@orgnc/core/components/blocks/AcfArticleListBlock/Layouts';
import PostFeaturedImage from '@orgnc/core/components/molecules/PostFeaturedImage';

const WdRecentArticleGridLayout: React.FC<IArticleGridProps> = ({ posts }) => {
  const theme = useTheme();
  const primaryPosts = useMemo(() => {
    if (Array.isArray(posts)) {
      if (posts.length > 0) {
        return (
          <Box
            sx={{
              gap: 3,
              padding: 0,
              mb: '4rem',
            }}
          >
            {posts.map((post) => (
              <DisablePrefetchLink
                key={post.databaseId}
                href={post.uri || ''}
                passHref
              >
                <Box
                  component="article"
                  sx={{
                    display: 'flex',
                    flexDirection: {
                      xs: 'column',
                      sm: 'column',
                      md: 'row',
                    },
                    gap: { xs: 0, md: 3 },
                    justifyContent: 'center',
                    alignItems: { xs: 'stretch', md: 'center' },
                    position: 'relative',
                    boxSizing: 'border-box',
                    backgroundColor: 'lightFlour.main',
                    mb: 3,
                    overflow: 'hidden',
                    width: '100%',
                  }}
                >
                  {post.featuredImage?.node?.sourceUrl && (
                    <Box
                      sx={{
                        flexBasis: { xs: '100%', md: '33%' },
                        width: { xs: '100%', md: 'auto' },
                        flexGrow: 0,
                        px: { xs: 0, xl: 0 },
                      }}
                    >
                      <Link href={post?.uri || ''} prefetch={false} passHref>
                        <A
                          title={post?.title || ''}
                          underline="none"
                          variant="inherit"
                          sx={{ display: 'block', width: '100%' }}
                        >
                          <PostFeaturedImage
                            ratio={16 / 9}
                            src={post.featuredImage.node?.sourceUrl || ''}
                          />
                        </A>
                      </Link>
                    </Box>
                  )}
                  <Box
                    sx={{
                      display: 'flex',
                      flexBasis: { xs: '100%', md: '67%' },
                      flexDirection: 'column',
                      justifyContent: 'center',
                      pb: { xs: 0, md: 3 },
                      py: { xs: 2, md: 0 },
                    }}
                  >
                    {post.primaryCategory && (
                      <Typography
                        component="a"
                        variant="caption"
                        href={post?.primaryCategory.uri || ''}
                        sx={{
                          color: 'secondary.main',
                          textDecoration: 'none',
                          textTransform: 'uppercase',
                          transition: '0.3s ease-in-out',
                          '&:hover': {
                            filter: 'brightness(1.2)',
                          },
                          fontWeight: 600,
                          fontSize: '13px',
                          display: 'block',
                        }}
                      >
                        {post?.primaryCategory.name}
                      </Typography>
                    )}
                    <Box
                      sx={{
                        py: 0,
                        color: 'text.primary',
                        mt: 1,
                      }}
                    >
                      {post?.title && (
                        <div>
                          <DisablePrefetchLink href={post?.uri || '#'} passHref>
                            <A
                              dangerouslySetInnerHTML={{ __html: post.title }}
                              variant="h4"
                              sx={{
                                transition: 'color 0.3s ease-in-out',
                                '&:hover': {
                                  color: 'secondary.main',
                                },
                                textDecoration: 'none',
                                color: 'inherit',
                                fontFamily:
                                  theme.typography?.fonts?.serif?.primary,
                                fontWeight: 400,
                              }}
                            />
                          </DisablePrefetchLink>
                        </div>
                      )}
                      {post?.excerpt && (
                        <Box
                          sx={{
                            mt: 1,
                            color: 'text.secondary',
                            fontSize: '0.9rem',
                            fontWeight: 300,
                            opacity: 0.75,
                            cursor: 'pointer',
                          }}
                          dangerouslySetInnerHTML={{ __html: post.excerpt }}
                        />
                      )}
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      ></Box>
                    </Box>
                  </Box>
                </Box>
              </DisablePrefetchLink>
            ))}
          </Box>
        );
      }
    }

    return null;
  }, [posts, theme.typography?.fonts?.serif?.primary]);

  if (!primaryPosts) return null;

  return primaryPosts;
};

export default WdRecentArticleGridLayout;
