import { Box, Link as MuiLink, Typography } from '@mui/material';
import React from 'react';

import { AcfTopStoryBlockProps } from '@orgnc/core/components/blocks/Blocks/types';

import { FrameLayout } from '@orgnc/core/components/primitives';
import {
  DisablePrefetchLink,
  FastlyOptimizedImage,
} from '@orgnc/core/components/atoms';

const AcfTopStoryBlockHeroPHLayout = ({
  area,
  className,
  frequency,
  post,
}: AcfTopStoryBlockProps) => {
  return (
    <Box
      sx={{ position: 'relative', overflow: 'hidden' }}
      component="article"
      className={className}
      data-og-block-area={area}
      data-og-block-nth={frequency}
    >
      {post.featuredImage && (
        <Box
          sx={{
            ':after': {
              content: '""',
              position: 'absolute',
              inset: 0,
              background: 'linear-gradient(to top, black 0%, transparent 45%)',
            },
          }}
        >
          <FrameLayout ratio={'16:8'}>
            <FastlyOptimizedImage
              alt={post.featuredImage?.node?.altText || ''}
              src={post.featuredImage?.node?.sourceUrl || ''}
            />
          </FrameLayout>
        </Box>
      )}
      {/* Children */}
      <Box
        sx={{
          position: 'absolute',
          inset: 0,
          display: 'flex',
          flexDirection: 'column',
          zIndex: 1,
          color: 'white',
          p: {
            md: 2,
            xs: 0,
          },
          a: {
            color: 'white',
            textDecoration: 'none',

            ':hover': {
              textDecoration: 'underline',
            },
          },
        }}
      >
        <DisablePrefetchLink passHref href={post.uri || 'a'}>
          <Box
            sx={{
              display: 'flex',
              flex: 1,
              flexDirection: 'column',
              p: 4,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flex: 1,
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
            >
              {/* Article Author(s) Underline */}
              {post?.categories?.nodes?.length ? (
                <DisablePrefetchLink
                  href={post?.categories.nodes[0]?.uri || '#'}
                  passHref
                >
                  <MuiLink
                    aria-label={post?.categories.nodes[0]?.name || ''}
                    fontWeight={700}
                    fontSize={16}
                    sx={{ fontWeight: 700 }}
                    variant="caption"
                  >
                    {post?.categories.nodes[0]?.name}
                  </MuiLink>
                </DisablePrefetchLink>
              ) : null}

              {/* Title */}
              <Box>
                <DisablePrefetchLink href={post?.uri || '#'} passHref>
                  <Typography
                    aria-label={post?.title || ''}
                    component="a"
                    variant="h3"
                    sx={{
                      fontFamily: ({ typography }) =>
                        typography.fonts?.serif?.primary,
                      pr: {
                        lg: '40%',
                      },
                    }}
                  >
                    {post?.title}
                  </Typography>
                </DisablePrefetchLink>

                {/* Subtitle */}
                {post?.excerpt && (
                  <Typography
                    aria-label={post?.excerpt || ''}
                    color="white"
                    component="h4"
                    fontWeight={400}
                    dangerouslySetInnerHTML={{ __html: post.excerpt }}
                    sx={{
                      fontFamily: ({ typography }) =>
                        typography.fonts?.serif?.primary,
                      fontSize: 16,
                      display: {
                        xs: 'none',
                        sm: 'block',
                      },
                      mt: 3,
                    }}
                    variant="subtitle1"
                  />
                )}
              </Box>
            </Box>
          </Box>
        </DisablePrefetchLink>
      </Box>
    </Box>
  );
};

export default AcfTopStoryBlockHeroPHLayout;
