import { gql } from 'graphql-request';

const acfFullWidthCallToActionBlockFragment = gql`
  fragment AcfFullWidthCallToActionBlockFragment on AcfFullWidthCallToActionBlock {
    fullWidthCallToAction {
      heading
      subheading
      background_color
      cta_image {
        altText
        sourceUrl
        mediaDetails {
          width
          height
        }
      }
      background_image {
        altText
        sourceUrl
        mediaDetails {
          width
          height
        }
      }
      cta_rows {
        cta_items {
          cta_item_icon
          cta_item_text
          cta_item_url
        }
      }
      cta_alignment
      cta_buttons_rounded
      cta_text_uppercase
      cta_text_color
      cta_buttons_bg_color
      cta_buttons_text_color
      cta_buttons_outline_color
      cta_buttons_style
    }
  }
`;

export default acfFullWidthCallToActionBlockFragment;
