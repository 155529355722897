import { getDefaultTheme } from './default-theme/default-theme';

export default getDefaultTheme({
  color: {
    primary: '#fff',
    secondary: '#6C6C6C',
    error: '#ea252a',
    brand: {
      main: '#D4382B',
      text: 'rgb(255,255,255)',
    },
    text: {
      primary: '#333333',
      secondary: '#b6b6b6',
    },
  },
  font: {
    base: `"Helvetica"`,
    sans: `"Helvetica"`,
    serif: `serif`,
  },
});
