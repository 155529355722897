import { DiscussionEmbed } from 'disqus-react';
import { useSiteSpecificComponents } from '@orgnc/core/hooks';

const DisqusComments = ({
  url,
  title,
  disqusPostId,
}: {
  url?: string;
  title: string;
  disqusPostId: string;
}) => {
  const { options } = useSiteSpecificComponents();
  return options?.disqusSiteIdentifier &&
    typeof options?.disqusSiteIdentifier === 'string' ? (
    <DiscussionEmbed
      shortname={options.disqusSiteIdentifier}
      config={{
        url,
        identifier: disqusPostId,
        title,
      }}
    />
  ) : null;
};

export default DisqusComments;
