import { gql } from 'graphql-request';
import { CategoryTemplateQuery } from '@orgnc/core/lib/themes/queries';
import {
  IDirectoryCategoriesQuery,
  IDirectoryCategoriesQueryVariables,
} from '@orgnc/core/generated/graphql-operations';

const query = gql`
  query DirectoryCategoriesQuery($dbId: Int) {
    categories(where: { parent: $dbId }, first: 50) {
      nodes {
        name
        uri
        displayOptions {
          featuredImage {
            altText
            mediaItemUrl
            title
            uri
          }
        }
      }
    }
  }
`;

const directoryCategoriesQuery: CategoryTemplateQuery<
  IDirectoryCategoriesQuery,
  IDirectoryCategoriesQueryVariables
> = {
  name: 'directoryCategoriesQuery',
  query,
  queryArguments: (contentData) => {
    return {
      dbId: contentData.databaseId || 0,
    };
  },
};

export default directoryCategoriesQuery;
