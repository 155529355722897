import React, { useMemo } from 'react';

import { DisablePrefetchLink } from '@orgnc/core/components/atoms';
import {
  FeaturedImage,
  FeaturedImageButton,
} from '@orgnc/core/components/themes/Elements/components';
import { Box, Link, useTheme } from '@mui/material';
import Typography from '@mui/material/Typography';
import { IArticleGridProps } from '@orgnc/core/components/blocks/AcfArticleListBlock/Layouts';
import AdsBlock from './AdsBlock/AdsBlock';

const WsnArticleGridAdsAndFeaturedLayout: React.FC<IArticleGridProps> = ({
  hasImageLazyLoad = true,
  posts,
}) => {
  const theme = useTheme();

  const primaryPosts = useMemo(() => {
    if (Array.isArray(posts)) {
      if (posts.length > 0) {
        return (
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: {
                xs: '1fr',
                sm: 'repeat(3, 1fr)',
              },
              gap: 3,
              padding: 0,
              mb: '4rem',
            }}
          >
            {posts.map((post, index) => {
              // Create the post item component
              const postItem = (
                <DisablePrefetchLink
                  key={post.databaseId}
                  href={post.uri || ''}
                  passHref
                >
                  <Box
                    component="article"
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'flex-start',
                      position: 'relative',
                      boxSizing: 'border-box',
                    }}
                  >
                    {post.featuredImage?.node?.sourceUrl && (
                      <FeaturedImageButton>
                        <FeaturedImage
                          ratio="16:9"
                          loading={hasImageLazyLoad ? 'lazy' : 'eager'}
                          src={post.featuredImage.node.sourceUrl}
                        />
                      </FeaturedImageButton>
                    )}
                    {post.primaryCategory && (
                      <Typography
                        component="a"
                        variant="caption"
                        href={post?.primaryCategory.uri || ''}
                        sx={{
                          display: 'inline-flex',
                          backgroundColor: ({ palette }) =>
                            palette.brand.header,
                          color: 'secondary.main',
                          textDecoration: 'none',
                          textTransform: 'uppercase',
                          fontWeight: 700,
                          fontSize: '14px',
                          px: '10px',
                          my: 1.5,
                          alignSelf: 'flex-start',
                        }}
                      >
                        {post?.primaryCategory.name}
                      </Typography>
                    )}
                    <Box
                      sx={{
                        py: 0,
                        color: 'text.primary',
                      }}
                    >
                      {post?.title && (
                        <Box sx={{ lineHeight: 0.5 }}>
                          <DisablePrefetchLink href={post?.uri || '#'} passHref>
                            <Link
                              dangerouslySetInnerHTML={{ __html: post.title }}
                              variant="body2"
                              sx={{
                                textDecoration: 'none',
                                color: 'text.primary',
                                fontFamily:
                                  theme.typography?.fonts?.sans?.primary,
                                fontWeight: 400,
                                fontSize: '1.15rem',
                                display: 'block',
                                lineHeight: 1.05,
                                transition: 'color 0.3s ease-in-out',
                                '&:hover': {
                                  color: 'secondary.main',
                                },
                              }}
                            />
                          </DisablePrefetchLink>
                        </Box>
                      )}
                    </Box>
                  </Box>
                </DisablePrefetchLink>
              );

              // After the 3rd post (index 2), render both the ad and the post
              if (index === 3) {
                const adsKey = `ad-after-post-${post.databaseId}`;
                return (
                  <React.Fragment key={`fragment-${post.databaseId}`}>
                    <AdsBlock key={adsKey} />
                    {postItem}
                  </React.Fragment>
                );
              }

              // For all other posts, just render the post
              return postItem;
            })}
          </Box>
        );
      }
    }

    return null;
  }, [hasImageLazyLoad, posts, theme.typography?.fonts?.sans?.primary]);

  if (!primaryPosts) return null;

  return primaryPosts;
};

export default WsnArticleGridAdsAndFeaturedLayout;
