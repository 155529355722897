import { Box } from '@mui/material';
import React, { PropsWithChildren, useMemo } from 'react';
import { DisablePrefetchLink } from '@orgnc/core/components/atoms';
import {
  FeaturedImage,
  FeaturedImageButton,
} from '@orgnc/core/components/themes/Elements/components';

import Typography from '@mui/material/Typography';
import { merriWeather } from '@/utils/localFonts';
import { RenderBlockProps } from '@orgnc/core/components/blocks/Blocks/helpers/RenderBlock';

interface BlockItem {
  image: {
    sourceUrl: string;
    title: string;
    mediaDetails: {
      width: number;
      height: number;
    };
  };
  subtitle: string;
  title: string;
  url: string;
}

interface Post {
  databaseId: number;
  uri?: string;
  title?: string;
  featuredImage?: {
    node?: {
      sourceUrl?: string;
    };
  };
  categories?: {
    nodes: Array<{
      uri?: string;
      name?: string;
    }>;
  };
}

// Define a simpler type for the custom block data
interface CustomBlock {
  acfScriptmagArticlesShowcase?: {
    items: BlockItem[];
  };
  // Include any other required properties from IBlock
  name?: string;
}

// Create extended props interface
interface ScriptmagMainArticlesShowcaseProps
  extends Omit<RenderBlockProps, 'block'> {
  posts?: Post[];
  block: CustomBlock;
}

// Create a component that accepts our props
const AcfArticleShowcaseSplitLayout = (
  props: PropsWithChildren<ScriptmagMainArticlesShowcaseProps>
) => {
  const { block } = props;
  const hasImageLazyLoad = true;
  const posts = props?.posts;

  // Access blockItems from the block prop
  const blockItems = block?.acfScriptmagArticlesShowcase?.items;

  // Determine which data source to use
  const useBlockItems = !posts?.length && blockItems && blockItems.length > 0;

  const renderContent = useMemo(() => {
    // If we have no data to render, return null
    if (
      (!posts || posts.length === 0) &&
      (!blockItems || blockItems.length === 0)
    ) {
      return null;
    }

    return (
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexWrap: 'wrap',
          gap: 2,
          px: 3,
        }}
      >
        {useBlockItems && blockItems
          ? // Render blockItems
            blockItems.map((item: BlockItem, index: number) => (
              <Box
                key={index}
                sx={{
                  width: { xs: '100%', md: '47.5%' }, // Responsive width
                  aspectRatio: '16/9',
                  position: 'relative',
                  mb: 2,
                }}
              >
                <DisablePrefetchLink key={index} href={item.url || ''} passHref>
                  <Box
                    component="article"
                    sx={{
                      position: 'relative',
                      width: '100%',
                      aspectRatio: '16/9',
                      '&:before': {
                        transition: 'background-color 0.3s ease-in-out',
                        content: '""',
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                        top: 0,
                        left: 0,
                        background:
                          'linear-gradient(to top, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0))',
                        display: 'block',
                        zIndex: 1,
                      },
                    }}
                  >
                    {item.image?.sourceUrl && (
                      <FeaturedImageButton
                        sx={{
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          width: '100%',
                          height: '100%',
                          '& > .MuiBox-root': {
                            width: '100%',
                            height: '100%',
                          },
                          '& img': {
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                          },
                        }}
                      >
                        <FeaturedImage
                          ratio="16:9"
                          loading={hasImageLazyLoad ? 'lazy' : 'eager'}
                          src={item.image.sourceUrl}
                        />
                      </FeaturedImageButton>
                    )}
                    <Box
                      sx={{
                        position: 'absolute',
                        bottom: 0,
                        left: 0,
                        px: 2,
                        py: 2,
                        width: '100%',
                        zIndex: 2,
                      }}
                    >
                      {item?.subtitle && (
                        <Typography
                          component="span"
                          variant="caption"
                          sx={{
                            color: 'secondary.main',
                            textDecoration: 'none',
                            textTransform: 'uppercase',
                            transition: '0.3s ease-in-out',
                            '&:hover': {
                              filter: 'brightness(1.2)',
                            },
                            fontWeight: 600,
                            fontSize: '11px',
                            display: 'block',
                            lineHeight: 1.2,
                            mb: 1,
                          }}
                        >
                          {item?.subtitle}
                        </Typography>
                      )}
                      {item?.title && (
                        <Typography
                          component="h3"
                          sx={{
                            color: 'white',
                            textDecoration: 'none',
                            fontWeight: 400,
                            fontSize: '22px',
                            display: 'block',
                            lineHeight: 1.2,
                            fontFamily: merriWeather.className,
                          }}
                        >
                          {item?.title}
                        </Typography>
                      )}
                    </Box>
                  </Box>
                </DisablePrefetchLink>
              </Box>
            ))
          : // Render posts
            posts?.map((post: Post) => (
              <Box
                key={post.databaseId}
                sx={{
                  width: { xs: '100%', md: 'calc(50% - 8px)' }, // Responsive width
                  position: 'relative',
                  mb: 2,
                }}
              >
                <DisablePrefetchLink
                  key={post.databaseId}
                  href={post.uri || ''}
                  passHref
                >
                  <Box
                    component="article"
                    sx={{
                      position: 'relative',
                      width: '100%',
                      aspectRatio: '16/9',
                      '&:before': {
                        transition: 'background-color 0.3s ease-in-out',
                        content: '""',
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                        top: 0,
                        left: 0,
                        background:
                          'linear-gradient(to top, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0))',
                        display: 'block',
                        zIndex: 1,
                      },
                    }}
                  >
                    {post.featuredImage?.node?.sourceUrl && (
                      <FeaturedImageButton
                        sx={{
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          width: '100%',
                          height: '100%',
                          '& > .MuiBox-root': {
                            width: '100%',
                            height: '100%',
                          },
                          '& img': {
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                          },
                        }}
                      >
                        <FeaturedImage
                          ratio="16:9"
                          loading={hasImageLazyLoad ? 'lazy' : 'eager'}
                          src={post.featuredImage.node.sourceUrl}
                        />
                      </FeaturedImageButton>
                    )}
                    <Box
                      sx={{
                        position: 'absolute',
                        bottom: 0,
                        left: 0,
                        px: 2,
                        py: 3,
                        width: '100%',
                        zIndex: 2,
                      }}
                    >
                      {post?.categories?.nodes[0].name && (
                        <Typography
                          component="span"
                          variant="caption"
                          sx={{
                            color: 'secondary.main',
                            textDecoration: 'none',
                            textTransform: 'uppercase',
                            transition: '0.3s ease-in-out',
                            '&:hover': {
                              filter: 'brightness(1.2)',
                            },
                            fontWeight: 600,
                            fontSize: '11px',
                            display: 'block',
                            lineHeight: 1.2,
                            mb: 1,
                          }}
                        >
                          {post?.categories?.nodes[0].name}
                        </Typography>
                      )}
                      {post?.title && (
                        <Typography
                          component="h3"
                          sx={{
                            color: 'white',
                            textDecoration: 'none',
                            fontWeight: 400,
                            fontSize: '16px',
                            display: 'block',
                            lineHeight: 1.2,
                            fontFamily: merriWeather.className,
                          }}
                        >
                          {post?.title}
                        </Typography>
                      )}
                    </Box>
                  </Box>
                </DisablePrefetchLink>
              </Box>
            ))}
      </Box>
    );
  }, [hasImageLazyLoad, posts, blockItems, useBlockItems]);

  return renderContent;
};

// Simple export without type assertion - we'll handle it in theme.ts
export default AcfArticleShowcaseSplitLayout;
