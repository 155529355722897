import { ComponentType } from 'react';
import { RenderBlockProps } from '@orgnc/core/components/blocks/Blocks/helpers/RenderBlock';
import { Box, Grid, Typography, useTheme, SxProps, Theme } from '@mui/material';
import { generateFastlyImageURL } from '@/lib/utilities';
import {
  FeaturedImage,
  FeaturedImageButton,
} from '@orgnc/core/components/themes/Elements/components';
import { IAcfCategoriesGridBlockFields } from '@orgnc/core/generated/graphql-operations';

// Based on common usage patterns in the codebase
type TextAlignment = 'left' | 'center' | 'right';

interface AcfCategoriesGridBlockProps extends RenderBlockProps {
  block: {
    categoriesGridBlock: IAcfCategoriesGridBlockFields;
    name: string;
    order: number;
    innerBlocks: any[];
    attributes: {
      name: string;
      data: Record<string, any>;
      mode: string;
      className: string;
    };
  };
}

/**
 * Helper function to calculate aspect ratio height based on width and ratio string
 * @param ratio - Aspect ratio in format "width:height" (e.g., "16:9")
 * @returns Calculated percentage for padding-bottom to maintain aspect ratio
 */
const calculateRatioPadding = (ratio: string): string => {
  const [width, height] = ratio.split(':').map(Number);
  return `${(height / width) * 100}%`;
};

/**
 * Categories Grid Block Component
 * Displays a grid of categories with configurable columns
 */
const AcfCategoriesGridBlock: ComponentType<AcfCategoriesGridBlockProps> = ({
  block,
  frequency,
  area,
}) => {
  // Extract block data from the categoriesGridBlock property
  const defaultImageRatio = '4:3';
  const {
    title,
    textAlignment = 'left',
    imageRatio = defaultImageRatio,
    columnsNumber = 3,
    categories = [],
  } = block.categoriesGridBlock || {};
  const theme = useTheme();
  const ratioPadding = calculateRatioPadding(imageRatio || defaultImageRatio);

  // Ensure categories is always an array and columnsNumber is always a number
  const safeCategories = categories || [];
  const safeColumnsNumber = columnsNumber || 3;

  // Map alignment values to justifyContent properties
  const justifyContentMap = {
    left: 'flex-start',
    center: 'center',
    right: 'flex-end',
  };

  // Cast textAlignment to a valid value for the component
  const alignmentValue = (textAlignment || 'left') as TextAlignment;

  return (
    <Box
      data-og-block-area={area}
      data-og-block-nth={frequency}
      data-og-block-type="acf/categories-grid"
      sx={{
        py: 4,
        px: 3,
      }}
    >
      {title && (
        <Typography
          variant="h2"
          component="h2"
          sx={{
            mb: 4,
            fontSize: { xs: '28px', md: '36px' },
            fontWeight: 500,
            textAlign: 'center',
          }}
        >
          {title}
        </Typography>
      )}

      <Grid container spacing={3}>
        {safeCategories.map((category) => {
          if (!category) return null;
          return (
            <Grid
              item
              xs={12}
              sm={6}
              md={12 / safeColumnsNumber}
              key={category.id}
            >
              <Box
                component="a"
                href={category.uri || '#'}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  height: '100%',
                  borderRadius: 1,
                  overflow: 'hidden',
                  cursor: 'pointer',
                  textDecoration: 'none',
                }}
              >
                {/* Image Container - Always rendered with consistent ratio */}
                <Box
                  sx={{
                    position: 'relative',
                    width: '100%',
                    paddingBottom: ratioPadding,
                    backgroundColor: 'action.hover',
                    overflow: 'hidden',
                  }}
                >
                  {category.categoryPostImage && (
                    <Box
                      sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        '& > div': {
                          width: '100%',
                          height: '100%',
                        },
                        '& img': {
                          objectFit: 'cover',
                          width: '100%',
                          height: '100%',
                        },
                      }}
                    >
                      <FeaturedImageButton
                        sx={{
                          width: '100%',
                          height: '100%',
                          display: 'block',
                        }}
                      >
                        <FeaturedImage
                          // @ts-ignore Type 'string' is not assignable to type 'RatioString | undefined'
                          ratio={imageRatio}
                          src={generateFastlyImageURL(
                            category.categoryPostImage
                          )}
                          style={{
                            width: '100%',
                            height: '100%',
                            display: 'block',
                            objectFit: 'cover',
                          }}
                        />
                      </FeaturedImageButton>
                    </Box>
                  )}
                </Box>

                {/* Category Name */}
                <Box
                  sx={{
                    py: 3,
                    px: 0.2,
                    flexGrow: 1,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent:
                      justifyContentMap[
                        textAlignment as keyof typeof justifyContentMap
                      ] || 'flex-start',
                  }}
                >
                  <Typography
                    variant="h5"
                    component="div"
                    sx={
                      {
                        width: '100%',
                        fontWeight: 500,
                        textAlign: alignmentValue,
                        fontSize: { xs: '13px', md: '16px' },
                        fontFamily: theme.typography?.fonts?.sans?.primary,
                        color: theme.palette.text.primary,
                        transition: 'color 0.3s ease',
                        '&:hover': {
                          color: theme.palette.primary.main,
                        },
                      } as SxProps<Theme>
                    }
                  >
                    {category.name}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

export default AcfCategoriesGridBlock;
