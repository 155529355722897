import { Box, Card, Link, useTheme } from '@mui/material';
import React, { useMemo } from 'react';
import { DisablePrefetchLink } from '@orgnc/core/components/atoms';
import {
  FeaturedImage,
  FeaturedImageButton,
} from '@orgnc/core/components/themes/Elements/components';
import { IArticleGridProps } from '@orgnc/core/components/blocks/AcfArticleListBlock/Layouts';

const AchCircularCardLayout: React.FC<IArticleGridProps> = ({
  hasImageLazyLoad = true,
  posts,
}) => {
  const theme = useTheme();

  const primaryPosts = useMemo(() => {
    if (Array.isArray(posts) && posts.length > 0) {
      return (
        <Box
          sx={{
            width: '100%',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              width: '100%',
              gap: 2,
              justifyContent: 'space-between',
            }}
          >
            {posts.map((post) => (
              <Box
                key={post.databaseId}
                sx={{
                  width: 'calc(33.333% - 16px)',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  mb: 3,
                }}
              >
                <Card
                  component="article"
                  sx={{
                    position: 'relative',
                    width: '100%',
                    paddingTop: '100%',
                    borderRadius: '50%',
                    boxShadow: 3,
                    overflow: 'hidden',
                  }}
                  raised={false}
                >
                  {post.featuredImage?.node?.sourceUrl && (
                    <DisablePrefetchLink href={post.uri || ''} passHref>
                      <FeaturedImageButton
                        sx={{
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          width: '100%',
                          height: '100%',
                          '& .MuiImageListItem-img': {
                            objectFit: 'cover',
                            width: '100%',
                            height: '100%',
                          },
                          '& > div': {
                            width: '100%',
                            height: '100%',
                          },
                        }}
                      >
                        <FeaturedImage
                          ratio="1:1"
                          loading={hasImageLazyLoad ? 'lazy' : 'eager'}
                          src={post.featuredImage.node.sourceUrl}
                        />
                      </FeaturedImageButton>
                    </DisablePrefetchLink>
                  )}
                </Card>
                {post?.title && (
                  <Box
                    sx={{
                      mt: 2,
                      textAlign: 'center',
                      width: '100%',
                    }}
                  >
                    <DisablePrefetchLink href={post?.uri || '#'} passHref>
                      <Link
                        dangerouslySetInnerHTML={{ __html: post.title }}
                        variant="body1"
                        sx={{
                          color: 'secondary.main',
                          textDecoration: 'none',
                          fontFamily: theme.typography?.fonts?.sans?.primary,
                          opacity: 0.7,
                          transition: 'color 0.3s ease-in-out',
                          '&:hover': {
                            color: 'primary.main',
                          },
                        }}
                      />
                    </DisablePrefetchLink>
                  </Box>
                )}
              </Box>
            ))}
          </Box>
        </Box>
      );
    }
    return null;
  }, [hasImageLazyLoad, posts, theme.typography?.fonts?.sans?.primary]);

  if (!primaryPosts) return null;

  return primaryPosts;
};

export default AchCircularCardLayout;
