import { Box } from '@mui/material';
import { useLayout } from '@orgnc/core/hooks';
import Typography from '@mui/material/Typography';
import AppBar from '@mui/material/AppBar';
import * as React from 'react';
import NavBar from '@/components/header/default-header/NavBar';
import { Logo } from '@orgnc/core/components/atoms';
import SocialList from '@/components/header/SocialList';
import { useEffect, useRef, useState } from 'react';
import GoogleAdTag from '@/components/GoogleAdTag';

const Header = () => {
  const { menus, networks, logos, newsletter } = useLayout();
  const appBarRef = useRef<HTMLDivElement>(null);
  const [headerHeight, setHeaderHeight] = useState<number>(0);

  useEffect(() => {
    setHeaderHeight(appBarRef?.current?.offsetHeight || 0);
  }, [appBarRef]);

  return (
    <>
      <AppBar
        ref={appBarRef}
        position="relative"
        sx={{
          backgroundColor: 'common.white',
          zIndex: ({ zIndex }) => zIndex.drawer - 1,
          mb: 4,
        }}
      >
        <Box
          sx={{
            justifyContent: 'space-between',
            mt: 1,
            mx: 2,
            display: {
              xs: 'none',
              sm: 'flex',
            },
            a: {
              textDecoration: 'unset',
              color: 'secondary.main',
            },
          }}
        >
          <SocialList networks={networks} />
          <Box
            sx={{
              display: 'flex',
              gap: '1rem',
            }}
          >
            {menus.HEADER_TOP_NAVIGATION?.map(
              (item) =>
                item.path && (
                  <Typography
                    key={`${item.label}.${item.path}`}
                    variant="h6"
                    component="a"
                    href={item.path}
                    // @ts-ignore - Property 'target' exists at runtime but not in type definition
                    target={item.target}
                    // @ts-ignore - Property 'target' exists at runtime but not in type definition
                    rel={item.target ? 'noopener' : undefined}
                    sx={{
                      transition: 'color 0.3s ease-in-out',
                      '&:hover': {
                        color: 'secondary.light',
                      },
                    }}
                  >
                    {item.label}
                  </Typography>
                )
            )}
          </Box>
        </Box>
        <Box
          sx={{
            display: {
              xs: 'none',
              sm: 'flex',
            },
            maxWidth: 1100,
            mx: 'auto',
            px: 2,
            my: 2,
            width: '100%',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              flexGrow: 1,
              maxWidth: {
                md: '700px',
                sm: '60%',
              },
            }}
          >
            {logos.main?.sourceUrl && (
              <Logo
                sourceUrl={logos.main.sourceUrl}
                width={700}
                height="auto"
                title={logos.main.altText}
                sx={{}}
              />
            )}
          </Box>
          <Box
            component="a"
            href="https://aim.dragonforms.com/loading.do?omedasite=HTM_NLsignup"
            target="_blank"
            sx={{
              textDecoration: 'unset',
              display: 'block',
              width: {
                sm: '50px',
                md: '75px',
              },
              ml: 2,
            }}
          >
            <Box
              sx={{
                display: 'block',
                height: {
                  sm: '50px',
                  md: '75px',
                },
                width: {
                  sm: '50px',
                  md: '75px',
                },
                backgroundImage: `url(/images/squarehortcoverline.jpg)`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                objectFit: 'cover',
              }}
            />
            <Typography
              sx={{
                mt: 1,
                color: 'primary.main',
                textTransform: 'uppercase',
                fontSize: {
                  sm: '8px',
                  md: '10px',
                },
                transition: 'color 0.3s ease-in-out',
                '&:hover': {
                  color: 'secondary.main',
                },
              }}
            >
              Subscribe to our newsletters
            </Typography>
          </Box>
        </Box>
        <NavBar
          menu={menus.MAIN_NAVIGATION}
          categoriesMenu={menus.HEADER_NAVIGATION}
          networks={networks}
          additionalMenu={menus.FOOTER_NAVIGATION}
          newsletter={newsletter}
          logo={logos.main || undefined}
          headerHeight={headerHeight}
        />
      </AppBar>
      <GoogleAdTag
        zone="header"
        sizes={[
          [728, 90],
          [970, 90],
          [300, 50],
          [300, 100],
        ]}
        mapping={[
          [
            [1024, 768],
            [
              [728, 90],
              [970, 90],
              [970, 250],
            ],
          ],
          [[768, 0], [[728, 90]]],
          [
            [0, 0],
            [
              [300, 50],
              [300, 100],
            ],
          ],
        ]}
      />
      <GoogleAdTag zone="interstitial" />
    </>
  );
};

export default Header;
