import { Box, Container, SwipeableDrawer } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import DrawerMenu from '@/components/header/DrawerMenu';
import SearchIcon from '@mui/icons-material/Search';
import Toolbar from '@mui/material/Toolbar';
import * as React from 'react';
import Button from '@mui/material/Button';
import { INavBarProps as IElementsNavBarProps } from '@orgnc/core/components/themes/Elements/components';
import { ISocialListProps } from '@orgnc/core/components/themes/Elements/components/SocialList';
import { INewsletter } from '@orgnc/core/lib/wordpress/api/adapters/layoutDataAdapter';
import { Logo, SearchInput } from '@orgnc/core/components/atoms';
import { IMediaFragment } from '@orgnc/core/lib/wordpress/api/fragments/mediaFragment';
import { ChevronRight } from '@mui/icons-material';
import { ReactEventHandler, useState } from 'react';
import IconClose from '@mui/icons-material/Close';

export interface INavBarProps {
  menu: IElementsNavBarProps['menuItems'];
  categoriesMenu: IElementsNavBarProps['menuItems'];
  additionalMenu: IElementsNavBarProps['menuItems'];
  networks: ISocialListProps['networks'];
  headerHeight: number;
  newsletter?: INewsletter;
  logo?: IMediaFragment;
}

interface ISearchDrawerProps {
  open: boolean;
  onClose: ReactEventHandler;
  onOpen: ReactEventHandler;
  headerHeight: number;
}

const SearchDrawer = ({
  open,
  onClose,
  onOpen,
  headerHeight,
}: ISearchDrawerProps) => {
  return (
    <SwipeableDrawer
      anchor="top"
      open={open}
      onClose={onClose}
      onOpen={onOpen}
      PaperProps={{
        sx: {
          mt: `${headerHeight}px`,
        },
      }}
    >
      <Container maxWidth="xl">
        <SearchInput
          iconSearch={ChevronRight}
          sxInput={{
            width: {
              lg: '764px',
              md: '620px',
              sm: '600px',
              xs: '100%',
            },
            maxWidth: '100%',
            m: '20px auto',
            border: 1,
            borderColor: 'grey.300',
            p: 1,
          }}
        />
      </Container>
    </SwipeableDrawer>
  );
};

const NavBar = ({
  menu,
  categoriesMenu,
  networks,
  additionalMenu,
  newsletter,
  logo,
  headerHeight,
}: INavBarProps) => {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );
  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  const [isSearchDrawerOpen, setIsSearchDrawerOpen] = useState<boolean>(false);

  return (
    <Toolbar sx={{ backgroundColor: 'primary.main' }} disableGutters>
      <Box sx={{ flexGrow: 1, display: { xs: 'flex' } }}>
        <IconButton
          sx={{ fontSize: 'large' }}
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleOpenNavMenu}
          color="inherit"
          disableRipple
        >
          <MenuIcon
            fontSize="large"
            sx={{
              transition: 'color 0.3s ease-in-out',
              '&:hover': {
                color: 'secondary.main',
              },
            }}
          />
        </IconButton>
        <DrawerMenu
          open={Boolean(anchorElNav)}
          onClose={handleCloseNavMenu}
          networks={networks}
          categoriesMenu={categoriesMenu}
          additionalMenu={additionalMenu}
        />
      </Box>
      <Box
        sx={{
          flexGrow: 1,
          mr: 2,
          display: { xs: 'flex', sm: 'none' },
          py: 1,
        }}
      >
        {logo?.sourceUrl && (
          <Logo
            sourceUrl={logo.sourceUrl}
            width={500}
            height={48}
            title={logo.altText}
          />
        )}
      </Box>
      <Box
        sx={{
          flexGrow: 1,
          flexWrap: 'wrap',
          overflow: 'hidden',
          maxHeight: 65,
          display: { xs: 'none', sm: 'flex' },
          gap: '4px',
        }}
      >
        {newsletter && newsletter.directLink && (
          <Button
            key="subscribe"
            href={newsletter.directLink}
            target="_blank"
            sx={{
              my: 2,
              color: 'common.white',
              backgroundColor: 'brand.header',
              display: 'flex',
              alignItems: 'center',
              transition: '0.3s ease-in-out',
              padding: '4px 12px',
              marginRight: '8px',
              fontSize: '0.95rem',
              '&:hover': {
                backgroundColor: 'brand.header',
                filter: 'brightness(1.6)',
              },
              fontWeight: 700,
            }}
          >
            {newsletter.label}
          </Button>
        )}
        {menu?.map((page) => (
          <Button
            key={page.id}
            href={page.path || '#'}
            sx={{
              my: 2,
              color: 'brand.text',
              display: 'inline-flex',
              transition: 'color 0.3s ease-in-out',
              textTransform: 'uppercase',
              '&:hover': {
                color: 'secondary.main',
              },
              fontWeight: 700,
              whiteSpace: 'nowrap',
            }}
          >
            {page.label}
          </Button>
        ))}
      </Box>
      <Box>
        <IconButton
          onClick={() => setIsSearchDrawerOpen(!isSearchDrawerOpen)}
          aria-label="search"
          size="large"
          sx={{
            color: ({ palette }) => palette.brand.text,
            transition: 'color 0.3s ease-in-out',
            '&:hover': {
              color: 'secondary.main',
            },
          }}
        >
          {isSearchDrawerOpen ? <IconClose /> : <SearchIcon />}
        </IconButton>
      </Box>
      <SearchDrawer
        open={isSearchDrawerOpen}
        onClose={() => setIsSearchDrawerOpen(false)}
        onOpen={() => setIsSearchDrawerOpen(true)}
        headerHeight={headerHeight}
      />
    </Toolbar>
  );
};

export default NavBar;
