import { sourceSans, ubuntu } from '../utils/localFonts';
import { getDefaultTheme } from './default-theme/default-theme';

export default getDefaultTheme({
  color: {
    primary: 'rgb(0, 50, 104)',
    secondary: '#E8E8E8',
    error: '#ea252a',
    brand: {
      main: 'rgb(6, 50, 98)',
      text: '#fff',
    },
    text: {
      primary: 'rgb(51, 51, 51)',
      secondary: 'rgb(101,101,101)',
    },
  },
  font: {
    base: `${sourceSans.style.fontFamily}`,
    sans: `${sourceSans.style.fontFamily}`,
    serif: `${ubuntu.style.fontFamily}`,
  },
});
