import React, { useMemo } from 'react';
import { DisablePrefetchLink } from '@orgnc/core/components/atoms';
import { Box, Link as A, Typography } from '@mui/material';

import { IArticleGridProps } from '@orgnc/core/components/blocks/AcfArticleListBlock/Layouts';
import { formatDate } from '@/lib/utilities';

const NnRecentArticlesGridLayout: React.FC<IArticleGridProps> = ({ posts }) => {
  const primaryPosts = useMemo(() => {
    if (Array.isArray(posts)) {
      if (posts.length > 0) {
        return (
          <Box
            sx={{
              gap: 3,
              padding: 0,
              mb: '4rem',
            }}
          >
            {posts.map((post) => (
              <DisablePrefetchLink
                key={post.databaseId}
                href={post.uri || ''}
                passHref
              >
                <Box
                  component="article"
                  sx={{
                    display: 'flex',
                    flexDirection: {
                      xs: 'column',
                      sm: 'column',
                      md: 'row',
                    },
                    gap: { xs: 0, md: 3 },
                    justifyContent: 'center',
                    alignItems: { xs: 'stretch', md: 'center' },
                    position: 'relative',
                    boxSizing: 'border-box',
                    backgroundColor: 'lightFlour.main',
                    mb: 3,
                    overflow: 'hidden',
                    width: '100%',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexBasis: { xs: '100%', md: '67%' },
                      flexDirection: 'column',
                      justifyContent: 'center',
                      pb: { xs: 0, md: 3 },
                      py: { xs: 2, md: 0 },
                    }}
                  >
                    {post.primaryCategory && (
                      <Typography
                        component="a"
                        variant="caption"
                        href={post?.primaryCategory.uri || ''}
                        sx={{
                          backgroundColor: 'primary.main',
                          px: 1.5,
                          py: 0.1,
                          color: 'black',
                          letterSpacing: '0.05em',
                          textDecoration: 'none',
                          textTransform: 'uppercase',
                          transition: '0.3s ease-in-out',
                          '&:hover': {
                            filter: 'brightness(1.2)',
                          },
                          fontWeight: 600,
                          fontSize: '13px',
                          display: 'block',
                          alignSelf: 'center',
                        }}
                      >
                        {post?.primaryCategory.name}
                      </Typography>
                    )}
                    <Box
                      sx={{
                        py: 0,
                        color: 'text.primary',
                        mt: 1,
                      }}
                    >
                      {post?.title && (
                        <Box sx={{}}>
                          <DisablePrefetchLink href={post?.uri || '#'} passHref>
                            <A
                              dangerouslySetInnerHTML={{ __html: post.title }}
                              sx={{
                                transition: 'color 0.3s ease-in-out',
                                '&:hover': {
                                  color: 'secondary.main',
                                },
                                textDecoration: 'none',
                                color: 'inherit',
                                fontSize: '22px',
                                fontWeight: 600,
                              }}
                            />
                          </DisablePrefetchLink>
                        </Box>
                      )}
                      {post?.excerpt && (
                        <Box
                          sx={{
                            mt: 1,
                            color: 'text.secondary',
                            fontSize: '0.9rem',
                            fontWeight: 600,
                            cursor: 'pointer',
                          }}
                          dangerouslySetInnerHTML={{ __html: post.excerpt }}
                        />
                      )}
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          mt: 2,
                        }}
                      >
                        {post?.authorTags?.nodes?.length && (
                          <DisablePrefetchLink
                            href={post?.authorTags.nodes[0]?.uri || '#'}
                            passHref
                          >
                            <A
                              aria-label={post?.authorTags.nodes[0]?.name || ''}
                              fontWeight={700}
                              sx={{
                                color: 'black',
                                fontWeight: 600,
                                textDecoration: 'none',
                                transition: 'color 0.3s ease-in-out',
                                '&:hover': {
                                  color: 'secondary.main',
                                },
                                textTransform: 'uppercase',
                              }}
                            >
                              By {post?.authorTags.nodes[0]?.name}
                            </A>
                          </DisablePrefetchLink>
                        )}
                        <strong style={{ padding: '0 4px' }}>⋅</strong>
                        <Box
                          component="a"
                          href={post?.uri || '#'}
                          sx={{
                            color: 'black',
                            fontWeight: 600,
                            textDecoration: 'none',
                            transition: '.3s ease',
                            '&:hover': {
                              color: 'seconsary.main',
                            },
                          }}
                        >
                          {formatDate(post?.date || '')}
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </DisablePrefetchLink>
            ))}
          </Box>
        );
      }
    }

    return null;
  }, [posts]);

  if (!primaryPosts) return null;

  return primaryPosts;
};

export default NnRecentArticlesGridLayout;
