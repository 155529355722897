import antiquetrader from './antiquetrader';
import artsandcraftshomes from './artsandcraftshomes';
import hortmagTheme from './hortmag';
import militarytrader from './militarytrader';
import numismaticnews from './numismaticnews';
import oldcarsweekly from './oldcarsweekly';
import periodHomes from './period_homes';
import scriptmag from './scriptmag';
import sportscollectorsdigest from './sportscollectorsdigest';
import traditionalbuilding from './traditionalbuilding';
import traditionalbuildingshow from './traditionalbuildingshow';
import woodshopnews from './woodshopnews';
import writersdigest from './writersdigest';

export enum MuiThemeType {
  HORTMAG = 'hortmag',
  ARTSANDCRAFTSHOMES = 'artsandcraftshomes',
  WOODSHOPNEWS = 'woodshopnews',
  TRADITIONALBUILDING = 'traditionalbuilding',
  PERIOD_HOMES = 'period-homes',
  TRADITIONALBUILDINGSHOW = 'traditionalbuildingshow',
  NUMISMATICNEWS = 'numismaticnews',
  OLDCARSWEEKLY = 'oldcarsweekly',
  SPORTSCOLLECTORSDIGEST = 'sportscollectorsdigest',
  ANTIQUETRADER = 'antiquetrader',
  MILITARYTRADER = 'militarytrader',
  WRITERSDIGEST = 'writersdigest',
  SCRIPTMAG = 'scriptmag',
}

const THEMES = {
  [MuiThemeType.HORTMAG]: hortmagTheme,
  [MuiThemeType.ARTSANDCRAFTSHOMES]: artsandcraftshomes,
  [MuiThemeType.WOODSHOPNEWS]: woodshopnews,
  [MuiThemeType.TRADITIONALBUILDING]: traditionalbuilding,
  [MuiThemeType.PERIOD_HOMES]: periodHomes,
  [MuiThemeType.TRADITIONALBUILDINGSHOW]: traditionalbuildingshow,
  [MuiThemeType.NUMISMATICNEWS]: numismaticnews,
  [MuiThemeType.OLDCARSWEEKLY]: oldcarsweekly,
  [MuiThemeType.SPORTSCOLLECTORSDIGEST]: sportscollectorsdigest,
  [MuiThemeType.ANTIQUETRADER]: antiquetrader,
  [MuiThemeType.MILITARYTRADER]: militarytrader,
  [MuiThemeType.WRITERSDIGEST]: writersdigest,
  [MuiThemeType.SCRIPTMAG]: scriptmag,
};

export const getDomainThemeId = (domain: string | null = null) => {
  const hostname = domain
    ?.replace('.com', '')
    ?.replace('.net', '')
    ?.replace('.builddemo', '');

  switch (hostname) {
    case 'periodhomes':
      return MuiThemeType.PERIOD_HOMES;
    default:
      return hostname ?? MuiThemeType.HORTMAG;
  }
};

export const getMuiTheme = (themeId: MuiThemeType | string | undefined) => {
  if (themeId === undefined || !(themeId in THEMES)) {
    return hortmagTheme;
  }

  return THEMES[themeId as MuiThemeType];
};

// Simplify imports for storybook
export const defaultTheme = getMuiTheme(process.env.STORYBOOK_THEME_ID ?? '');
