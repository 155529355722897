import { gql } from 'graphql-request';

import { ArticleTemplateQuery } from '@orgnc/core/lib/themes/queries';
import mediaFragment from '@orgnc/core/lib/wordpress/api/fragments/mediaFragment';
import {
  IPostAimFeaturedImageQuery,
  IPostAimFeaturedImageQueryVariables,
} from '@orgnc/core/generated/graphql-operations';

const query = gql`
  query PostAimFeaturedImageQuery($dbId: ID!) {
    post(id: $dbId, idType: DATABASE_ID) {
      databaseId
      aimFeaturedImage {
        hideableFeaturedImage {
          ...MediaFragment
        }
      }
    }
    acfOptionsGeneral {
      generalOptions {
        aimPosts {
          enableHideableFeaturedImage
        }
      }
    }
  }
  ${mediaFragment}
`;

const postAimFeaturedImageQuery: ArticleTemplateQuery<
  IPostAimFeaturedImageQuery,
  IPostAimFeaturedImageQueryVariables
> = {
  name: 'postAimFeaturedImageQuery',
  query,
  queryArguments: (contentData) => {
    return {
      dbId: contentData.databaseId?.toString() || '',
    };
  },
};

export default postAimFeaturedImageQuery;
