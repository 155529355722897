import React, { useMemo } from 'react';

import { DisablePrefetchLink } from '@orgnc/core/components/atoms';
import {
  FeaturedImage,
  FeaturedImageButton,
} from '@orgnc/core/components/themes/Elements/components';
import { Box, Link, useTheme } from '@mui/material';
import { IArticleGridProps } from '@orgnc/core/components/blocks/AcfArticleListBlock/Layouts';
import { formatDate } from '@/lib/utilities/strings';
import AdsBlock from './AdsBlock/AdsBlock';

const MtMvArticleGridAdsLayout: React.FC<IArticleGridProps> = ({
  hasImageLazyLoad = true,
  posts,
}) => {
  const theme = useTheme();

  const primaryPosts = useMemo(() => {
    if (Array.isArray(posts)) {
      if (posts.length > 0) {
        return (
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: {
                xs: '1fr',
                sm: 'repeat(3, 1fr)',
              },
              gap: 3,
              padding: 0,
              mb: '4rem',
            }}
          >
            {posts.map((post, index) => {
              // Render the post item
              const postItem = (
                <DisablePrefetchLink
                  key={post.databaseId}
                  href={post.uri || ''}
                  passHref
                >
                  <Box
                    component="article"
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'flex-start',
                      position: 'relative',
                      boxSizing: 'border-box',
                      backgroundColor: '#676842',
                    }}
                  >
                    {post.featuredImage?.node?.sourceUrl && (
                      <FeaturedImageButton>
                        <FeaturedImage
                          ratio="16:9"
                          loading={hasImageLazyLoad ? 'lazy' : 'eager'}
                          src={post.featuredImage.node.sourceUrl}
                        />
                      </FeaturedImageButton>
                    )}
                    <Box
                      sx={{
                        py: 0,
                        px: 2,
                        pb: 1,
                        color: 'text.primary',
                      }}
                    >
                      {post?.title && (
                        <Box
                          sx={{
                            mt: 2.5,
                            mb: 1.5,
                            lineHeight: 0.5,
                            textAlign: 'center',
                            color: 'white',
                          }}
                        >
                          <DisablePrefetchLink href={post?.uri || '#'} passHref>
                            <Link
                              dangerouslySetInnerHTML={{ __html: post.title }}
                              variant="h4"
                              fontWeight={400}
                              sx={{
                                textDecoration: 'none',
                                color: 'inherit',
                                fontFamily:
                                  theme.typography?.fonts?.sans?.primary,
                                lineHeight: 1,
                                letterSpacing: '0.02em',
                                fontSize: '16px',
                                transition: 'color 0.3s ease-in-out',
                                '&:hover': {
                                  color: 'secondary.main',
                                },
                              }}
                            />
                          </DisablePrefetchLink>
                        </Box>
                      )}
                      {post?.excerpt && (
                        <Box
                          sx={{
                            mt: 1,
                            color: 'white',
                            textAlign: 'center',
                            fontSize: '0.9rem',
                            fontWeight: 300,
                            opacity: 0.75,
                            cursor: 'pointer',
                          }}
                          dangerouslySetInnerHTML={{ __html: post.excerpt }}
                        />
                      )}
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        {post?.authorTags?.nodes?.length && (
                          <DisablePrefetchLink
                            href={post?.authorTags.nodes[0]?.uri || '#'}
                            passHref
                          >
                            <Link
                              aria-label={post?.authorTags.nodes[0]?.name || ''}
                              fontWeight={700}
                              padding={'20px 0'}
                              variant="caption"
                              sx={{
                                color: 'white',
                                transition: 'color 0.3s ease-in-out',
                                '&:hover': {
                                  color: 'white',
                                },
                                textDecoration: 'none',
                                textTransform: 'uppercase',
                              }}
                            >
                              By {post?.authorTags.nodes[0]?.name}
                            </Link>
                          </DisablePrefetchLink>
                        )}
                        <Box
                          sx={{
                            color: 'white',
                            fontSize: '10px',
                            fontWeight: 700,
                            padding: '0 4px',
                          }}
                        >
                          ⋅
                        </Box>
                        <Box
                          sx={{
                            color: 'white',
                            fontSize: '10px',
                            fontWeight: 700,
                          }}
                        >
                          {formatDate(post?.date || '')}
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </DisablePrefetchLink>
              );

              // After the 3rd post (index 2), render both the ad and the post
              if (index === 3) {
                const adsKey = `ad-after-post-${post.databaseId}`;
                return (
                  <React.Fragment key={`fragment-${post.databaseId}`}>
                    <AdsBlock key={adsKey} />
                    {postItem}
                  </React.Fragment>
                );
              }

              // For all other posts, just render the post
              return postItem;
            })}
          </Box>
        );
      }
    }

    return null;
  }, [hasImageLazyLoad, posts, theme.typography?.fonts?.sans?.primary]);

  if (!primaryPosts) return null;

  return primaryPosts;
};

export default MtMvArticleGridAdsLayout;
