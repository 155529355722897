import React from 'react';
import { Box } from '@mui/material';
import GoogleAdTag from '@/components/GoogleAdTag';

/**
 * AdsBlock component for displaying advertisement placeholders
 * Used in various article grid layouts
 */
const AdsBlock: React.FC = () => {
  return (
    <Box
      component="article"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '4px',
        padding: 4,
        boxSizing: 'border-box',
        position: 'relative',
        height: 'auto',
        minHeight: '100%',
      }}
    >
      <GoogleAdTag zone="in_card" sizes={[[300, 250], 'fluid']} />
    </Box>
  );
};

export default AdsBlock;
