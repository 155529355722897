import React from 'react';
import { Box, Container, Paper } from '@mui/material';
import { MuiThemeType } from '@/themes';
import Typography from '@mui/material/Typography';
import { useLayout } from '@orgnc/core/hooks';
import RelatedSitesList from '@/components/footer/RelatedSitesList';
import GoogleAdTag from '@/components/GoogleAdTag';
import getConfig from 'next/config';

const Footer = () => {
  const { menus, logos, copyright } = useLayout();
  const THEME_ID = getConfig().publicRuntimeConfig.themeId as MuiThemeType;

  return (
    <Box
      sx={{
        borderTopWidth: '1px',
        borderTopStyle: 'solid',
        borderTopColor: 'secondary.light',
        // prevent ads to add horizontal scroll
        overflowX: 'hidden',
      }}
    >
      {THEME_ID !== MuiThemeType.TRADITIONALBUILDINGSHOW && (
        <GoogleAdTag
          zone="below_content_card"
          sizes={[
            [970, 90],
            [728, 90],
            [300, 250],
            [300, 50],
            [300, 100],
          ]}
          mapping={[
            [
              [1024, 768],
              [
                [970, 90],
                [728, 90],
              ],
            ],
            [
              [0, 0],
              [
                [300, 250],
                [300, 100],
                [300, 50],
              ],
            ],
          ]}
        />
      )}
      <Paper>
        <Container
          sx={{
            py: 5,
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Box>
            {copyright && (
              /* eslint-disable-next-line react/no-danger */
              <Typography
                variant="caption"
                sx={{
                  textTransform: 'uppercase',
                }}
                dangerouslySetInnerHTML={{ __html: copyright }}
              />
            )}
            <Box
              sx={{
                display: 'flex',
                columnGap: 1,
                flexWrap: 'wrap',
              }}
            >
              {menus.FOOTER_NAVIGATION.map((item) =>
                item.children?.length ? (
                  <Box
                    key={item.id}
                    sx={{
                      display: 'flex',
                      gap: 1,
                      width: '100%',
                    }}
                  >
                    <Typography
                      variant="caption"
                      sx={{
                        textTransform: 'uppercase',
                      }}
                    >
                      {item.label}
                    </Typography>
                    {item.children.map((child) => (
                      <Typography
                        key={child.id}
                        component="a"
                        href={child.path || '#'}
                        variant="caption"
                        sx={{
                          textTransform: 'uppercase',
                          textDecoration: 'unset',
                        }}
                      >
                        {child.label}
                      </Typography>
                    ))}
                  </Box>
                ) : (
                  <Typography
                    key={item.id}
                    component="a"
                    href={item.path || '#'}
                    // @ts-ignore
                    target={item?.target || '_self'}
                    variant="caption"
                    sx={{
                      textTransform: 'uppercase',
                      textDecoration: 'unset',
                    }}
                  >
                    {item.label}
                  </Typography>
                )
              )}
            </Box>
          </Box>
        </Container>
      </Paper>
      <RelatedSitesList logo={logos.footer?.sourceUrl || ''} />
      {THEME_ID !== MuiThemeType.TRADITIONALBUILDINGSHOW && (
        <>
          <GoogleAdTag
            zone="fixed_bottom"
            sizes={[
              [728, 90],
              [900, 65],
              [970, 90],
              [300, 50],
              [320, 50],
            ]}
            mapping={[
              [
                [1024, 768],
                [
                  [728, 90],
                  [900, 65],
                  [970, 90],
                ],
              ],
              [
                [360, 640],
                [
                  [300, 50],
                  [320, 50],
                ],
              ],
            ]}
          />
          <Box id="footer-enlarger-for-ad" sx={{ height: 90 }} />
        </>
      )}
    </Box>
  );
};

export default Footer;
