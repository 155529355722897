/**
 * Image helpers for use with Fastly image resizing and optimizations
 */

import queryString from 'query-string';
import { ImageLoaderProps } from 'next/image';

type FastlyIOImageOptions = {
  /** @deprecated Use {@link FastlyIOImageOptions.format} instead */
  auto?: string | 'webp';
  format?: string | 'auto';
  crop?: string;
  frame?: string; // TODO: add exact typing
  height?: number;
  optimize?: 'low' | 'medium' | 'high';
  quality?: number;
  width?: number;
};

// generates a new image URL width, height, and other Fastly Image Optimizer options: https://developer.fastly.com/reference/io/
const generateFastlyImageURL = (
  imageURL: string,
  options?: FastlyIOImageOptions
) => {
  const { url, query } = queryString.parseUrl(imageURL);

  const {
    format = query.format || 'auto', // serve images in next-gen format, if available
    width = query.width,
    height = query.height,
    optimize = (query.optimize as string) || 'high',
    quality = query.quality,
    frame = query.frame,
    crop = query.crop,
  } = options || {};

  return queryString.stringifyUrl({
    url,
    query: {
      ...query,
      auto: undefined,
      format,
      width,
      height,
      optimize: ['low', 'medium', 'high'].includes(optimize)
        ? optimize
        : undefined,
      quality: ['low', 'medium', 'high'].includes(optimize)
        ? undefined
        : quality,
      frame,
      crop,
    },
  });
};

const imageLoaderFastly = (p: ImageLoaderProps) => {
  return generateFastlyImageURL(p.src, { width: p.width, quality: p.quality });
};

export { generateFastlyImageURL, imageLoaderFastly };
