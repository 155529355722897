import { inter } from '../utils/localFonts';
import { getDefaultTheme } from './default-theme/default-theme';

export default getDefaultTheme({
  color: {
    primary: '#f38120',
    secondary: '#00b1f5',
    error: '#ea252a',
    brand: {
      main: '#f38120',
      text: '#fff',
    },
    text: {
      primary: '#333333',
      secondary: '#777777',
    },
  },
  font: {
    base: `${inter.style.fontFamily}`,
    sans: `${inter.style.fontFamily}`,
    serif: `"Helvetica"`,
  },
});
