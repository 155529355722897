import { SiteSpecificComponentContext } from '@orgnc/core/lib/themes/SiteSpecificComponentsProvider';
import BaseApp from '@orgnc/core/lib/wordpress/pages/_app.page';

import React from 'react';
import NavigationChangeHandler from '@/utils/navigationChangeHandler';
import { getTheme } from '../theme';
import {
  crimsonText,
  inter,
  lato,
  merriWeather,
  merriWeatherSans,
  openSans,
  russoOne,
} from '../utils/localFonts';
import useCanonicalDomain, {
  HostnameProvider,
} from '../utils/useCanonicalDomain';
import { getDomainThemeId } from '../themes';
import { getStatusCode } from '../utils/getStatusCode';
import StaticApp from '../components/StaticApp';

type AppProps = Parameters<typeof BaseApp>[number];

const App: typeof BaseApp &
  React.FunctionComponent<
    AppProps & { hostname: string | null; isError?: boolean }
  > = (props) => {
  const hostname = 'hostname' in props ? props.hostname : null;
  const isError = 'isError' in props ? props.isError : false;

  if (isError) {
    return (
      <StaticApp>
        <props.Component {...props.pageProps} />
      </StaticApp>
    );
  }

  return (
    <>
      <style jsx global>{`
        html {
          font-family: ${inter.style.fontFamily};
          font-family: ${merriWeather.style.fontFamily};
          font-family: ${merriWeatherSans.style.fontFamily};
          font-family: ${openSans.style.fontFamily};
          font-family: ${crimsonText.style.fontFamily};
          font-family: ${lato.style.fontFamily};
          font-family: ${russoOne.style.fontFamily};
        }
      `}</style>
      <HostnameProvider hostname={hostname}>
        <AppContent {...props} />
      </HostnameProvider>
    </>
  );
};

const AppContent: React.FunctionComponent<AppProps> = (props) => {
  const domain = useCanonicalDomain();

  return (
    <SiteSpecificComponentContext.Provider
      value={getTheme(getDomainThemeId(domain))}
    >
      <NavigationChangeHandler />
      <BaseApp {...props} />
    </SiteSpecificComponentContext.Provider>
  );
};

App.getInitialProps = async (appContext) => {
  const { ctx } = appContext;
  const statusCode = getStatusCode(appContext.ctx);
  const isError = statusCode >= 400;

  const hostname = ctx.req?.headers.host;

  const baseAppProps = BaseApp.getInitialProps
    ? await BaseApp.getInitialProps(appContext)
    : { pageProps: {}, requestHost: undefined };

  return {
    ...baseAppProps,
    hostname,
    isError,
  };
};

export default App;
