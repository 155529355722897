import React from 'react';
import { Box, useTheme } from '@mui/material';
import { CenterLayout } from '@orgnc/core/components/primitives';
import { forwardArticleRef } from '@orgnc/core/lib/themes/templates/article';
import { useTemplateQuery } from '@orgnc/core/hooks';
import postDisqusCommentsQuery from '@/lib/queries/postDisqusCommentsQuery';
import GoogleAdTag from '@/components/GoogleAdTag';
import useCanonicalDomain from '@/utils/useCanonicalDomain';
import TbArticleTemplateContent from './TbArticleTemplateContent';
import { injectAds, renderAfterContent } from './shared/articleUtils';
import useHideablePostFeaturedImage from '../../../utils/useHideablePostFeaturedImage';

const TbArticleTemplate = forwardArticleRef((props, ref) => {
  const theme = useTheme();
  const hostname = useCanonicalDomain();

  const { data: postDisqusCommentsData } = useTemplateQuery(
    postDisqusCommentsQuery,
    props.post
  );
  const hideableFeaturedImage = useHideablePostFeaturedImage(props.post);

  const extendedProps = {
    ...props,
    post: injectAds(props.post),
    hideableFeaturedImage,
  };

  const disqusOptions = postDisqusCommentsData?.post?.disqusOptions;

  return (
    <>
      <Box ref={ref} component="article">
        <CenterLayout
          maxWidth={`${theme.breakpoints.values.xl}${theme.breakpoints.unit}`}
        >
          <TbArticleTemplateContent
            {...extendedProps}
            beforeSidebarContent={
              <GoogleAdTag
                zone="atf_sidebar"
                sizes={[[300, 250]]}
                mapping={[
                  [
                    [1024, 768],
                    [
                      [300, 250],
                      [300, 600],
                    ],
                  ],
                ]}
              />
            }
            sidebarContent={
              <GoogleAdTag
                zone="sidebar"
                sizes={[[300, 250]]}
                mapping={[
                  [
                    [1024, 768],
                    [
                      [300, 250],
                      [300, 600],
                    ],
                  ],
                ]}
              />
            }
            afterContentSlot={renderAfterContent(
              hostname,
              props.post.link || '',
              disqusOptions,
              props.post.title
            )}
          />
        </CenterLayout>
      </Box>
    </>
  );
});

export default TbArticleTemplate;
