import { merriWeather, inter } from '../utils/localFonts';
import { getDefaultTheme } from './default-theme/default-theme';

export default getDefaultTheme({
  color: {
    primary: '#2D2D2D',
    secondary: '#6C6C6C',
    error: '#ea252a',
    brand: {
      main: '#3F7BCC',
      text: 'rgb(255,255,255)',
    },
    text: {
      primary: '#333333',
      secondary: '#777777',
    },
  },
  font: {
    base: `${inter.style.fontFamily}`,
    sans: `${inter.style.fontFamily}`,
    serif: `${merriWeather.style.fontFamily}`,
  },
});
