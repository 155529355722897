import { openSans, merriWeather } from '../utils/localFonts';
import { getDefaultTheme } from './default-theme/default-theme';

export default getDefaultTheme({
  color: {
    primary: '#0a7c0f',
    secondary: '#0e0140',
    error: '#ea252a',
    brand: {
      main: '#0a7c0f',
      text: 'rgb(247, 249, 242)',
    },
    text: {
      primary: '#333333',
      secondary: '#0e0140',
    },
  },
  font: {
    base: `${openSans.style.fontFamily}`,
    sans: `${openSans.style.fontFamily}`,
    serif: `${merriWeather.style.fontFamily}`,
  },
});
