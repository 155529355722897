import { lato, playfairDisplay } from '../utils/localFonts';
import { getDefaultTheme } from './default-theme/default-theme';

export default getDefaultTheme({
  color: {
    primary: '#938356',
    secondary: '#1A3636',
    error: '#ea252a',
    brand: {
      main: '#938356',
      text: '#fff',
    },
    text: {
      primary: '#333333',
      secondary: '#777777',
    },
  },
  font: {
    base: `${lato.style.fontFamily}`,
    sans: `${lato.style.fontFamily}`,
    serif: `${playfairDisplay.style.fontFamily}`,
  },
});
