import { Box, ChipProps, Grid, Typography, useTheme } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useTemplateQuery } from '@orgnc/core/hooks';

import { ICategoryTemplateProps } from '@orgnc/core/lib/themes/templates/category';

import {
  Breadcrumbs,
  Category,
  FeaturedImage,
  Tag,
} from '@orgnc/core/components/themes/Elements/components';
import {
  DisablePrefetchLink,
  DividerButton,
} from '@orgnc/core/components/atoms';
import { ContentStyling } from '@orgnc/core/components/molecules/PageContent';
import {
  CenterLayout,
  ClusterLayout,
  GridLayout,
  StackLayout,
} from '@orgnc/core/components/primitives';
import directoryFeaturedPostsQuery from '@/lib/queries/directoryFeaturedPostsQuery';
import directorySponsoredPostsQuery from '@/lib/queries/directorySponsoredPostsQuery';
import directoryPostsQuery from '@/lib/queries/directoryPostsQuery';
import { ICategoryWithPostsFragment } from '@orgnc/core/lib/wordpress/api/queries/categoryWithPosts';
import nonNullable from '@orgnc/core/lib/utils/types/nonNullable';
import { IPostFragment } from '@orgnc/core/generated/graphql-operations';

const ScdDirectoryTemplateContent: React.FunctionComponent<
  ICategoryTemplateProps
> = ({ categoryPageData, options }) => {
  // Settings
  const pageSize = 16;
  const [offset, setOffset] = useState<number>(0);

  // Category object
  const { category } = categoryPageData;

  const { data: directoryPostsData, isFetching } = useTemplateQuery(
    directoryPostsQuery,
    { ...category, perPage: pageSize, offset } as ICategoryWithPostsFragment
  );

  const { data: directoryFeaturedPostsData } = useTemplateQuery(
    directoryFeaturedPostsQuery,
    category as ICategoryWithPostsFragment
  );
  const { data: directorySponsoredPostsData } = useTemplateQuery(
    directorySponsoredPostsQuery,
    category as ICategoryWithPostsFragment
  );

  // Use MUI theme for styling
  const theme = useTheme();
  const { typography } = theme;

  // Check if featured image exists
  const hasFeaturedImage = Boolean(
    category.displayOptions?.featuredImage?.sourceUrl
  );
  const [articles, setArticles] = useState<IPostFragment[]>([]);
  const [hasMore, setHasMore] = useState<boolean>(true);

  // Update articles when data is loaded
  useEffect(() => {
    if (directoryPostsData?.posts?.nodes) {
      const fetchedArticles = directoryPostsData.posts.nodes.filter(
        nonNullable
      ) as IPostFragment[];

      if (offset === 0) {
        // Initial load - replace articles
        setArticles(fetchedArticles);
      } else {
        // Load more - append articles
        setArticles((prev) => [...prev, ...fetchedArticles]);
      }

      setHasMore(
        !!directoryPostsData.posts?.pageInfo?.offsetPagination?.hasMore
      );
    }
  }, [directoryPostsData, offset]);

  const handleLoadmore = async () => {
    // First, update the offset to fetch the next batch
    const newOffset = offset + pageSize;
    setOffset(newOffset);
  };

  const includeBreadcrumbs = !options?.disableBreadcrumbs;
  const showSubcategories = !category.displayOptions?.hideSubcategoryPills;
  const disableTitle = Boolean(options?.disableTitle);

  const breadcrumbs = useMemo(() => {
    if (!includeBreadcrumbs) return [];

    // spread prevents mutations
    const bc = [{ text: 'Home', url: '/' }];

    if (category.ancestors?.nodes && category.ancestors.nodes.length > 0) {
      category.ancestors.nodes.forEach((ancestor) => {
        if (ancestor?.name && ancestor?.uri) {
          bc.push({ text: ancestor.name, url: ancestor.uri });
        }
      });
    }

    return bc;
  }, [category.ancestors?.nodes, includeBreadcrumbs]);
  const subcategoryTagVariant =
    String(options?.subcategoryTagVariant as ChipProps['variant']) || 'filled';

  return (
    <StackLayout space={theme.spacing(5)}>
      {/* Render the category header. */}
      {breadcrumbs.length > 0 && (
        <Box
          sx={{
            px: { xs: 3, md: 10 },
          }}
        >
          <Breadcrumbs
            separator={''}
            breadcrumbs={breadcrumbs}
            sx={{
              textTransform: 'uppercase',
              a: {
                fontWeight: 600,
                color: 'primary.main',
              },
            }}
          />
        </Box>
      )}
      <Box
        sx={{
          px: { xs: 3, md: 10 },
          [`data-rawhtml="1"] p`]: {
            maxWidth: 'unset !important',
          },
        }}
      >
        <Grid container spacing={3} alignItems="center">
          {hasFeaturedImage && (
            <Grid item xs={12} md={4}>
              <FeaturedImage
                alt={
                  category.displayOptions?.featuredImage?.altText ||
                  `${category.name || 'Category'} Image`
                }
                ratio="4:3"
                src={category.displayOptions?.featuredImage?.sourceUrl || ''}
              />
            </Grid>
          )}
          <Grid item xs={12} md={hasFeaturedImage ? 8 : 12}>
            <Box
              sx={{
                textAlign: hasFeaturedImage ? 'left' : 'center',
                display: 'flex',
                flexDirection: 'column',
                alignItems: hasFeaturedImage ? 'flex-start' : 'center',
              }}
            >
              <StackLayout space={theme.spacing(2)}>
                {!disableTitle && (
                  <Typography
                    component="h1"
                    sx={{
                      fontFamily: typography?.fonts?.sans?.primary,
                      fontWeight: 400,
                      fontSize: {
                        md: '40px',
                        sm: '30px',
                        xs: '24px',
                      },
                      textAlign: hasFeaturedImage ? 'left' : 'center',
                      mb: 4,
                      color: '#000',
                    }}
                  >
                    {category.name}
                  </Typography>
                )}
                {category?.description && (
                  <ContentStyling
                    enabled
                    useUncomfortableCPL={false}
                    sx={{
                      [`[data-rawhtml="1"] p`]: {
                        maxWidth: 'unset',
                        mb: 2,
                        textAlign: hasFeaturedImage ? 'left' : 'center',
                      },
                      [`
                        [data-rawhtml="1"] a,
                        [data-rawhtml="1"] p a,
                       `]: {
                        color: 'brand.header',
                      },
                    }}
                  >
                    <Typography
                      component="div" // this can't be a <p> since description HTML may contain another <p> tag
                      dangerouslySetInnerHTML={{
                        __html: category.description,
                      }}
                      data-rawhtml="1"
                      sx={{
                        color: 'text.secondary',
                        fontFamily: typography.fonts?.sans?.primary,
                        mt: 2,
                        mb: 1,
                        alignSelf: 'center',
                        maxWidth: '100%',
                        iframe: {
                          border: 0,
                        },
                      }}
                      variant="h6"
                    />
                  </ContentStyling>
                )}
                {showSubcategories &&
                categoryPageData?.category?.children?.nodes?.length ? (
                  <ClusterLayout
                    align="center"
                    justify="center"
                    space={theme.spacing(1)}
                  >
                    {category.children?.nodes?.map((tag) => (
                      <Tag
                        key={tag?.slug}
                        href={tag?.uri || ''}
                        label={tag?.name || ''}
                        variant={subcategoryTagVariant}
                      />
                    ))}
                  </ClusterLayout>
                ) : null}
              </StackLayout>
            </Box>
          </Grid>
        </Grid>
      </Box>
      {/* Section for posts */}
      {
        <>
          {/* Category sponsors */}
          {directorySponsoredPostsData?.posts?.nodes &&
            directorySponsoredPostsData.posts.nodes.length > 0 && (
              <Box sx={{ px: { xs: 3, md: 10 }, backgroundColor: '#dadada' }}>
                <Typography
                  variant="h2"
                  sx={{
                    my: 4,
                    textAlign: 'center',
                    color: 'black',
                    fontFamily: typography.fonts?.sans?.primary,
                    fontWeight: 400,
                  }}
                >
                  Sponsored
                </Typography>
                <GridLayout>
                  <Box sx={{ width: '100%' }}>
                    {directorySponsoredPostsData.posts.nodes.map(
                      (post: any) => (
                        <DisablePrefetchLink
                          key={post.id}
                          href={post.uri || ''}
                          passHref
                        >
                          <Box
                            component="div"
                            sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              gap: 2,
                              py: 3,
                              overflow: 'hidden',
                              width: '100%',
                              backgroundColor: 'lightFlour.main',
                              cursor: 'pointer',
                              textDecoration: 'none',
                              color: 'inherit',
                              borderTop: '1px solid black',
                              '&:first-of-type': {
                                borderTop: 'unset',
                              },
                            }}
                          >
                            {post.featuredImage?.node?.mediaItemUrl && (
                              <Box
                                sx={{
                                  flexBasis: '35%',
                                  width: '35%',
                                }}
                              >
                                <FeaturedImage
                                  ratio="16:9"
                                  src={
                                    post.featuredImage.node?.mediaItemUrl || ''
                                  }
                                  alt={post.title || ''}
                                />
                              </Box>
                            )}
                            <Box
                              sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                flexBasis: '65%',
                                p: 2,
                              }}
                            >
                              {post?.primaryCategory?.name && (
                                <Box sx={{ pb: 1 }}>
                                  <Category
                                    href={post.primaryCategory.uri || ''}
                                    size="small"
                                    variant="contained"
                                    sx={{
                                      backgroundColor: 'secondary.main',
                                      color: 'secondary.contrastText',
                                    }}
                                  >
                                    {post.primaryCategory.name}
                                  </Category>
                                </Box>
                              )}
                              {post?.title && (
                                <Typography
                                  sx={{
                                    mb: 1,
                                    fontFamily: typography.fonts?.sans?.primary,
                                    fontWeight: 400,
                                    color: 'text.secondary',
                                    fontSize: {
                                      md: '24px',
                                      sm: '18px',
                                    },
                                  }}
                                >
                                  {post.title}
                                </Typography>
                              )}
                              {post?.excerpt && (
                                <Box
                                  sx={{
                                    color: 'text.secondary',
                                    fontSize: '.85rem',
                                    fontWeight: 600,
                                    opacity: 0.75,
                                  }}
                                  dangerouslySetInnerHTML={{
                                    __html: post.excerpt,
                                  }}
                                />
                              )}
                            </Box>
                          </Box>
                        </DisablePrefetchLink>
                      )
                    )}
                  </Box>
                </GridLayout>
              </Box>
            )}
          {/* Featured posts */}
          {directoryFeaturedPostsData?.posts?.nodes &&
            directoryFeaturedPostsData.posts.nodes.length > 0 && (
              <Box sx={{ px: { xs: 3, md: 10 }, backgroundColor: '#dadada' }}>
                <Typography
                  variant="h2"
                  sx={{
                    my: 4,
                    textAlign: 'center',
                    color: 'black',
                    fontFamily: typography.fonts?.sans?.primary,
                    fontWeight: 400,
                  }}
                >
                  Featured
                </Typography>
                <GridLayout>
                  <Box sx={{ width: '100%' }}>
                    {directoryFeaturedPostsData.posts.nodes.map((post: any) => (
                      <DisablePrefetchLink
                        key={post.id}
                        href={post.uri || ''}
                        passHref
                      >
                        <Box
                          component="div"
                          sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            gap: 2,
                            py: 3,
                            overflow: 'hidden',
                            width: '100%',
                            backgroundColor: 'lightFlour.main',
                            cursor: 'pointer',
                            textDecoration: 'none',
                            color: 'inherit',
                            borderTop: '1px solid black',
                            '&:first-of-type': {
                              borderTop: 'unset',
                            },
                          }}
                        >
                          {post.featuredImage?.node?.mediaItemUrl && (
                            <Box
                              sx={{
                                flexBasis: '35%',
                                width: '35%',
                              }}
                            >
                              <FeaturedImage
                                ratio="16:9"
                                src={
                                  post.featuredImage.node?.mediaItemUrl || ''
                                }
                                alt={post.title || ''}
                              />
                            </Box>
                          )}
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              flexBasis: '65%',
                              p: 2,
                            }}
                          >
                            {post?.primaryCategory?.name && (
                              <Box sx={{ pb: 1 }}>
                                <Category
                                  href={post.primaryCategory.uri || ''}
                                  size="small"
                                  variant="contained"
                                  sx={{
                                    backgroundColor: 'secondary.main',
                                    color: 'secondary.contrastText',
                                  }}
                                >
                                  {post.primaryCategory.name}
                                </Category>
                              </Box>
                            )}
                            {post?.title && (
                              <Typography
                                sx={{
                                  mb: 1,
                                  fontFamily: typography.fonts?.sans?.primary,
                                  fontWeight: 400,
                                  color: 'text.primary',
                                  fontSize: {
                                    md: '24px',
                                    sm: '18px',
                                  },
                                }}
                                dangerouslySetInnerHTML={{
                                  __html: post.title,
                                }}
                              />
                            )}
                            {post?.excerpt && (
                              <Box
                                sx={{
                                  color: 'text.primary',
                                  fontSize: '.85rem',
                                  fontWeight: 600,
                                  opacity: 0.75,
                                }}
                                dangerouslySetInnerHTML={{
                                  __html: post.excerpt,
                                }}
                              />
                            )}
                          </Box>
                        </Box>
                      </DisablePrefetchLink>
                    ))}
                  </Box>
                </GridLayout>
              </Box>
            )}
          {/* Directory feed */}
          {articles && articles.length > 0 && (
            <Box sx={{ px: { xs: 3, md: 10 } }}>
              <Typography
                variant="h2"
                sx={{
                  my: 4,
                  textAlign: 'center',
                  color: 'black',
                  fontFamily: typography.fonts?.sans?.primary,
                  fontWeight: 400,
                }}
              >
                Free Listings
              </Typography>
              <GridLayout>
                <Box sx={{ width: '100%' }}>
                  {articles.map((post: any) => (
                    <DisablePrefetchLink
                      key={post.id}
                      href={post.uri || ''}
                      passHref
                    >
                      <Box
                        component="a"
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          mb: 3,
                          overflow: 'hidden',
                          width: '100%',
                          cursor: 'pointer',
                          textDecoration: 'none',
                          color: 'inherit',
                        }}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            width: '100%',
                            p: 2,
                            gap: 3,
                            borderBottom: '1px solid black',
                            flexWrap: 'wrap',
                          }}
                        >
                          {post?.title && (
                            <Typography
                              dangerouslySetInnerHTML={{
                                __html: post.title,
                              }}
                              sx={{
                                mb: 1,
                                fontFamily: typography.fonts?.sans?.primary,
                                fontWeight: 400,
                                flexBasis: '50%',
                                fontSize: {
                                  md: '24px',
                                  sm: '18px',
                                },
                                color: 'text.secondary',
                                transition: '.2s ease-in-out',
                                '&:hover': {
                                  color: 'black',
                                },
                              }}
                            />
                          )}
                          {post?.primaryCategory?.name && (
                            <Typography
                              sx={{
                                textTransform: 'uppercase',
                                fontSize: '14px',
                                fontWeight: 600,
                                transition: '.2s ease-in-out',
                                '&:hover': {
                                  opacity: 0.6,
                                },
                              }}
                            >
                              {post.primaryCategory.name}
                            </Typography>
                          )}
                          {post.excerpt && (
                            <Box
                              dangerouslySetInnerHTML={{
                                __html: post.excerpt,
                              }}
                              sx={{
                                mb: 2,
                                flexBasis: '100%',
                              }}
                            />
                          )}
                        </Box>
                      </Box>
                    </DisablePrefetchLink>
                  ))}
                </Box>
              </GridLayout>
            </Box>
          )}
          {/* Pagination control */}
          <CenterLayout sx={{ mb: 4 }}>
            {hasMore && (
              <DividerButton disabled={isFetching} onClick={handleLoadmore}>
                {isFetching ? 'Loading...' : 'Show more'}
              </DividerButton>
            )}
          </CenterLayout>
        </>
      }
    </StackLayout>
  );
};

export default ScdDirectoryTemplateContent;
