import React, { useMemo } from 'react';

import { DisablePrefetchLink } from '@orgnc/core/components/atoms';
import {
  FeaturedImage,
  FeaturedImageButton,
} from '@orgnc/core/components/themes/Elements/components';
import { Box, Link, useTheme } from '@mui/material';
import Typography from '@mui/material/Typography';
import { IArticleGridProps } from '@orgnc/core/components/blocks/AcfArticleListBlock/Layouts';
import { formatDate } from '@/lib/utilities/strings';

const MainArticleGridLayout: React.FC<IArticleGridProps> = ({
  hasImageLazyLoad = true,
  posts,
}) => {
  const theme = useTheme();

  const primaryPosts = useMemo(() => {
    if (Array.isArray(posts)) {
      if (posts.length > 0) {
        return (
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: {
                xs: '1fr',
                sm: 'repeat(2, 1fr)',
              },
              gap: 3,
              padding: 0,
              mb: '4rem',
            }}
          >
            {posts.map((post) => (
              <DisablePrefetchLink
                key={post.databaseId}
                href={post.uri || ''}
                passHref
              >
                <Box
                  component="article"
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    position: 'relative',
                    boxSizing: 'border-box',
                  }}
                >
                  {post.featuredImage?.node?.sourceUrl && (
                    <FeaturedImageButton>
                      <FeaturedImage
                        ratio="16:9"
                        loading={hasImageLazyLoad ? 'lazy' : 'eager'}
                        src={post.featuredImage.node.sourceUrl}
                      />
                    </FeaturedImageButton>
                  )}
                  {post.primaryCategory && (
                    <Typography
                      component="a"
                      variant="caption"
                      href={post?.primaryCategory.uri || ''}
                      sx={{
                        color: 'secondary.main',
                        textDecoration: 'none',
                        textTransform: 'uppercase',
                        transition: '0.3s ease-in-out',
                        '&:hover': {
                          filter: 'brightness(1.2)',
                        },
                        fontWeight: 600,
                        fontSize: '13px',
                        pt: 2,
                        pb: 1,
                      }}
                    >
                      {post?.primaryCategory.name}
                    </Typography>
                  )}
                  <Box
                    sx={{
                      py: 0,
                      color: 'text.primary',
                    }}
                  >
                    {post?.title && (
                      <div>
                        <DisablePrefetchLink href={post?.uri || '#'} passHref>
                          <Link
                            dangerouslySetInnerHTML={{ __html: post.title }}
                            variant="h6"
                            sx={{
                              textDecoration: 'none',
                              color: 'inherit',
                              fontFamily:
                                theme.typography?.fonts?.serif?.primary,
                              transition: 'color 0.3s ease-in-out',
                              '&:hover': {
                                color: 'secondary.main',
                              },
                            }}
                          />
                        </DisablePrefetchLink>
                      </div>
                    )}
                  </Box>
                  {post?.excerpt && (
                    <Box
                      sx={{
                        mt: 1,
                        color: 'text.secondary',
                        fontSize: '0.9rem',
                        fontWeight: 300,
                        opacity: 0.75,
                        cursor: 'pointer',
                      }}
                      dangerouslySetInnerHTML={{ __html: post.excerpt }}
                    />
                  )}
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    {post?.authorTags?.nodes?.length && (
                      <DisablePrefetchLink
                        href={post?.authorTags.nodes[0]?.uri || '#'}
                        passHref
                      >
                        <Link
                          aria-label={post?.authorTags.nodes[0]?.name || ''}
                          fontWeight={700}
                          padding={'20px 0'}
                          variant="caption"
                          sx={{
                            color: 'primary.main',
                            transition: 'color 0.3s ease-in-out',
                            '&:hover': {
                              color: 'secondary.main',
                            },
                            textDecoration: 'none',
                            textTransform: 'uppercase',
                          }}
                        >
                          By {post?.authorTags.nodes[0]?.name}
                        </Link>
                      </DisablePrefetchLink>
                    )}
                    <Box
                      sx={{
                        color: 'primary.main',
                        fontSize: '10px',
                        fontWeight: 700,
                        padding: '0 4px',
                      }}
                    >
                      ⋅
                    </Box>
                    <Box
                      sx={{
                        color: 'primary.main',
                        fontSize: '10px',
                        fontWeight: 700,
                      }}
                    >
                      {formatDate(post?.date || '')}
                    </Box>
                  </Box>
                </Box>
              </DisablePrefetchLink>
            ))}
          </Box>
        );
      }
    }

    return null;
  }, [hasImageLazyLoad, posts, theme.typography?.fonts?.serif?.primary]);

  if (!primaryPosts) return null;

  return primaryPosts;
};

export default MainArticleGridLayout;
