import { Box } from '@mui/material';
import { useLayout } from '@orgnc/core/hooks';
import Typography from '@mui/material/Typography';
import AppBar from '@mui/material/AppBar';
import * as React from 'react';
import { Logo } from '@orgnc/core/components/atoms';
import SocialList from '@/components/header/SocialList';
import { useEffect, useRef, useState } from 'react';
import GoogleAdTag from '@/components/GoogleAdTag';
import NavBar from './NavBar';
import { useHeaderCardColumns } from '../../../utils/useHeaderCardColumns';
import HeaderCard, { HeaderCardProps } from '../HeaderCard';

const CARD_STYLES: HeaderCardProps['sx'] = {
  title: {
    color: 'common.white',
    fontSize: {
      sm: '10px',
      lg: '13px',
    },
  },
  subtitle: {
    color: 'common.white',
    fontSize: {
      sm: '10px',
      lg: '13px',
    },
  },
};

const Header = () => {
  const { menus, networks, logos, newsletter } = useLayout();
  const appBarRef = useRef<HTMLDivElement>(null);
  const [headerHeight, setHeaderHeight] = useState<number>(0);
  const headerCardsByColumn = useHeaderCardColumns();

  useEffect(() => {
    setHeaderHeight(appBarRef?.current?.offsetHeight || 0);
  }, [appBarRef]);

  return (
    <>
      <AppBar
        ref={appBarRef}
        position="relative"
        sx={{
          backgroundColor: 'text.primary',
          zIndex: ({ zIndex }) => zIndex.drawer - 1,
          mb: 4,
        }}
      >
        <Box
          sx={{
            justifyContent: 'space-between',
            mt: 1,
            mx: 2,
            display: {
              xs: 'none',
              sm: 'flex',
            },
            a: {
              textDecoration: 'unset',
              color: 'common.white',
            },
          }}
        >
          <SocialList networks={networks} />
          <Box
            sx={{
              display: 'flex',
              gap: '1rem',
            }}
          >
            {menus.HEADER_TOP_NAVIGATION?.map(
              (item) =>
                item.path && (
                  <Typography
                    key={`${item.label}.${item.path}`}
                    component="a"
                    href={item.path}
                    // @ts-ignore - Property 'target' exists at runtime but not in type definition
                    target={item.target}
                    // @ts-ignore - Property 'target' exists at runtime but not in type definition
                    rel={item.target ? 'noopener' : undefined}
                    sx={{
                      transition: 'color 0.3s ease-in-out',
                      '&:hover': {
                        color: 'secondary.light',
                      },
                      fontSize: 15,
                    }}
                  >
                    {item.label}
                  </Typography>
                )
            )}
          </Box>
        </Box>
        <Box
          sx={{
            display: {
              xs: 'none',
              sm: 'flex',
            },
            mx: 'auto',
            px: 4,
            my: 1,
            width: '100%',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Box sx={{ display: 'flex', gap: 2 }}>
            {headerCardsByColumn?.left.map((card, idx) => (
              <HeaderCard
                key={`left-${idx}`}
                headerCard={card}
                sx={CARD_STYLES}
              />
            ))}
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Box
              sx={{
                maxWidth: { sm: 200, md: 300, lg: 458 },
                width: '100%',
                display: 'flex',
              }}
            >
              {logos.main?.sourceUrl && (
                <Logo
                  sourceUrl={logos.main.sourceUrl}
                  width={458}
                  height="100%"
                  title={logos.main.altText}
                  sx={{}}
                />
              )}
            </Box>
          </Box>
          <Box sx={{ display: 'flex', gap: 2 }}>
            {headerCardsByColumn?.right.map((card, idx) => (
              <HeaderCard
                key={`right-${idx}`}
                headerCard={card}
                sx={CARD_STYLES}
              />
            ))}
          </Box>
        </Box>
        <NavBar
          menu={menus.MAIN_NAVIGATION}
          categoriesMenu={menus.HEADER_NAVIGATION}
          networks={networks}
          additionalMenu={menus.FOOTER_NAVIGATION}
          newsletter={newsletter}
          logo={logos.main || undefined}
          headerHeight={headerHeight}
        />
      </AppBar>
      <GoogleAdTag
        zone="header"
        sizes={[
          [728, 90],
          [970, 90],
        ]}
        mapping={[
          [
            [1024, 768],
            [
              [728, 90],
              [970, 90],
              [970, 250],
            ],
          ],
          [[768, 0], [[728, 90]]],
          [
            [0, 0],
            [
              [300, 50],
              [300, 100],
            ],
          ],
        ]}
      />
      <GoogleAdTag zone="interstitial" />
    </>
  );
};

export default Header;
