import React from 'react';
import {
  IHeaderCardFragment,
  IHeaderCardsQuery,
} from '@orgnc/core/generated/graphql-operations';
import { useGlobalQuery } from '@orgnc/core/hooks';
import nonNullable from '@orgnc/core/lib/utils/types/nonNullable';
import headerCardsQuery from '../lib/queries/headerCardsQuery';

export const useHeaderCardColumns = () => {
  const { data: headerCardsData } =
    useGlobalQuery<IHeaderCardsQuery>(headerCardsQuery);

  const headerCardsByColumn = React.useMemo(() => {
    const cards =
      headerCardsData?.acfOptionsGeneral?.generalOptions?.cards ?? undefined;
    if (!cards?.showHeaderCards) {
      return undefined;
    }

    return cards?.headerCards?.filter(nonNullable).reduce(
      (acc, card) => {
        const column = card.side === 'left' ? 'left' : 'right';

        return { ...acc, [column]: [...acc[column], card] };
      },
      { left: [] as IHeaderCardFragment[], right: [] as IHeaderCardFragment[] }
    );
  }, [headerCardsData]);

  return headerCardsByColumn;
};
