import { merriWeather, openSans } from '../utils/localFonts';
import { getDefaultTheme } from './default-theme/default-theme';

export default getDefaultTheme({
  color: {
    primary: '#E8932C',
    secondary: '#ffffff',
    error: '#ea252a',
    brand: {
      main: '#1b2875',
      text: 'rgb(255,255,255)',
    },
    text: {
      primary: '#000',
      secondary: '#777777',
    },
  },
  font: {
    base: `${openSans.style.fontFamily}`,
    sans: `${openSans.style.fontFamily}`,
    serif: `${merriWeather.style.fontFamily}`,
  },
});
