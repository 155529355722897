import { Box, SxProps } from '@mui/material';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { FastlyOptimizedImage } from '@orgnc/core/components/atoms';
import { IHeaderCardFragment } from '@orgnc/core/generated/graphql-operations';

import {
  FrameLayout,
  IFrameLayoutProps,
} from '@orgnc/core/components/primitives';

export type HeaderCardProps = {
  headerCard: IHeaderCardFragment;
  sx?: {
    title?: SxProps;
    subtitle?: SxProps;
    image?: SxProps;
  };
  aspectRatio?: IFrameLayoutProps['ratio'];
};

const HeaderCard: React.FunctionComponent<HeaderCardProps> = ({
  headerCard,
  sx,
  aspectRatio = '1:1',
}) => {
  if (headerCard.layout === 'horizontal') {
    return (
      <Box
        sx={{
          textDecoration: 'unset',
          display: 'flex',
          ml: 2,
          gap: 2,
        }}
      >
        <Box
          component="a"
          href={headerCard.imageGroup?.link ?? '/'}
          sx={{
            display: 'block',
            height: {
              sm: '50px',
              lg: '75px',
            },
            width: {
              sm: '50px',
              lg: '75px',
            },
            ...(sx?.image ?? {}),
          }}
        >
          <FrameLayout ratio={aspectRatio}>
            <FastlyOptimizedImage
              alt={headerCard.imageGroup?.image?.altText || ''}
              src={headerCard.imageGroup?.image?.sourceUrl || ''}
            />
          </FrameLayout>
        </Box>
        <Box
          sx={{
            width: '110px',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography
            component="a"
            href={headerCard.titleGroup?.link ?? '/'}
            sx={{
              color: 'text.primary',
              fontSize: {
                sm: '8px',
                lg: '10px',
              },
              textDecoration: 'none',
              transition: 'opacity 0.3s ease-in-out',
              '&:hover': {
                opacity: '0.8',
              },
              ...(sx?.title ?? {}),
            }}
          >
            {headerCard.titleGroup?.text}
          </Typography>
          <Typography
            component="a"
            href={headerCard.subtitleGroup?.link ?? '/'}
            sx={{
              color: 'text.secondary',
              fontSize: {
                sm: '12px',
                lg: '13px',
              },
              textDecoration: 'none',
              transition: 'opacity 0.3s ease-in-out',
              '&:hover': {
                opacity: '0.8',
              },
              ...(sx?.subtitle ?? {}),
            }}
          >
            {headerCard.subtitleGroup?.text}
          </Typography>
        </Box>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        textDecoration: 'unset',
        display: 'block',
        width: {
          sm: '75px',
          md: '100px',
        },
        ml: 2,
      }}
    >
      <Box
        component="a"
        href={headerCard.imageGroup?.link ?? '/'}
        target="_blank"
        sx={{
          display: 'block',
          height: {
            sm: '50px',
            md: '75px',
          },
          width: {
            sm: '50px',
            md: '75px',
          },
          ...(sx?.image ?? {}),
        }}
      >
        <FrameLayout ratio={aspectRatio}>
          <FastlyOptimizedImage
            alt={headerCard.imageGroup?.image?.altText || ''}
            src={headerCard.imageGroup?.image?.sourceUrl || ''}
          />
        </FrameLayout>
      </Box>
      {headerCard.titleGroup?.text && (
        <Typography
          component="a"
          href={headerCard.titleGroup?.link ?? '/'}
          sx={{
            mt: 0.5,
            display: 'block',
            textDecoration: 'none',
            color: 'text.primary',
            fontSize: {
              sm: '10px',
              md: '10px',
            },
            transition: 'opacity 0.3s ease-in-out',
            '&:hover': {
              opacity: '0.8',
            },
            maxWidth: '75px',
            ...(sx?.title ?? {}),
          }}
        >
          {headerCard.titleGroup?.text}
        </Typography>
      )}
      {headerCard.subtitleGroup?.text && (
        <Typography
          component="a"
          href={headerCard.subtitleGroup?.link ?? '/'}
          sx={{
            mt: 0.5,
            display: 'block',
            textDecoration: 'none',
            color: 'text.secondary',
            fontSize: {
              sm: '10px',
              md: '13px',
            },
            transition: 'opacity 0.3s ease-in-out',
            '&:hover': {
              opacity: '0.8',
            },
            maxWidth: '75px',
            ...(sx?.subtitle ?? {}),
          }}
        >
          {headerCard.subtitleGroup?.text}
        </Typography>
      )}
    </Box>
  );
};

export default HeaderCard;
