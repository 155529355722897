import Button from '@mui/material/Button';
import Grid2 from '@mui/material/Unstable_Grid2';
import { Box, Divider, Link } from '@mui/material';
import React, { useRef, useState } from 'react';
import { MuiThemeType } from '@/themes';
import getConfig from 'next/config';

const AIM_SITES = {
  links: [
    {
      name: 'Antique Trader',
      link: 'https://www.antiquetrader.com/?utm_source=hortmag.com&utm_medium=referral&utm_campaign=AIM%20Global%20Footer',
    },
    {
      name: 'Arts & Crafts Homes',
      link: 'https://www.artsandcraftshomes.com/?utm_source=hortmag.com&utm_medium=referral&utm_campaign=AIM%20Global%20Footer',
    },
    {
      name: 'Bank Note Reporter',
      link: 'https://my.banknotereporter.com/?utm_source=hortmag.com&utm_medium=referral&utm_campaign=AIM%20Global%20Footer',
    },
    {
      name: 'Cabin Life',
      link: 'https://www.cabinlife.com/?utm_source=hortmag.com&utm_medium=referral&utm_campaign=AIM%20Global%20Footer',
    },
    {
      name: 'Cuisine at Home',
      link: 'https://www.cuisineathome.com/?utm_source=hortmag.com&utm_medium=referral&utm_campaign=AIM%20Global%20Footer',
    },
    {
      name: 'Fine Gardening',
      link: 'https://www.finegardening.com/?utm_source=hortmag.com&utm_medium=referral&utm_campaign=AIM%20Global%20Footer',
    },
    {
      name: 'Fine Homebuilding',
      link: 'https://www.finehomebuilding.com/?utm_source=hortmag.com&utm_medium=referral&utm_campaign=AIM%20Global%20Footer',
    },
    {
      name: 'Fine Woodworking',
      link: 'https://www.finewoodworking.com/?utm_source=hortmag.com&utm_medium=referral&utm_campaign=AIM%20Global%20Footer',
    },
    {
      name: 'Green Building Advisor',
      link: 'https://www.greenbuildingadvisor.com/?utm_source=hortmag.com&utm_medium=referral&utm_campaign=AIM%20Global%20Footer',
    },
    {
      name: 'Garden Gate',
      link: 'https://www.gardengatemagazine.com/?utm_source=hortmag.com&utm_medium=referral&utm_campaign=AIM%20Global%20Footer',
    },
    {
      name: 'Keep Craft Alive',
      link: 'https://www.keepcraftalive.org/?utm_source=hortmag.com&utm_medium=referral&utm_campaign=AIM%20Global%20Footer',
    },
    {
      name: 'Log Home Living',
      link: 'https://www.loghome.com/?utm_source=hortmag.com&utm_medium=referral&utm_campaign=AIM%20Global%20Footer',
    },
    {
      name: 'Military Trader/Vehicles',
      link: 'https://www.militarytrader.com/?utm_source=hortmag.com&utm_medium=referral&utm_campaign=AIM%20Global%20Footer',
    },
    {
      name: 'Numismatic News/Vehicles',
      link: 'https://www.numismaticnews.net/?utm_source=hortmag.com&utm_medium=referral&utm_campaign=AIM%20Global%20Footer',
    },
    {
      name: 'Numismaster',
      link: 'https://numismaster.com/?utm_source=hortmag.com&utm_medium=referral&utm_campaign=AIM%20Global%20Footer',
    },
    {
      name: 'Old Cars Weekly',
      link: 'https://www.oldcarsweekly.com/?utm_source=hortmag.com&utm_medium=referral&utm_campaign=AIM%20Global%20Footer',
    },
    {
      name: 'Old House Journal',
      link: 'https://my.oldhousejournal.com/?utm_source=hortmag.com&utm_medium=referral&utm_campaign=AIM%20Global%20Footer',
    },
    {
      name: 'Period Homes',
      link: 'https://www.period-homes.com/?utm_source=hortmag.com&utm_medium=referral&utm_campaign=AIM%20Global%20Footer',
    },
    {
      name: 'Popular Woodworking',
      link: 'https://www.popularwoodworking.com/?utm_source=hortmag.com&utm_medium=referral&utm_campaign=AIM%20Global%20Footer',
    },
    {
      name: 'Script',
      link: 'https://www.scriptmag.com/?utm_source=hortmag.com&utm_medium=referral&utm_campaign=AIM%20Global%20Footer',
    },
    {
      name: 'ShopNotes',
      link: 'https://www.shopnotes.com/?utm_source=hortmag.com&utm_medium=referral&utm_campaign=AIM%20Global%20Footer',
    },
    {
      name: 'Sports Collectors Digest',
      link: 'https://www.sportscollectorsdigest.com/?utm_source=hortmag.com&utm_medium=referral&utm_campaign=AIM%20Global%20Footer',
    },
    {
      name: 'Threads',
      link: 'https://www.threadsmagazine.com/?utm_source=hortmag.com&utm_medium=referral&utm_campaign=AIM%20Global%20Footer',
    },
    {
      name: 'Timber Home Living',
      link: 'https://www.timberhomeliving.com/?utm_source=hortmag.com&utm_medium=referral&utm_campaign=AIM%20Global%20Footer',
    },
    {
      name: 'Traditional Building',
      link: 'https://www.traditionalbuilding.com/?utm_source=hortmag.com&utm_medium=referral&utm_campaign=AIM%20Global%20Footer',
    },
    {
      name: 'Woodsmith',
      link: 'https://www.woodsmith.com/?utm_source=hortmag.com&utm_medium=referral&utm_campaign=AIM%20Global%20Footer',
    },
    {
      name: 'Woodshop News',
      link: 'https://www.woodshopnews.com/?utm_source=hortmag.com&utm_medium=referral&utm_campaign=AIM%20Global%20Footer',
    },
    {
      name: 'World Coin News',
      link: 'https://my.worldcoinnews.net/?utm_source=hortmag.com&utm_medium=referral&utm_campaign=AIM%20Global%20Footer',
    },
    {
      name: "Writer's Digest",
      link: 'https://www.writersdigest.com/?utm_source=hortmag.com&utm_medium=referral&utm_campaign=AIM%20Global%20Footer',
    },
  ],
};

const FooterLink = ({ site }: { site: { name: string; link: string } }) => (
  <Link
    component="a"
    key={site.name}
    href={site.link}
    target="_blank"
    sx={{
      color: 'inherit',
      textDecoration: 'none',
      transition: 'color 0.2s ease-in-out',
      fontSize: '10px',
      textAlign: 'center',
      textTransform: 'uppercase',
      '&:hover': {
        color: 'red',
        textDecoration: 'underline',
      },
    }}
  >
    {site.name}
  </Link>
);

const RelatedSitesList = ({ logo }: { logo: string }) => {
  const [footerOpen, setFooterOpen] = useState(false);
  const footerRef = useRef<HTMLElement>();
  const THEME_ID = getConfig().publicRuntimeConfig.themeId as MuiThemeType;

  const handleFooterToggle = () => {
    setFooterOpen((prevState) => {
      const newState = !prevState;
      if (!prevState) {
        // Delay scrolling to allow the footer to render
        setTimeout(() => {
          footerRef.current?.scrollIntoView({ behavior: 'smooth' });
        }, 100);
      }
      return newState;
    });
  };

  return (
    <Box
      sx={{
        flexDirection: 'column',
        display: 'flex',
        mx: 'auto',
        pt: 4,
        background: 'linear-gradient(to bottom, #bbb 0%, #fff 15px, #fff 100%)',
      }}
      ref={footerRef}
    >
      <Button
        variant="outlined"
        size="medium"
        sx={{
          mx: 'auto',
          fontSize: '12px',
          padding: '0.5rem 1rem',
          border: '1px solid',
          borderRadius: '0 !important',
          color: 'black',
          '&:hover': {
            color: 'red',
            transition: 'color 0.3s ease-in-out',
            backgroundColor: 'transparent',
          },
        }}
        disableRipple
        disableFocusRipple
        onClick={handleFooterToggle}
      >
        {footerOpen ? 'Hide AIM Sites [-]' : 'More AIM Sites [+]'}
      </Button>
      <Grid2
        component="div"
        spacing={2}
        columns={{ xs: 2, sm: 3 }}
        display={footerOpen ? 'flex' : 'none'}
        sx={{ mx: 'auto', my: 1 }}
      >
        <Grid2
          component="div"
          display="flex"
          justifyContent="center"
          flexDirection="column"
          alignItems="center"
          sx={{
            px: 2,
          }}
        >
          {AIM_SITES.links.slice(0, 12).map((site) => (
            <FooterLink key={site.link} site={site} />
          ))}
        </Grid2>
        <Divider orientation="vertical" flexItem />
        <Grid2
          component="div"
          display="flex"
          justifyContent="center"
          flexDirection="column"
          alignItems="center"
          sx={{
            px: 2,
          }}
        >
          {AIM_SITES.links.slice(12, 25).map((site) => (
            <FooterLink key={site.link} site={site} />
          ))}
        </Grid2>
        <Divider orientation="vertical" flexItem />
        <Grid2
          component="div"
          display="flex"
          justifyContent="center"
          flexDirection="column"
          alignItems="center"
          sx={{
            px: 2,
          }}
        >
          {AIM_SITES.links.slice(25, 30).map((site) => (
            <FooterLink key={site.link} site={site} />
          ))}
        </Grid2>
      </Grid2>
      {THEME_ID !== MuiThemeType.TRADITIONALBUILDINGSHOW && (
        <Box
          component="a"
          href="/"
          display="block"
          mx="auto"
          sx={{
            backgroundImage: `url(${logo})`,
            backgroundSize: 'cover', // Makes the image cover the box while maintaining aspect ratio
            backgroundPosition: 'center', // Centers the image
            width: '100%', // Ensure it takes full width of the container
            height: { xs: '91px' }, // Responsive height based on screen size
            maxWidth: '200px', // Prevent the image from exceeding the container width
            objectFit: 'cover',
            marginTop: '1rem',
            marginBottom: '1rem', // Ensures the image content fits well within its bounds
          }}
        />
      )}
      ;
    </Box>
  );
};

export default RelatedSitesList;
