import { gql } from 'graphql-request';
import { CategoryTemplateQuery } from '@orgnc/core/lib/themes/queries';
import {
  IDirectorySponsoredPostsQuery,
  IDirectorySponsoredPostsQueryVariables,
} from '@orgnc/core/generated/graphql-operations';

const query = gql`
  query DirectorySponsoredPostsQuery($dbId: Int) {
    posts(
      where: {
        tagSlugIn: ["sponsored", "directory-sponsor"]
        orderby: { field: TITLE, order: ASC }
        categoryId: $dbId
      }
    ) {
      nodes {
        id
        title
        slug
        uri
        excerpt
        primaryCategory {
          id
          uri
          name
        }
        featuredImage {
          node {
            altText
            caption
            mediaDetails {
              width
              height
            }
            mediaItemUrl
          }
        }
      }
      pageInfo {
        offsetPagination {
          hasMore
          hasPrevious
          total
        }
      }
    }
  }
`;

// Extend the interface to include the tagName property
interface ExtendedCategoryProps {
  databaseId: number;
  tagName?: string;
  [key: string]: any;
}

const directorySponsoredPostsQuery: CategoryTemplateQuery<
  IDirectorySponsoredPostsQuery,
  IDirectorySponsoredPostsQueryVariables
> = {
  name: 'directorySponsoredPostsQuery',
  query,
  queryArguments: (contentData: ExtendedCategoryProps) => {
    return {
      dbId: contentData.databaseId,
    };
  },
};

export default directorySponsoredPostsQuery;
