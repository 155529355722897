import { Box, Link as A, Typography } from '@mui/material';
import React from 'react';

import Link from '@orgnc/core/components/atoms/DisablePrefetchLink';
import RegisteredBlockType, {
  AcfTopStoryBlockProps,
} from '@orgnc/core/components/blocks/Blocks/types';
import PostFeaturedImage from '@orgnc/core/components/molecules/PostFeaturedImage';
import featuredImageAdapter from '@orgnc/core/lib/wordpress/api/adapters/featuredImageAdapter';
import { decodeHTMLEntities } from '@/lib/utilities/strings';

const AcfTopStoryBlockArticleExcerptLayout = ({
  area,
  className,
  frequency,
  post,
}: AcfTopStoryBlockProps) => {
  const { featuredImage } = featuredImageAdapter(post);
  const categoryName =
    post?.primaryCategory?.name || post?.categories?.nodes[0].name;
  const categoryUrl =
    post?.primaryCategory?.uri || post?.categories?.nodes[0].uri;
  return (
    <Box
      className={className}
      data-og-block-area={area}
      data-og-block-nth={frequency}
      data-og-block-type={RegisteredBlockType.AcfTopStoryBlock}
      sx={{
        backgroundColor: 'lightFlour.main',
        display: 'flex',
        flexDirection: {
          xs: 'column',
          sm: 'column',
          md: 'row',
        },
      }}
    >
      <Box
        sx={{
          flexBasis: '100%',
          px: { xs: 3, xl: 0 },
        }}
      >
        <Link href={post?.uri || ''} prefetch={false} passHref>
          <A title={post?.title || ''} underline="none" variant="inherit">
            <PostFeaturedImage
              ratio={16 / 9}
              src={featuredImage.node?.sourceUrl || ''}
            />
          </A>
        </Link>
      </Box>
      <Box
        sx={{
          flex: 1,
          flexBasis: '70%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          px: 3,
        }}
      >
        {categoryName && (
          <Link href={categoryUrl || ''} prefetch={false} passHref>
            <A sx={{ textDecoration: 'none' }}>
              <Typography
                sx={{
                  display: 'block',
                  textTransform: 'uppercase',
                  fontWeight: '600',
                  color: ({ palette }) => palette.secondary.main,
                  transition: '.2s ease',
                  '&:hover': {
                    filter: 'brightness(1.2)',
                  },
                }}
                variant="body2"
              >
                {categoryName}
              </Typography>
            </A>
          </Link>
        )}
        <Link href={post?.uri || ''} prefetch={false} passHref>
          <A
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '1rem',
              mt: {
                xs: 2,
              },
            }}
            title={post?.title || ''}
            underline="none"
            variant="inherit"
          >
            <Typography
              variant="h4"
              sx={{
                fontWeight: 400,
                transition: '.2s ease',
                '&:hover': {
                  color: ({ palette }) => palette.secondary.main,
                },
              }}
            >
              {post?.title || ''}
            </Typography>
          </A>
        </Link>

        {post?.excerpt && (
          <Typography
            variant="body1"
            aria-label={post?.excerpt ? decodeHTMLEntities(post.excerpt) : ''}
            dangerouslySetInnerHTML={{
              __html: post?.excerpt ? decodeHTMLEntities(post.excerpt) : '',
            }}
            sx={{
              fontWeight: 400,
              opacity: 0.7,
              mt: 2,
            }}
          />
        )}
      </Box>
    </Box>
  );
};

export default AcfTopStoryBlockArticleExcerptLayout;
