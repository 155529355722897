import DefaultHeader from '@/components/header/default-header/header';
import HortmagHeader from '@/components/header/hortmag/header';
import ScriptmagHeader from '@/components/header/scriptmag/header';
import WritersdigestHeader from '@/components/header/writersdigest/header';
import MilitarytraderHeader from '@/components/header/militarytrader/header';
import SportscollectorsdigestHeader from '@/components/header/sportscollectorsdigest/header';
import TraditionalbuildingHeader from '@/components/header/traditionalbuilding/header';
import TraditionalbuildingshowHeader from '@/components/header/traditionalbuildingshow/header';
import OldcarsweeklyHeader from '@/components/header/oldcarsweekly/header';
import PeriodHomesHeader from '@/components/header/period-homes/header';
import ArtsandcraftsHomes from '@/components/header/artsandcraftshomes/header';
import WoodshopnewsHeader from '@/components/header/woodshopnews/header';
import NumismaticHeader from '@/components/header/numismaticnews/header';
import AntiquetraderHeader from '@/components/header/antiquetrader/header';

import { ReactElement } from 'react';
import { MuiThemeType } from '../../themes';

type HeaderComponent = () => ReactElement;

type HeaderThemeRegistry = {
  [key: string]: HeaderComponent;
};

const headerThemeRegistry: HeaderThemeRegistry = {
  default: DefaultHeader,
  [MuiThemeType.HORTMAG]: HortmagHeader,
  [MuiThemeType.SCRIPTMAG]: ScriptmagHeader,
  [MuiThemeType.WRITERSDIGEST]: WritersdigestHeader,
  [MuiThemeType.MILITARYTRADER]: MilitarytraderHeader,
  [MuiThemeType.SPORTSCOLLECTORSDIGEST]: SportscollectorsdigestHeader,
  [MuiThemeType.TRADITIONALBUILDING]: TraditionalbuildingHeader,
  [MuiThemeType.TRADITIONALBUILDINGSHOW]: TraditionalbuildingshowHeader,
  [MuiThemeType.OLDCARSWEEKLY]: OldcarsweeklyHeader,
  [MuiThemeType.PERIOD_HOMES]: PeriodHomesHeader,
  [MuiThemeType.WOODSHOPNEWS]: WoodshopnewsHeader,
  [MuiThemeType.ARTSANDCRAFTSHOMES]: ArtsandcraftsHomes,
  [MuiThemeType.NUMISMATICNEWS]: NumismaticHeader,
  [MuiThemeType.ANTIQUETRADER]: AntiquetraderHeader,
};

const getThemeHeader = (themeId: string): HeaderComponent => {
  if (!headerThemeRegistry[themeId]) {
    return DefaultHeader;
  }
  return headerThemeRegistry[themeId];
};

export default getThemeHeader;
