import { useTheme } from '@mui/material';
import React from 'react';
import { IPageTemplateProps } from '@orgnc/core/lib/themes/templates/page';
import { CenterLayout } from '@orgnc/core/components/primitives';
import { PageContent } from '@orgnc/core/components/molecules';
import RegisteredBlockType from '@orgnc/core/components/blocks/Blocks/types';

const NumismaticNewsHomepageTemplate = ({
  page,
}: IPageTemplateProps): JSX.Element => {
  const { breakpoints, typography } = useTheme();
  return (
    <>
      <CenterLayout maxWidth={`${breakpoints.values.xl}${breakpoints.unit}`}>
        <PageContent
          blocks={page.blocks}
          sx={{
            my: 6,
            /**
             * FLOW CONTROL FOR ELEMENT SPACING
             *
             * Objective:
             * - To establish a consistent vertical spacing between direct sibling child elements within a container.
             * This ensures an organized flow of content and visual separation between distinct elements
             *
             * Caution:
             * Any modification to this spacing should ensure content integrity and maintain a balanced visual layout.
             */
            [`
                [data-og-block-type="${RegisteredBlockType.AcfListArticleBlock}"],
                [data-og-block-type="${RegisteredBlockType.AcfArticleListBlock}"],
                [data-og-block-type="${RegisteredBlockType.AcfArticleGridBlock}"]
            `]: {
              mx: 'auto',
              px: {
                xs: 2,
                sm: 3,
              },
            },
            [`
                [data-og-block-type="core/heading"]
            `]: {
              mx: 3,
              my: 2,
              py: 3,
              maxWidth: 'unset',
              textAlign: 'center',
              color: 'brand.text',
              fontFamily: typography?.fonts?.sans?.primary,
              fontSize: {
                md: '46px',
                sm: '20px',
              },
              fontWeight: 600,
              backgroundColor: 'secondary.main',
            },
            [`
                [data-og-block-type="core/paragraph"]
            `]: {
              px: 3,
              mb: 2,
              maxWidth: 'unset',
            },
            [`
                [data-og-block-type="core/paragraph"] p
            `]: {
              maxWidth: 'unset',
              fontSize: {
                md: '23px',
                xs: '18px',
              },
            },
            [`
                [data-og-block-type="acf/full-width-call-to-action"] h4
            `]: {
              color: 'brand.text',
              fontFamily: typography?.fonts?.sans?.primary,
              fontSize: {
                md: '46px',
                sm: '20px',
              },
            },
            [`
                [data-og-block-type="acf/full-width-call-to-action"] p
            `]: {
              color: 'brand.text',
              mt: 3,
            },
            [`.heading`]: {
              fontFamily: typography?.fonts?.sans?.primary,
            },
            [`.paragraph`]: {
              lineHeight: 1.15,
            },
          }}
          includeContentBlockStyling
        />
      </CenterLayout>
    </>
  );
};

export default NumismaticNewsHomepageTemplate;
