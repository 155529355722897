import {
  Box,
  ChipProps,
  Divider,
  Pagination,
  PaginationItem,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import {
  CenterLayout,
  ClusterLayout,
  StackLayout,
} from '@orgnc/core/components/primitives';
import { ICategoryTemplateProps } from '@orgnc/core/lib/themes/templates/category';
import {
  Breadcrumbs,
  DisablePrefetchLink,
  EmptyState,
} from '@orgnc/core/components/atoms';
import {
  FeaturedImage,
  HedCard,
  Tag,
} from '@orgnc/core/components/themes/Elements/components';
import { ContentStyling } from '@orgnc/core/components/molecules/PageContent';
import { useRouter } from 'next/router';
import { useThemeSettings } from '@orgnc/core/hooks';
import { useCallback, useMemo } from 'react';
import { defaultThemeSettings } from '@orgnc/core/lib/wordpress/api/adapters/themeSettingsAdapter';
import addTrailingSlash from '@orgnc/core/lib/utils/addTrailingSlash';

const CategoryTemplate = ({
  options = {},
  categoryPageData,
  pagination,
}: ICategoryTemplateProps) => {
  const { category } = categoryPageData;

  const theme = useTheme();

  const router = useRouter();

  // Define pagination logic
  const { data: themeSettings } = useThemeSettings();
  const articlesPerPage =
    themeSettings?.categoryPostsPerPage ||
    defaultThemeSettings.categoryPostsPerPage;
  const totalArticlesCount =
    category.posts?.pageInfo?.offsetPagination?.total ?? 0;
  const totalPages = Math.ceil(totalArticlesCount / articlesPerPage);
  const includeBreadcrumbs = !options?.disableBreadcrumbs;
  const showSubcategories = !category.displayOptions?.hideSubcategoryPills;
  const disableTitle = Boolean(options?.disableTitle);
  const categoryFeaturedImage = category.displayOptions?.featuredImage;
  const breadcrumbs = useMemo(() => {
    if (!includeBreadcrumbs) return [];

    // spread prevents mutations
    const bc = [{ text: 'Home', url: '/' }];

    if (category.ancestors?.nodes && category.ancestors.nodes.length > 0) {
      category.ancestors.nodes.forEach((ancestor) => {
        if (ancestor?.name && ancestor?.uri) {
          bc.push({ text: ancestor.name, url: ancestor.uri });
        }
      });
    }

    return bc;
  }, [category.ancestors?.nodes, includeBreadcrumbs]);

  const subcategoryTagVariant =
    String(options?.subcategoryTagVariant as ChipProps['variant']) || 'filled';

  // Handle page change in pagination
  const handleChange = useCallback(
    (_event: React.ChangeEvent<unknown>, value: number) => {
      router.push(
        `${addTrailingSlash(categoryPageData.category.uri)}page/${value}`
      );
    },
    [router, categoryPageData.category.uri]
  );

  const firstPost = category.posts.nodes[0];

  return (
    <Box sx={{ pt: 5, ...(options?.sx ?? {}) }}>
      <StackLayout space={theme.spacing(4)}>
        <CenterLayout
          maxWidth={`${theme.breakpoints.values.xl}${theme.breakpoints.unit}`}
          sx={{ width: '100%' }}
        >
          <StackLayout space={theme.spacing(5)}>
            <Box sx={{ px: { xs: 3, md: 10 } }}>
              {breadcrumbs.length > 0 && (
                <Breadcrumbs breadcrumbs={breadcrumbs} />
              )}
              <CenterLayout andText>
                <StackLayout space={theme.spacing(2)}>
                  {!disableTitle && (
                    <Typography variant="h1">
                      <Box
                        sx={{
                          display: 'inline-block',
                          borderBottom: `4px solid ${theme.palette.text.primary}`,
                        }}
                      >
                        {category.name}
                      </Box>
                    </Typography>
                  )}
                  <Stack
                    sx={{
                      alignItems: { xs: 'center', lg: 'flex-start' },
                      justifyContent: 'center',
                    }}
                    direction={{ xs: 'column', lg: 'row' }}
                    spacing={2}
                  >
                    <Box sx={{ maxWidth: '300px' }}>
                      {categoryFeaturedImage && (
                        <FeaturedImage
                          alt={categoryFeaturedImage.altText || ''}
                          ratio="4:3"
                          src={categoryFeaturedImage.sourceUrl || ''}
                        />
                      )}
                    </Box>
                    {category?.description && (
                      <ContentStyling
                        enabled
                        useUncomfortableCPL={false}
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          [`[data-rawhtml="1"] a`]: {
                            color: 'brand.header',
                          },
                        }}
                      >
                        <Typography
                          component="div" // this can't be a <p> since description HTML may contain another <p> tag
                          dangerouslySetInnerHTML={{
                            __html: category.description,
                          }}
                          data-rawhtml="1"
                          sx={{
                            color: 'text.secondary',
                            fontFamily: ({ typography }) =>
                              typography.fonts?.sans?.primary,
                            mt: 2,
                            mb: 1,
                            alignSelf: 'center',
                            maxWidth: 'unset',
                            iframe: {
                              border: 0,
                            },
                          }}
                          variant="h6"
                        />
                      </ContentStyling>
                    )}
                  </Stack>
                  {showSubcategories &&
                  categoryPageData?.category?.children?.nodes?.length ? (
                    <ClusterLayout
                      align="center"
                      justify="center"
                      space={theme.spacing(1)}
                    >
                      {category.children?.nodes?.map((tag) => (
                        <Tag
                          key={tag?.slug}
                          href={tag?.uri || ''}
                          label={tag?.name || ''}
                          variant={subcategoryTagVariant}
                        />
                      ))}
                    </ClusterLayout>
                  ) : null}
                </StackLayout>
              </CenterLayout>
            </Box>
            {/* Section for recent stories */}
            {firstPost ? (
              <Stack spacing={2} sx={{ px: { xs: 3, md: 10 } }}>
                {!disableTitle && <Divider sx={{ bgcolor: 'primary.light' }} />}
                <StackLayout space={theme.spacing(6)}>
                  <Stack sx={{ margin: '0 auto' }} spacing={3}>
                    {category.posts.nodes.slice(1).map((post) => (
                      <Stack
                        key={post.id}
                        sx={{ gap: 2 }}
                        direction={{ xs: 'column', sm: 'row' }}
                      >
                        <Box
                          sx={{
                            width: '100%',
                            maxWidth: { xs: 'initial', sm: '250px' },
                          }}
                        >
                          <DisablePrefetchLink href={post.uri || '#'} passHref>
                            <a>
                              <FeaturedImage
                                alt={post?.featuredImage?.node?.altText || ''}
                                ratio="16:9"
                                src={post?.featuredImage?.node?.sourceUrl || ''}
                              />
                            </a>
                          </DisablePrefetchLink>
                        </Box>
                        <HedCard
                          divider={false}
                          excludeAuthorLink={false}
                          excludeCategoryLink={false}
                          excludePublishDate={false}
                          post={post}
                        />
                      </Stack>
                    ))}
                  </Stack>
                  {/* Pagination control */}
                  {totalPages > 1 && (
                    <CenterLayout>
                      <Pagination
                        sx={{ mb: 2 }}
                        color="secondary"
                        count={totalPages}
                        onChange={handleChange}
                        page={pagination.pageNum}
                        renderItem={(item) => <PaginationItem {...item} />}
                      />
                    </CenterLayout>
                  )}
                </StackLayout>
              </Stack>
            ) : (
              <EmptyState title="No articles found." />
            )}
          </StackLayout>
        </CenterLayout>
      </StackLayout>
    </Box>
  );
};

export default CategoryTemplate;
