import { gql } from 'graphql-request';

const acfCategoriesGridBlockFragment = gql`
  fragment AcfCategoriesGridFragment on AcfCategoriesGridBlock {
    categoriesGridBlock {
      title
      textAlignment
      maxCategoriesNumber
      imageRatio
      columnsNumber
      categories {
        uri
        name
        categoryPostImage
        id
      }
    }
  }
`;

export default acfCategoriesGridBlockFragment;
