export function untrailingslashit(url: string): string {
  let newurl = url;
  while (newurl.length && newurl[newurl.length - 1] === '/') {
    newurl = newurl.substring(0, newurl.length - 1);
  }

  return newurl;
}

export function trailingslashit(url: string): string {
  return `${untrailingslashit(url)}/`;
}

/**
 * Formats a date string to a standardized uppercase format: "MMM DD, YYYY"
 * Example: "2024-12-03T20:00:00" becomes "DEC 3, 2024"
 *
 * @param {string} dateString - The date string to format (ISO 8601 format)
 * @returns {string} Formatted date string
 */
export function formatDate(dateString: string): string {
  if (!dateString) return '';

  const date = new Date(dateString);
  const month = date.toLocaleString('en-US', { month: 'short' }).toUpperCase();
  const day = date.getDate();
  const year = date.getFullYear();

  return `${month} ${day}, ${year}`;
}

/**
 * Checks if a URL is absolute or not.
 *
 * @param {string} url
 */
export function isAbsoluteUrl(url: string): boolean {
  if (typeof url !== 'string') {
    throw new TypeError(`Expected a \`string\`, got \`${typeof url}\``);
  }

  // Don't match Windows paths `c:\`
  if (/^[a-zA-Z]:\\/.test(url)) {
    return false;
  }

  // Scheme: https://tools.ietf.org/html/rfc3986#section-3.1
  // Absolute URL: https://tools.ietf.org/html/rfc3986#section-4.3
  return /^[a-zA-Z][a-zA-Z\d+\-.]*:/.test(url);
}

/**
 * @function isAudioOnly
 * Detects if ad is an audio only advert
 *
 * @param {Object} playerStore
 * @param {Object} playerStore.player - Full player reference
 * @param {string} playerStore.playerType - Player Type
 * @returns {Boolean}
 */
export function isAudioAdOnly({ player, playerType }: any): boolean {
  let currentAdModule = null;

  // If not tdplayer, abandon
  if (playerType !== 'tdplayer') {
    return false;
  }

  if (player && player.MediaPlayer && player.MediaPlayer.adManager) {
    currentAdModule = player.MediaPlayer.adManager.currentAdModule;
  }

  // Look for ad, return whether NOT an MP4.
  // eslint-disable-next-line no-prototype-builtins
  if (currentAdModule && currentAdModule.hasOwnProperty('html5Node')) {
    // eslint-disable-next-line prefer-regex-literals
    const regEx = new RegExp(/\.mp4(\?.*)?$/);
    const adUrl = currentAdModule.html5Node
      ? currentAdModule.html5Node.currentSrc || false
      : false;
    // eslint-disable-next-line no-console
    console.log(`TRITON PREROLL URL: ${adUrl}`);
    const retval = adUrl ? !regEx.test(adUrl) : true; // ONLY RETURN FALSE WHEN WE KNOW IT IS VIDEO
    // eslint-disable-next-line no-console
    console.log(`DID ${retval ? '' : 'NOT '}DETECT AUDIO ONLY PREROLL`);
    return retval;
  }

  return true;
}

/**
 * Decodes HTML entities and removes HTML tags from a string
 * Ensures consistent rendering between server and client to avoid hydration errors
 *
 * @param {string} text - The HTML text to decode
 * @returns {string} Cleaned text with HTML tags removed and entities decoded
 */
export function decodeHTMLEntities(text: string): string {
  if (!text) return '';

  // Remove HTML tags first
  const withoutTags = text.replace(/<[^>]*>/g, '');

  // Consistently replace HTML entities with the same characters
  // on both server and client to avoid hydration mismatches
  return withoutTags
    .replace(/&amp;/g, '&')
    .replace(/&lt;/g, '<')
    .replace(/&gt;/g, '>')
    .replace(/&quot;/g, '"')
    .replace(/&#039;/g, "'")
    .replace(/&#8230;/g, '...') // Use three dots consistently
    .replace(/&hellip;/g, '...') // Handle &hellip; consistently
    .replace(/&rsquo;/g, "'")
    .replace(/&nbsp;/g, ' ')
    .replace(/&ndash;/g, '-')
    .replace(/&mdash;/g, '--');
}

export default {
  untrailingslashit,
  trailingslashit,
  formatDate,
  isAudioAdOnly,
  decodeHTMLEntities,
};
