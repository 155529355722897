import Bowser from 'bowser';

export const isAndroid = () => {
  if (typeof window !== 'undefined') {
    const { userAgent } = window.navigator;
    return !!userAgent && userAgent.toLowerCase().indexOf('android') > -1;
  }
  return false;
};

export const isSafari = () => {
  const { userAgent } = window.navigator;
  return /^((?!chrome|android).)*safari/i.test(userAgent);
};

export const isChrome = () => {
  return !!(window as any).chrome;
};

export const isFireFox = () => {
  const { userAgent } = window.navigator;
  return userAgent.toLowerCase().indexOf('firefox') < -1;
};

export const isIPhone = () => {
  const { userAgent } = window.navigator;
  return !!userAgent.match(/iPhone/i);
};

export const isIPad = () => {
  const { userAgent } = window.navigator;

  return (
    !!userAgent.match(/iPad/i) ||
    (!!userAgent.match(/Mac/i) &&
      'ontouchend' in document) /* iPad OS 13+ in default desktop mode */
  );
};

export const isIOS = () => {
  if (typeof window !== 'undefined') {
    return isIPhone() || isIPad();
  }
  return false;
};

export const isWebKit = () => {
  const { userAgent } = window.navigator;
  return !!userAgent.match(/WebKit/i);
};

export const isIE11 = (): boolean => {
  let ie: number = 0;
  try {
    // eslint-disable-next-line
    const match = navigator.userAgent.match(/(MSIE |Trident.*rv[ :])([0-9]+)/);
    if (match && match[2]) {
      ie = parseInt(match[2], 10);
    }
  } catch (e) {
    // do nothing
  }
  return ie === 11;
};

export const isWindowsBrowser = () => {
  const browser = Bowser.getParser(window.navigator.userAgent);

  return browser.satisfies({
    windows: {
      ie: '>10',
      edge: '>15',
      chrome: '>68',
      firefox: '>48',
      safari: '>5',
    },
  });
};
