import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import PinterestIcon from '@mui/icons-material/Pinterest';
import XIcon from '@mui/icons-material/X';
import * as React from 'react';
import {
  SocialList as ElementsSocialList,
  ISocialListProps as IElementsSocialList,
} from '@orgnc/core/components/themes/Elements/components';

export interface ISocialListProps {
  networks: IElementsSocialList['networks'];
  size?: IElementsSocialList['size'];
  sx?: IElementsSocialList['sx'];
}

const SocialList = ({ networks, size = 'medium', sx }: ISocialListProps) => (
  <ElementsSocialList
    size={size}
    networks={networks}
    customIcons={{
      facebook: <FacebookIcon />,
      instagram: <InstagramIcon />,
      pinterest: <PinterestIcon />,
      twitter: <XIcon />,
    }}
    spacing={2}
    sx={{
      a: {
        background: 'transparent',
        transition: 'color 0.3s ease-in-out',
        p: 0,
        '&:hover': {
          background: 'transparent',
          color: 'secondary.light',
        },
      },
      ...sx,
    }}
  />
);

export default SocialList;
