import { useTemplateQuery } from '@orgnc/core/hooks';
import { IArticle } from '@orgnc/core/lib/wordpress/api/adapters/articleAdapter';
import { IMediaFragment } from '@orgnc/core/generated/graphql-operations';
import postAimFeaturedImageQuery from '../lib/queries/postAimFeaturedImageQuery';

export type HideableFeaturedImage = {
  enabled: boolean;
  featuredImage?: IMediaFragment;
};

const useHideablePostFeaturedImage = (
  post: IArticle
): HideableFeaturedImage => {
  const { data } = useTemplateQuery(postAimFeaturedImageQuery, post);

  return {
    enabled:
      data?.acfOptionsGeneral?.generalOptions?.aimPosts
        ?.enableHideableFeaturedImage ?? false,
    featuredImage:
      data?.post?.aimFeaturedImage?.hideableFeaturedImage ?? undefined,
  };
};

export default useHideablePostFeaturedImage;
