import { Box, Card, IconButton } from '@mui/material';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  PropsWithChildren,
} from 'react';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { DisablePrefetchLink } from '@orgnc/core/components/atoms';
import {
  FeaturedImage,
  FeaturedImageButton,
} from '@orgnc/core/components/themes/Elements/components';

import Typography from '@mui/material/Typography';
import { RenderBlockProps } from '@orgnc/core/components/blocks/Blocks/helpers/RenderBlock';

interface BlockItem {
  image: {
    sourceUrl: string;
    title: string;
    mediaDetails: {
      width: number;
      height: number;
    };
  };
  subtitle: string;
  title: string;
  url: string;
  excerpt?: string;
}

interface Post {
  databaseId: number;
  uri?: string;
  title?: string;
  excerpt?: string;
  featuredImage?: {
    node?: {
      sourceUrl?: string;
    };
  };
  categories?: {
    nodes: Array<{
      uri?: string;
      name?: string;
    }>;
  };
}

// Define a simpler type for the custom block data
interface CustomBlock {
  acfScriptmagArticlesShowcase?: {
    items: BlockItem[];
  };
  // Include any other required properties from IBlock
  name?: string;
}

// Create extended props interface
interface ScriptmagMainArticlesShowcaseProps
  extends Omit<RenderBlockProps, 'block'> {
  posts?: Post[];
  block: CustomBlock;
}

// Create a component that accepts our props
const AcfArticleShowcaseLayoutSquaredWSN = (
  props: PropsWithChildren<ScriptmagMainArticlesShowcaseProps>
) => {
  const { block } = props;
  const hasImageLazyLoad = true;
  const posts = props?.posts;
  const containerRef = useRef<HTMLElement>(null);
  const contentRef = useRef<HTMLElement>(null);
  const itemWidth = 150;
  const [visibleItems, setVisibleItems] = useState(5);

  // Get the default category (last one if available, otherwise first one)
  const getDefaultCategory = useCallback((post: Post) => {
    if (!post?.categories?.nodes?.length) {
      return null;
    }

    // Get the last category if available, otherwise get the first one
    const categories = post.categories.nodes;
    const defaultCategory =
      categories.length > 1 ? categories[categories.length - 1] : categories[0];

    return defaultCategory;
  }, []);

  // Access blockItems from the block prop
  const blockItems = block?.acfScriptmagArticlesShowcase?.items;

  // Determine which data source to use
  const useBlockItems = !posts?.length && blockItems && blockItems.length > 0;

  // Calculate the number of visible items based on container width
  useEffect(() => {
    const handleResize = () => {
      if (containerRef.current) {
        const containerWidth = containerRef.current.offsetWidth;
        const newVisibleItems = Math.max(
          1,
          Math.floor(containerWidth / itemWidth)
        );
        setVisibleItems(newVisibleItems);
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Handle left/right scroll
  const handleScroll = useCallback(
    (direction: number) => {
      if (contentRef.current) {
        const scrollAmount = direction * itemWidth * visibleItems; // Scroll by the width of visible items
        contentRef.current.scrollLeft += scrollAmount;
      }
    },
    [visibleItems]
  );

  const renderCarousel = useMemo(() => {
    // If we have no data to render, return null
    if (
      (!posts || posts.length === 0) &&
      (!blockItems || blockItems.length === 0)
    ) {
      return null;
    }
    return (
      <Box
        ref={containerRef}
        sx={{
          position: 'relative',
          width: useBlockItems ? 'calc(100% - 60px)' : '100%',
          overflow: 'hidden', // Hide content that overflows the container
          overflowY: 'hidden', // Explicitly prevent vertical scrolling
          px: useBlockItems ? '30px' : 0,
          // Only apply hover effect on xs and sm screens
          '@media (max-width: 899px)': {
            '&:hover .nav-button': {
              opacity: 1,
              visibility: 'visible',
            },
          },
        }}
      >
        {/* Left Navigation */}
        <IconButton
          onClick={() => handleScroll(-1)}
          className="nav-button"
          sx={{
            position: 'absolute',
            left: '10px',
            top: '50%',
            transform: 'translateY(-50%)',
            zIndex: 2,
            backgroundColor: 'primary.main',
            color: 'white',
            opacity: 0,
            visibility: 'hidden',
            display: { xs: 'flex', sm: 'flex', md: 'none' },
            transition: 'opacity .2s ease-in-out, visibility .2s ease-in-out',
            '&:hover': {
              opacity: 1,
              filter: 'brightness(1.7)',
              backgroundColor: 'brand.header',
            },
          }}
        >
          <KeyboardArrowLeftIcon />
        </IconButton>

        {/* Scrollable Content */}
        <Box
          ref={contentRef}
          sx={{
            display: 'flex',
            overflowX: 'hidden', // Disable horizontal scrolling
            overflowY: 'hidden', // Explicitly prevent vertical scrolling
            scrollBehavior: 'smooth', // Smooth scrolling effect
            width: '100%', // Full width of the container
            gap: 2,
            justifyContent: 'space-between',
          }}
        >
          {useBlockItems && blockItems
            ? // Render blockItems
              blockItems.map((item: BlockItem, index: number) => (
                <Box
                  key={index}
                  sx={{
                    minWidth: '150px',
                    maxWidth: 'calc(25% - 16px)',
                    width: '100%',
                    flex: '1 1 auto',
                  }}
                >
                  <Card
                    component="article"
                    sx={{
                      position: 'relative',
                      width: '100%',
                      paddingTop: '100%', // 1:1 aspect ratio
                      height: 0,
                    }}
                    raised={false}
                  >
                    {item.image?.sourceUrl && (
                      <DisablePrefetchLink
                        key={index}
                        href={item.url || ''}
                        passHref
                      >
                        <FeaturedImageButton
                          sx={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                          }}
                        >
                          <FeaturedImage
                            ratio="1:1"
                            loading={hasImageLazyLoad ? 'lazy' : 'eager'}
                            src={item.image.sourceUrl}
                            style={{ position: 'absolute', top: 0, left: 0 }}
                          />
                        </FeaturedImageButton>
                      </DisablePrefetchLink>
                    )}
                  </Card>
                  {item?.title && (
                    <Box
                      sx={{
                        mt: 2,
                      }}
                    >
                      <DisablePrefetchLink href={item?.url || '#'} passHref>
                        <>
                          <Typography
                            component="a"
                            variant="caption"
                            href={item?.url || ''}
                            sx={{
                              display: 'inline-flex',
                              backgroundColor: ({ palette }) =>
                                palette.brand.header,
                              color: 'secondary.main',
                              textDecoration: 'none',
                              textTransform: 'uppercase',
                              fontWeight: 700,
                              fontSize: '14px',
                              px: '10px',
                              my: 1.5,
                              alignSelf: 'flex-start',
                            }}
                          >
                            {item?.subtitle}
                          </Typography>
                          <Typography
                            component="a"
                            href={item?.url || ''}
                            sx={{
                              color: 'text.primary',
                              textDecoration: 'none',
                              fontWeight: 400,
                              fontSize: '1.15rem',
                              display: 'block',
                              lineHeight: 1.05,
                              fontFamily: (theme) =>
                                theme.typography?.fonts?.sans?.primary,
                              transition: 'color 0.3s ease-in-out',
                              '&:hover': {
                                color: 'secondary.main',
                              },
                            }}
                          >
                            {item?.title}
                          </Typography>
                          {item?.excerpt && (
                            <Box
                              sx={{
                                mt: 1,
                                color: 'text.secondary',
                                fontSize: '.95rem',
                                fontWeight: 300,
                                opacity: 0.85,
                                cursor: 'pointer',
                                display: {
                                  xs: 'none',
                                  lg: 'none',
                                  xl: 'block',
                                },
                              }}
                              dangerouslySetInnerHTML={{ __html: item.excerpt }}
                            />
                          )}
                        </>
                      </DisablePrefetchLink>
                    </Box>
                  )}
                </Box>
              ))
            : // Render posts
              posts?.map((post: Post) => (
                <Box
                  key={post.databaseId}
                  sx={{
                    minWidth: '150px',
                    maxWidth: 'calc(25% - 16px)',
                    width: '100%',
                    flex: '1 1 auto',
                  }}
                >
                  <Card
                    component="article"
                    sx={{
                      position: 'relative',
                      width: '100%',
                      paddingTop: '100%', // 1:1 aspect ratio
                      height: 0,
                    }}
                    raised={false}
                  >
                    {post.featuredImage?.node?.sourceUrl && (
                      <DisablePrefetchLink
                        key={post.databaseId}
                        href={post.uri || ''}
                        passHref
                      >
                        <FeaturedImageButton
                          sx={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                          }}
                        >
                          <FeaturedImage
                            ratio="1:1"
                            loading={hasImageLazyLoad ? 'lazy' : 'eager'}
                            src={post.featuredImage.node.sourceUrl}
                            style={{ position: 'absolute', top: 0, left: 0 }}
                          />
                        </FeaturedImageButton>
                      </DisablePrefetchLink>
                    )}
                  </Card>
                  {post?.title && (
                    <Box
                      sx={{
                        mt: 2,
                      }}
                    >
                      <DisablePrefetchLink href={post?.uri || '#'} passHref>
                        <>
                          <Typography
                            component="a"
                            variant="caption"
                            href={getDefaultCategory(post)?.uri || ''}
                            sx={{
                              display: 'inline-flex',
                              backgroundColor: ({ palette }) =>
                                palette.brand.header,
                              color: 'secondary.main',
                              textDecoration: 'none',
                              textTransform: 'uppercase',
                              fontWeight: 700,
                              fontSize: '14px',
                              px: '10px',
                              my: 1.5,
                              alignSelf: 'flex-start',
                            }}
                          >
                            {getDefaultCategory(post)?.name}
                          </Typography>
                          <Typography
                            component="a"
                            href={post?.uri || ''}
                            sx={{
                              color: 'text.primary',
                              textDecoration: 'none',
                              fontWeight: 400,
                              fontSize: {
                                xl: '1.15rem',
                                lg: '.85rem',
                              },
                              display: 'block',
                              lineHeight: 1.05,
                              fontFamily: (theme) =>
                                theme.typography?.fonts?.sans?.primary,
                              transition: 'color 0.3s ease-in-out',
                              '&:hover': {
                                color: 'secondary.main',
                              },
                            }}
                            dangerouslySetInnerHTML={{
                              __html: post.title || '',
                            }}
                          />
                          {post?.excerpt && (
                            <Box
                              sx={{
                                mt: 1,
                                color: 'text.secondary',
                                fontSize: '.95rem',
                                fontWeight: 300,
                                opacity: 0.85,
                                cursor: 'pointer',
                                display: {
                                  xs: 'none',
                                  lg: 'none',
                                  xl: 'block',
                                },
                              }}
                              dangerouslySetInnerHTML={{ __html: post.excerpt }}
                            />
                          )}
                        </>
                      </DisablePrefetchLink>
                    </Box>
                  )}
                </Box>
              ))}
        </Box>

        {/* Right Navigation */}
        <IconButton
          onClick={() => handleScroll(1)}
          className="nav-button"
          sx={{
            position: 'absolute',
            right: '10px',
            top: '50%',
            transform: 'translateY(-50%)',
            zIndex: 2,
            backgroundColor: 'primary.main',
            color: 'white',
            opacity: 0,
            visibility: 'hidden',
            display: { xs: 'flex', sm: 'flex', md: 'none' },
            transition: 'opacity .2s ease-in-out, visibility .2s ease-in-out',
            '&:hover': {
              opacity: 1,
              filter: 'brightness(1.6)',
              backgroundColor: 'brand.header',
            },
          }}
        >
          <KeyboardArrowRightIcon />
        </IconButton>
      </Box>
    );
  }, [
    handleScroll,
    hasImageLazyLoad,
    posts,
    blockItems,
    useBlockItems,
    getDefaultCategory,
  ]);

  return renderCarousel;
};

// Simple export without type assertion - we'll handle it in theme.ts
export default AcfArticleShowcaseLayoutSquaredWSN;
