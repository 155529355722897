import { playfairDisplay, openSans } from '../utils/localFonts';
import { getDefaultTheme } from './default-theme/default-theme';

export default getDefaultTheme({
  color: {
    primary: '#000000',
    secondary: 'rgb(238, 45, 47)',
    error: '#ea252a',
    brand: {
      main: '#B63623',
      text: 'rgb(255, 255, 255)',
    },
    text: {
      primary: '#000000',
      secondary: '#000000',
    },
  },
  font: {
    base: `${openSans.style.fontFamily}`,
    sans: `${openSans.style.fontFamily}`,
    serif: `${playfairDisplay.style.fontFamily}`,
  },
});
