import { SiteSpecificComponentContext } from '@orgnc/core/lib/themes/SiteSpecificComponentsProvider';
import MUIThemeProvider from '@orgnc/core/components/organisms/MUIThemeProvider';
import { getTheme } from '../theme';
import { getDomainThemeId } from '../themes';
import { openSans, merriWeather } from '../utils/localFonts';

const StaticApp: React.FunctionComponent<{ children: React.ReactNode }> = ({
  children,
}) => {
  return (
    <>
      <style jsx global>{`
        html {
          font-family: ${openSans.style.fontFamily};
          font-family: ${merriWeather.style.fontFamily};
        }
        html,
        body {
          margin: 0;
          padding: 0;
        }
      `}</style>
      <SiteSpecificComponentContext.Provider
        value={getTheme(getDomainThemeId())}
      >
        <MUIThemeProvider>{children}</MUIThemeProvider>
      </SiteSpecificComponentContext.Provider>
    </>
  );
};

export default StaticApp;
