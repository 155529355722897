import { playfairDisplay } from '../utils/localFonts';
import { getDefaultTheme } from './default-theme/default-theme';

export default getDefaultTheme({
  color: {
    primary: '#000000',
    secondary: '#ff6a00',
    error: '#ea252a',
    brand: {
      main: '#000000',
      text: '#fff',
    },
    text: {
      primary: '#333333',
      secondary: '#777777',
    },
  },
  font: {
    base: `"Helvetica"`,
    sans: `"Helvetica"`,
    serif: `${playfairDisplay.style.fontFamily}`,
  },
});
