import { gql } from 'graphql-request';
import { CategoryTemplateQuery } from '@orgnc/core/lib/themes/queries';
import {
  IDirectoryPostsQuery,
  IDirectoryPostsQueryVariables,
} from '@orgnc/core/generated/graphql-operations';
import nonNullable from '@orgnc/core/lib/utils/types/nonNullable';

const query = gql`
  query DirectoryPostsQuery($dbId: Int, $perPage: Int, $offset: Int) {
    posts(
      where: {
        categoryId: $dbId
        orderby: { field: TITLE, order: ASC }
        offsetPagination: { size: $perPage, offset: $offset }
      }
    ) {
      nodes {
        id
        databaseId
        title
        slug
        uri
        excerpt
        primaryCategory {
          name
          uri
        }
        featuredImage {
          node {
            altText
            caption
            mediaDetails {
              width
              height
            }
            mediaItemUrl
          }
        }
      }
      pageInfo {
        offsetPagination {
          hasMore
          hasPrevious
          total
        }
      }
    }
  }
`;

const DirectoryPostsQuery: CategoryTemplateQuery<
  IDirectoryPostsQuery,
  IDirectoryPostsQueryVariables
> = {
  name: 'DirectoryPostsQuery',
  query,
  cacheKeys: (data) => {
    return (
      data.posts?.nodes?.filter(nonNullable)?.map((post) => `p-${post.id}`) ??
      []
    );
  },
  queryArguments: (contentData) => {
    // Extract pagination properties safely from the contentData
    const perPage = (contentData as any)?.perPage || 16;
    const offset = (contentData as any)?.offset || 0;
    return {
      dbId: contentData?.databaseId || 0,
      perPage,
      offset,
    };
  },
};

export default DirectoryPostsQuery;
