import React, { useMemo } from 'react';

import { DisablePrefetchLink } from '@orgnc/core/components/atoms';
import {
  FeaturedImage,
  FeaturedImageButton,
} from '@orgnc/core/components/themes/Elements/components';
import Typography from '@mui/material/Typography';
import { IArticleGridProps } from '@orgnc/core/components/blocks/AcfArticleListBlock/Layouts';
import { Box, useTheme } from '@mui/material';

const SimpleGridLayout: React.FC<IArticleGridProps> = ({
  hasImageLazyLoad = true,
  posts,
}) => {
  const theme = useTheme();
  const primaryPosts = useMemo(() => {
    if (Array.isArray(posts)) {
      if (posts.length > 0) {
        return (
          <Box
            sx={{
              display: 'flex',
              flexWrap: { md: 'nowrap', xs: 'wrap' },
              gap: 2,
              padding: 0,
              width: '100%',
              overflowX: { xs: 'auto', sm: 'hidden' },
            }}
          >
            {posts.map((post) => (
              <Box
                key={post.databaseId}
                sx={{
                  flex: 1,
                  minWidth: { md: 0, sm: '100%' },
                  position: 'relative',
                  mb: 2,
                }}
              >
                <DisablePrefetchLink href={post.uri || ''} passHref>
                  <Box
                    component="article"
                    sx={{
                      position: 'relative',
                      width: '100%',
                      aspectRatio: { xs: '16/9', sm: '16/9', md: '3/4' },
                      '&:before': {
                        transition: 'background-color 0.3s ease-in-out',
                        content: '""',
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                        top: 0,
                        left: 0,
                        background:
                          'linear-gradient(to top, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.15))',
                        display: 'block',
                        zIndex: 1,
                      },
                    }}
                  >
                    {post.featuredImage?.node?.sourceUrl && (
                      <FeaturedImageButton
                        sx={{
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          width: '100%',
                          height: '100%',
                          '& > .MuiBox-root': {
                            width: '100%',
                            height: '100%',
                          },
                          '& img': {
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                            objectPosition: 'center',
                          },
                        }}
                      >
                        <FeaturedImage
                          ratio={theme.breakpoints.up('md') ? '3:4' : '16:9'}
                          loading={hasImageLazyLoad ? 'lazy' : 'eager'}
                          src={post.featuredImage.node.sourceUrl}
                        />
                      </FeaturedImageButton>
                    )}

                    <Box
                      sx={{
                        position: 'absolute',
                        bottom: 0,
                        left: 0,
                        px: 2,
                        py: 2,
                        width: '100%',
                        color: 'white',
                        zIndex: 2,
                      }}
                    >
                      {post?.title && (
                        <Typography
                          component="h3"
                          dangerouslySetInnerHTML={{ __html: post.title }}
                          sx={{
                            color: 'white',
                            textDecoration: 'none',
                            fontWeight: 400,
                            fontSize: '22px',
                            display: 'block',
                            lineHeight: 1.1,
                            fontFamily: theme.typography?.fonts?.serif?.primary,
                            mb: 1,
                          }}
                        />
                      )}
                    </Box>
                  </Box>
                </DisablePrefetchLink>
              </Box>
            ))}
          </Box>
        );
      }
    }

    return null;
  }, [
    hasImageLazyLoad,
    posts,
    theme.breakpoints,
    theme.typography?.fonts?.serif?.primary,
  ]);

  if (!primaryPosts) return null;

  return primaryPosts;
};

export default SimpleGridLayout;
