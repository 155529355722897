import Drawer from '@mui/material/Drawer';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Divider,
  InputAdornment,
  TextField,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import * as React from 'react';
import SocialList from '@/components/header/SocialList';
import { INavBarProps as IElementsNavBarProps } from '@orgnc/core/components/themes/Elements/components';
import { ISocialListProps } from '@orgnc/core/components/themes/Elements/components/SocialList';
import { ModalProps } from '@orgnc/core/components/atoms/Modal';

export interface INavBarProps {
  open: boolean;
  onClose: ModalProps['onClose'];
  categoriesMenu: IElementsNavBarProps['menuItems'];
  additionalMenu: IElementsNavBarProps['menuItems'];
  networks: ISocialListProps['networks'];
  sx?: {};
}

const DrawerMenu = ({
  open,
  onClose,
  networks,
  categoriesMenu,
  additionalMenu,
  sx,
}: INavBarProps) => {
  const searchOptions = ['Movies', 'TV Shows', 'Actors'];
  const [expanded, setExpanded] = React.useState<string | false>('panel1');

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  return (
    <Drawer
      id="menu-appbar"
      open={open}
      onClose={onClose}
      variant="temporary"
      sx={{
        display: { xs: 'block' },
        '& .MuiDrawer-paper': {
          color: 'transparent',
          backgroundColor: 'rgba(0, 0, 0, 0.6)',
          width: { xs: '100%', sm: '400px' },
        },
        ...sx,
        // "& .MuiBackdrop-root": {
        // 	color: "white",
        // 	opacity: 0.5,
        // },
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'justify-between',
          margin: '1rem',
          color: 'white',
        }}
      >
        <Autocomplete
          freeSolo
          id="free-solo-2-demo"
          disableClearable
          options={searchOptions}
          size="small"
          sx={{
            width: { xs: 400, sm: 300 },
            '& .MuiInputBase-root': {
              color: 'white', // Text color in the input field
            },
            '& .MuiInputLabel-root': {
              color: 'white', // Label color
            },
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: 'none', // Border color
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: 'none', // Border color on hover
            },
          }}
          renderInput={(params) => (
            <TextField
              sx={{
                color: 'white',
                text: 'white',
                backgroundColor: 'rgba(255, 255, 255, 0.5)',
                borderRadius: '5px',
              }}
              {...params}
              label="Search input"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon sx={{ color: 'white' }} />
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
        <CloseIcon
          onClick={onClose}
          sx={{
            fontSize: '2rem', // replace with rem
            marginBottom: 'auto',
            marginTop: 'auto',
            marginLeft: 'auto',
            justifyContent: 'right',
            cursor: 'pointer',
            color: 'white',
            '&:hover': {
              color: 'gray',
              transition: 'color 0.3s ease-in-out',
            },
          }}
        />
      </div>

      {categoriesMenu?.map((category) =>
        category.children?.length ? (
          <Accordion
            disableGutters
            elevation={0}
            expanded={expanded === `${category.id}`} // will be replaced with category id or name
            onChange={handleChange(`${category.id}`)}
            key={category.id}
            // onClick={handleCloseNavMenu}
            sx={{
              backgroundColor: 'transparent',
              borer: 'none',
              boxShadow: 'none',
              color: 'white',
              fontSize: '1.5rem',
              cursor: 'pointer',
              zIndex: 0,
              '&.MuiPaper-root': {
                border: 'none',
                boxShadow: 'none',
                color: 'transparent',
              },
            }}
          >
            <AccordionSummary
              sx={{
                border: 'none',
                boxShadow: 'none',
                '&:hover': {
                  backgroundColor: ({ palette }) => palette.secondary.main,
                },
              }}
              expandIcon={
                expanded === `${category.id}` ? (
                  <RemoveIcon
                    sx={{
                      color: ({ palette }) => palette.brand.text,
                      fontSize: '1.5rem',
                    }}
                  />
                ) : (
                  <AddIcon
                    sx={{
                      color: ({ palette }) => palette.brand.text,
                      fontSize: '1.5rem',
                    }}
                  />
                )
              }
            >
              <Typography
                component="a"
                sx={{
                  color: ({ palette }) => palette.brand.text,
                  textDecoration: 'unset',
                }}
                variant="h5"
                href={category.path || '#'}
              >
                {category.label}
              </Typography>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                display: 'flex',
                flexDirection: 'column',
                color: ({ palette }) => palette.brand.text,
                margin: '0 0 0 0 !important',
                padding: '0 0 0 0 !important',
              }}
            >
              {category.children.map((subcategory) => (
                <Typography
                  key={subcategory.id}
                  component="a"
                  href={subcategory.path || '#'}
                  sx={{
                    color: ({ palette }) => palette.brand.text,
                    textDecoration: 'none',
                  }}
                >
                  <AccordionSummary
                    sx={{
                      width: '100%',
                      px: 4,
                      '&:hover': {
                        backgroundColor: ({ palette }) =>
                          palette.secondary.main,
                      },
                    }}
                  >
                    {subcategory.label}
                  </AccordionSummary>
                </Typography>
              ))}
            </AccordionDetails>
          </Accordion>
        ) : (
          <Typography
            key={category.id}
            component="a"
            sx={{
              color: ({ palette }) => palette.brand.text,
              textDecoration: 'unset',
              '&:hover': {
                backgroundColor: ({ palette }) => palette.secondary.main,
              },
              height: '52px',
              lineHeight: '52px',
              px: 2,
            }}
            variant="h5"
            href={category.path || '#'}
          >
            {category.label}
          </Typography>
        )
      )}
      <SocialList
        sx={{
          mt: 5,
          px: 2,
        }}
        networks={networks}
      />
      <Divider
        variant="middle"
        sx={{ backgroundColor: 'white', margin: '1rem' }}
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {additionalMenu?.map((item) =>
          item.children?.length ? (
            <Box
              key={item.id}
              sx={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  color: ({ palette }) => palette.brand.text,
                  '&:hover': {
                    backgroundColor: ({ palette }) => palette.common.black,
                  },
                  textTransform: 'uppercase',
                  px: 2,
                  py: 1,
                }}
              >
                {item.label}
              </Typography>
              {item.children.map((child) => (
                <Typography
                  component="a"
                  href={child.path || '#'}
                  style={{ textDecoration: 'none' }}
                  key={child.id}
                  variant="body1"
                  // @ts-ignore - Property 'target' exists at runtime but not in type definition
                  target={child.target}
                  // @ts-ignore - Property 'target' exists at runtime but not in type definition
                  rel={child.target ? 'noopener' : undefined}
                  sx={{
                    color: ({ palette }) => palette.brand.text,
                    '&:hover': {
                      backgroundColor: ({ palette }) => palette.common.black,
                    },
                    textTransform: 'uppercase',
                    px: 2,
                    py: 1,
                  }}
                >
                  {child.label}
                </Typography>
              ))}
            </Box>
          ) : (
            <Typography
              component="a"
              href={item.path || '#'}
              style={{ textDecoration: 'none' }}
              key={item.id}
              variant="body1"
              // @ts-ignore - Property 'target' exists at runtime but not in type definition
              target={item.target}
              // @ts-ignore - Property 'target' exists at runtime but not in type definition
              rel={item.target ? 'noopener' : undefined}
              sx={{
                color: ({ palette }) => palette.brand.text,
                '&:hover': {
                  backgroundColor: ({ palette }) => palette.common.black,
                },
                textTransform: 'uppercase',
                px: 2,
                py: 1,
              }}
            >
              {item.label}
            </Typography>
          )
        )}
      </Box>
    </Drawer>
  );
};

export default DrawerMenu;
