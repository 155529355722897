import React, { useMemo } from 'react';

import { DisablePrefetchLink } from '@orgnc/core/components/atoms';
import {
  FeaturedImage,
  FeaturedImageButton,
} from '@orgnc/core/components/themes/Elements/components';
import { Box, Link, useTheme } from '@mui/material';
import Typography from '@mui/material/Typography';
import { IArticleGridProps } from '@orgnc/core/components/blocks/AcfArticleListBlock/Layouts';

const TbsArticleGridLayout: React.FC<IArticleGridProps> = ({
  hasImageLazyLoad = true,
  posts,
}) => {
  const theme = useTheme();

  const primaryPosts = useMemo(() => {
    if (Array.isArray(posts)) {
      if (posts.length > 0) {
        return (
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: {
                xs: '1fr',
                sm: 'repeat(3, 1fr)',
              },
              gap: 3,
              padding: 0,
              mb: '4rem',
            }}
          >
            {posts.map((post) => (
              <DisablePrefetchLink
                key={post.databaseId}
                href={post.uri || ''}
                passHref
              >
                <Box
                  component="article"
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    position: 'relative',
                    boxSizing: 'border-box',
                  }}
                >
                  {post.featuredImage?.node?.sourceUrl && (
                    <FeaturedImageButton>
                      <FeaturedImage
                        ratio="4:3"
                        loading={hasImageLazyLoad ? 'lazy' : 'eager'}
                        src={post.featuredImage.node.sourceUrl}
                        style={{ objectFit: 'cover' }}
                      />
                    </FeaturedImageButton>
                  )}
                  {post.primaryCategory && (
                    <Typography
                      component="a"
                      variant="caption"
                      href={post?.primaryCategory.uri || ''}
                      sx={{
                        color: 'primary.main',
                        textDecoration: 'none',
                        textTransform: 'uppercase',
                        transition: '0.3s ease-in-out',
                        '&:hover': {
                          color: 'primary.main',
                          filter: 'brightness(1.2)',
                        },
                        fontWeight: 600,
                        fontSize: '13px',
                        pt: 2,
                        pb: 1,
                      }}
                    >
                      {post?.primaryCategory.name}
                    </Typography>
                  )}
                  <Box
                    sx={{
                      py: 0,
                      color: 'text.primary',
                    }}
                  >
                    {post?.title && (
                      <Box sx={{ lineHeight: 1.05 }}>
                        <DisablePrefetchLink href={post?.uri || '#'} passHref>
                          <Link
                            dangerouslySetInnerHTML={{ __html: post.title }}
                            sx={{
                              textDecoration: 'none',
                              color: 'inherit',
                              fontFamily:
                                theme.typography?.fonts?.sans?.primary,
                              fontSize: '1.15rem',
                              lineHeight: 1,
                              transition: '0.3s ease-in-out',
                              '&:hover': {
                                opacity: 0.6,
                              },
                            }}
                          />
                        </DisablePrefetchLink>
                      </Box>
                    )}
                  </Box>
                  {post?.excerpt && (
                    <Box
                      sx={{
                        mt: 1,
                        color: 'text.secondary',
                        fontSize: '0.8rem',
                        fontWeight: 300,
                        opacity: 0.85,
                        cursor: 'pointer',
                        display: { xs: 'none', sm: 'block' },
                      }}
                      dangerouslySetInnerHTML={{ __html: post.excerpt }}
                    />
                  )}
                </Box>
              </DisablePrefetchLink>
            ))}
          </Box>
        );
      }
    }

    return null;
  }, [hasImageLazyLoad, posts, theme.typography?.fonts?.sans?.primary]);

  if (!primaryPosts) return null;

  return primaryPosts;
};

export default TbsArticleGridLayout;
