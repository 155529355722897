import dynamic from 'next/dynamic';
import DocumentHead from '@/components/DocumentHead';
import createTheme from '@orgnc/core/lib/themes/createTheme';
import getConfig from 'next/config';
import Footer from '@/components/Footer';
import elementsTheme from '@orgnc/core/components/themes/Default';
import directoryCategoriesQuery from '@/lib/queries/directoryCategoriesQuery';
import ElementsDefaultPageTemplate from '@orgnc/core/components/themes/Elements/components/templates/Page';
import ITheme from '@orgnc/core/lib/themes/ITheme';
import getArticleTemplate from '@orgnc/core/lib/themes/templates/functions/getArticleTemplate';
import getCategoryTemplate from '@orgnc/core/lib/themes/templates/functions/getCategoryTemplate';
import { GetPageTemplate } from '@orgnc/core/lib/themes/templates/functions/getPageTemplate';
import { getPageTemplateByName } from '@orgnc/core/lib/themes/templates/page';
import { defaultThemeSettings } from '@orgnc/core/lib/wordpress/api/adapters/themeSettingsAdapter';
import postCommentsQuery from '@orgnc/core/lib/wordpress/api/queries/postCommentsQuery';
import ArticleTemplate from '@/components/templates/Article/ArticleTemplate';
import { composeEndpointUri } from '@orgnc/core/lib/wordpress/api/gqlFetcher';
import SimpleGridLayout from '@/components/blocks/ArticleList/SimpleGridLayout';
import CircularCardLayout from '@/components/blocks/ArticleList/CircularCardLayout';
import AcfTopStoryBlockArticleLayout from '@/components/blocks/AcfTopStoryBlock/AcfTopStoryBlockArticleLayout';
import AcfTopStoryBlockHeroLayout from '@/components/blocks/AcfTopStoryBlock/AcfTopStoryBlockHeroLayout';
import AcfTopStoryBlockHeroLayoutWSN from '@/components/blocks/AcfTopStoryBlock/AcfTopStoryBlockHeroLayoutWSN';
import getThemeHeader from '@/components/header';
import acfFullWidthCallToActionBlockFragment from '@/lib/queries/acfFullWidthCallToActionBlockFragment';
import MainArticleGridLayout from '@/components/blocks/ArticleList/MainArticleGridLayout';
import MainRecentArticleGridLayout from '@/components/blocks/ArticleList/MainRecentArticleGridLayout';
import acfScriptmagArticlesShowcaseFragment from '@/lib/queries/acfScriptmagArticlesShowcaseFragment';
import DefaultHomepageTemplate from '@/components/templates/Homepage/DefaultHomepageTemplate';
import HortmagHomepageTemplate from '@/components/templates/Homepage/HortmagHomepageTemplate';
import ArticleGridAdsAndFeaturedLayout from '@/components/blocks/ArticleList/ArticleGridAdsAndFeaturedLayout';
import TraditionalBuildingHomepageTemplate from '@/components/templates/Homepage/TraditionalBuildingHomepageTemplate';
import TraditionalBuildingShowHomepageTemplate from '@/components/templates/Homepage/TraditionalBuildingShowHomepageTemplate';
import SportsCollectorsDigestHomepageTemplate from '@/components/templates/Homepage/SportsCollectorsDigestHomepageTemplate';
import ArticlesGalleryAndAdsLayout from '@/components/blocks/ArticleList/ArticlesGalleryAndAdsLayout';
import AcfArticleShowcaseSplitLayout from '@/components/blocks/AcfArticleShowcase/AcfArticleShowcaseSplitLayout';
import AcfArticleShowcaseLayout from '@/components/blocks/AcfArticleShowcase/AcfArticleShowcaseLayout';
import ArticlesGallerySplitLayout from '@/components/blocks/ArticleList/ArticlesGallerySplitLayout';
import ArticlesGalleryQuadSplitLayout from '@/components/blocks/ArticleList/ArticlesGalleryMultiSplitLayout';
import TbsArticleGridLayout from '@/components/blocks/ArticleList/TbsArticleGridLayout';
import GoogleAdTagBlock from '@/components/blocks/GoogleAdTagBlock';
import WoodshopnewsHomepageTemplate from '@/components/templates/Homepage/WoodshopnewsHomepageTemplate';
import AcfArticleShowcaseLayoutWSN from '@/components/blocks/AcfArticleShowcase/AcfArticleShowcaseLayoutWSN';
import AcfArticleShowcaseLayoutSquaredWSN from '@/components/blocks/AcfArticleShowcase/AcfArticleShowcaseLayoutSquaredWSN';
import WsnArticleGridAdsAndFeaturedLayout from '@/components/blocks/ArticleList/WsnArticleGridAdsAndFeaturedLayout';
import WritersdigestPageTemplate from '@/components/templates/Page/WritersdigestPageTemplate';
import WdArticleGridAdsLayout from '@/components/blocks/ArticleList/WdArticleGridAdsLayout';
import WdArticleGridNoAdsLayout from '@/components/blocks/ArticleList/WdArticleGridNoAdsLayout';
import WdRecentArticleGridLayout from '@/components/blocks/ArticleList/WdRecentArticleGridLayout';
import WdArticleTemplate from '@/components/templates/Article/WdArticleTemplate';
import AcfTopStoryBlockArticleExcerptLayout from '@/components/blocks/AcfTopStoryBlock/AcfTopStoryBlockArticleExcerptLayout';
import AchCircularCardLayout from '@/components/blocks/ArticleList/AchCircularCardLayout';
import AcfCategoriesGridBlock from '@/components/blocks/AcfCategoriesGridBlock';
import acfCategoriesGridBlockFragment from '@/lib/queries/acfCategoriesGridBlockFragment';
import PeriodHomesHomepageTemplate from '@/components/templates/Homepage/PeriodHomesHomepageTemplate';
import AcfTopStoryBlockHeroLayoutACH from '@/components/blocks/AcfTopStoryBlock/AcfTopStoryBlockHeroLayoutACH';
import ArtsCraftsHomesHomepageTemplate from '@/components/templates/Homepage/ArtsCraftsHomesHomepageTemplate';
import AchArticleGridLayout from '@/components/blocks/ArticleList/AchArticleGridLayout';
import AchArticleGridLayout3col from '@/components/blocks/ArticleList/AchArticleGridLayout3col';
import NnArticlesListLayout from '@/components/blocks/ArticleList/NnArticlesListLayout';
import AchArticleGridLayout3colExcerpt from '@/components/blocks/ArticleList/AchArticleGridLayout3colExcerpt';
import AcfTopStoryBlockHeroLayoutNN from '@/components/blocks/AcfTopStoryBlock/AcfTopStoryBlockHeroLayoutNN';
import NnArticlesList4colLayout from '@/components/blocks/ArticleList/NnArticlesList4colLayout';
import NumismaticNewsHomepageTemplate from '@/components/templates/Homepage/NumismaticNewsHomepageTemplate';
import NnRecentArticleGridLayout from '@/components/blocks/ArticleList/NnRecentArticleGridLayout';
import NnRecentArticlesGridLayout from '@/components/blocks/ArticleList/NnRecentArticlesGridLayout';
import NnArticleTemplate from '@/components/templates/Article/NnArticleTemplate';
import AchArticleTemplate from '@/components/templates/Article/AchArticleTemplate';
import MtMvArticleGridAdsLayout from '@/components/blocks/ArticleList/MtMvArticleGridAdsLayout';
import MtArticleGridAdsLayout from '@/components/blocks/ArticleList/MtArticleGridAdsLayout';
import WritersDigestHomepageTemplate from '@/components/templates/Homepage/WritersDigestHomepageTemplate';
import OldsCarWeeklyHomepageTemplate from '@/components/templates/Homepage/OldsCarWeeklyHomepageTemplate';
import AntiqueTraderHomepageTemplate from '@/components/templates/Homepage/AntiqueTraderHomepageTemplate';
import directorySponsoredPostsQuery from '@/lib/queries/directorySponsoredPostsQuery';
import directoryFeaturedPostsQuery from '@/lib/queries/directoryFeaturedPostsQuery';
import directoryPostsQuery from '@/lib/queries/directoryPostsQuery';
import AchDirectoryTemplateContent from '@/components/templates/Category/artsandcraftshomes/AchDirectoryTemplateContent';
import DirectoryTemplate from '@/components/templates/Category/DirectoryTemplate';
import AchDirectoryChildTemplateContent from '@/components/templates/Category/artsandcraftshomes/AchDirectoryChildTemplateContent';
import MtDirectoryTemplateContent from '@/components/templates/Category/militarytrader/MtDirectoryTemplateContent';
import DirectoryTemplateContent from '@/components/templates/Category/default/DirectoryTemplateContent';
import ScdDirectoryTemplateContent from '@/components/templates/Category/sportscollectorsdigest/ScdDirectoryTemplateContent';
import AtDirectoryTemplateContent from '@/components/templates/Category/antiquetrader/AtDirectoryTemplateContent';
import ScdArticleTemplate from '@/components/templates/Article/ScdArticleTemplate';
import TbArticleTemplate from '@/components/templates/Article/TbArticleTemplate';
import PhArticleTemplate from '@/components/templates/Article/PhArticleTemplate';
import TbsArticleTemplate from '@/components/templates/Article/TbsArticleTemplate';
import AcfTopStoryBlockHeroLayoutPH from './components/blocks/AcfTopStoryBlock/AcfTopStoryBlockHeroLayoutPH';
import TbDirectoryTemplateContent from './components/templates/Category/traditionalbuilding/TbDirectoryTemplateContent';
import { getMuiTheme, MuiThemeType } from './themes';
import AcfTopStoryBlockHeroLayoutOW from './components/blocks/AcfTopStoryBlock/AcfTopStoryBlockHeroLayoutOW';
import OwMainRecentArticleGridLayout from './components/blocks/ArticleList/OwMainRecentArticleGridLayout';
import CategoryTemplate from './components/templates/Category/CategoryTemplate';
import PhDirectoryTemplateContent from './components/templates/Category/periodhomes/PhDirectoryTemplateContent';
import PhDirectoryChildTemplateContent from './components/templates/Category/periodhomes/PhDirectoryChildTemplateContent';
import headerCardsQuery from './lib/queries/headerCardsQuery';
import postAimFeaturedImageQuery from './lib/queries/postAimFeaturedImageQuery';

const nextConfig = getConfig();
const DEFAULT_TEMPLATE_NAME = 'default';
const THEME_ID = getConfig().publicRuntimeConfig.themeId as MuiThemeType;

// Dynamic imports
const FullWidthCallToAction = dynamic(
  () => import('@/components/blocks/FullWidthCallToAction')
);

// Client configuration for headless site theme
const getClientConfig = (config: any, themeId: string) => {
  const containerAPIDomain = config.serverRuntimeConfig.wpDomain;
  const publicDefaultAPIDomain = config.publicRuntimeConfig.wpDomain;
  const publicAPIMultiDomain = config.publicRuntimeConfig.wpMultitenantDomain;
  const publicApiDomain =
    publicAPIMultiDomain[themeId] ?? publicDefaultAPIDomain;

  if (!publicAPIMultiDomain) {
    return undefined;
  }

  if (containerAPIDomain) {
    return {
      apiDomain: composeEndpointUri(containerAPIDomain, 'http'),
      wpPublicDomain: publicApiDomain,
    };
  }

  return {
    apiDomain: composeEndpointUri(publicApiDomain, 'https'),
    wpPublicDomain: publicApiDomain,
  };
};

// ===== Component Mappings =====

// Homepage template mapping
const homepageTemplateMapping: Record<string, React.ComponentType<any>> = {
  [MuiThemeType.ANTIQUETRADER]: AntiqueTraderHomepageTemplate,
  [MuiThemeType.ARTSANDCRAFTSHOMES]: ArtsCraftsHomesHomepageTemplate,
  [MuiThemeType.HORTMAG]: HortmagHomepageTemplate,
  [MuiThemeType.NUMISMATICNEWS]: NumismaticNewsHomepageTemplate,
  [MuiThemeType.TRADITIONALBUILDING]: TraditionalBuildingHomepageTemplate,
  [MuiThemeType.TRADITIONALBUILDINGSHOW]:
    TraditionalBuildingShowHomepageTemplate,
  [MuiThemeType.SPORTSCOLLECTORSDIGEST]: SportsCollectorsDigestHomepageTemplate,
  [MuiThemeType.WOODSHOPNEWS]: WoodshopnewsHomepageTemplate,
  [MuiThemeType.WRITERSDIGEST]: WritersDigestHomepageTemplate,
  [MuiThemeType.PERIOD_HOMES]: PeriodHomesHomepageTemplate,
  [MuiThemeType.OLDCARSWEEKLY]: OldsCarWeeklyHomepageTemplate,
};

// Article showcase mapping
const articleShowcaseMapping: Record<string, React.ComponentType<any>> = {
  [MuiThemeType.SPORTSCOLLECTORSDIGEST]: AcfArticleShowcaseSplitLayout,
};

// Article template mapping (used for documentation/reference)
const articleTemplateMapping: Record<string, React.ComponentType<any>> = {
  [MuiThemeType.ARTSANDCRAFTSHOMES]: AchArticleTemplate,
  [MuiThemeType.NUMISMATICNEWS]: NnArticleTemplate,
  [MuiThemeType.WRITERSDIGEST]: WdArticleTemplate,
  [MuiThemeType.SPORTSCOLLECTORSDIGEST]: ScdArticleTemplate,
  [MuiThemeType.TRADITIONALBUILDING]: TbArticleTemplate,
  [MuiThemeType.TRADITIONALBUILDINGSHOW]: TbsArticleTemplate,
  [MuiThemeType.PERIOD_HOMES]: PhArticleTemplate,
};

// Block component mappings by block type
const blockComponentMappings: Record<
  string,
  Record<string, React.ComponentType<any>>
> = {
  AcfTopStoryBlock: {
    [MuiThemeType.ARTSANDCRAFTSHOMES]: AcfTopStoryBlockHeroLayoutACH,
    [MuiThemeType.NUMISMATICNEWS]: AcfTopStoryBlockHeroLayoutNN,
    [MuiThemeType.SCRIPTMAG]: AcfTopStoryBlockArticleLayout,
    [MuiThemeType.TRADITIONALBUILDING]: AcfTopStoryBlockHeroLayout,
    [MuiThemeType.PERIOD_HOMES]: AcfTopStoryBlockHeroLayoutPH,
    [MuiThemeType.WOODSHOPNEWS]: AcfTopStoryBlockHeroLayoutWSN,
    [MuiThemeType.WRITERSDIGEST]: AcfTopStoryBlockArticleExcerptLayout,
    [MuiThemeType.OLDCARSWEEKLY]: AcfTopStoryBlockHeroLayoutOW,
  },
};

// Article list layout mappings for all themes
const articleListLayoutMappings: Record<string, React.ComponentType<any>> = {
  'hortmag-simple-grid': SimpleGridLayout,
  'hortmag-circular-card': CircularCardLayout,
  'scriptmag-main-grid': MainArticleGridLayout,
  'scriptmag-main-recent-grid': MainRecentArticleGridLayout,
  'scriptmag-main-showcase': AcfArticleShowcaseLayout as any,
  'article-list-with-ads-and-featured': ArticleGridAdsAndFeaturedLayout,
  'gallery-and-ads': ArticlesGalleryAndAdsLayout,
  'gallery-split': ArticlesGallerySplitLayout,
  'gallery-quad-split': ArticlesGalleryQuadSplitLayout,
  'tbs-article-grid': TbsArticleGridLayout,
  'wsn-article-showcase': AcfArticleShowcaseLayoutWSN as any,
  'wsn-article-showcase-sq': AcfArticleShowcaseLayoutSquaredWSN as any,
  'wsn-article-list-ads-features': WsnArticleGridAdsAndFeaturedLayout,
  'wd-article-grid-ads': WdArticleGridAdsLayout,
  'wd-article-grid-no-ads': WdArticleGridNoAdsLayout,
  'wd-recent-article-grid': WdRecentArticleGridLayout,
  'ach-circular-card': AchCircularCardLayout,
  'ach-article-grid': AchArticleGridLayout,
  'ach-article-grid-3-col': AchArticleGridLayout3col,
  'ach-article-grid-3-col-ex': AchArticleGridLayout3colExcerpt,
  'mt-article-grid-ads': MtArticleGridAdsLayout,
  'mt-article-grid-ads-mv': MtMvArticleGridAdsLayout,
  'nn-articles-list': NnArticlesListLayout,
  'nn-articles-list-4-col': NnArticlesList4colLayout,
  'nn-recent-article-grid': NnRecentArticleGridLayout,
  'nn-recent-articles-grid': NnRecentArticlesGridLayout,
  'ow-recent-grid': OwMainRecentArticleGridLayout,
};

// Directory template content mapping
interface DirectoryTemplateConfig {
  rootContent: React.ComponentType<any>;
  childContent: React.ComponentType<any>;
  paths: string[];
  template?: React.ComponentType<any>; // Optional shared template wrapper
}

const directoryMapping: Record<string, DirectoryTemplateConfig> = {
  [MuiThemeType.ANTIQUETRADER]: {
    paths: ['/directory-antique-businesses'],
    template: DirectoryTemplate,
    rootContent: AtDirectoryTemplateContent,
    childContent: AtDirectoryTemplateContent,
  },
  [MuiThemeType.ARTSANDCRAFTSHOMES]: {
    paths: ['/products-and-services-directory'],
    template: DirectoryTemplate,
    rootContent: AchDirectoryTemplateContent,
    childContent: AchDirectoryChildTemplateContent,
  },
  [MuiThemeType.MILITARYTRADER]: {
    paths: ['/militaria-collecting-101/militaria-dealer-directory'],
    template: DirectoryTemplate,
    rootContent: MtDirectoryTemplateContent,
    childContent: MtDirectoryTemplateContent,
  },
  [MuiThemeType.NUMISMATICNEWS]: {
    paths: ['/directory'],
    template: DirectoryTemplate,
    rootContent: DirectoryTemplateContent,
    childContent: DirectoryTemplateContent,
  },
  [MuiThemeType.SPORTSCOLLECTORSDIGEST]: {
    paths: ['/dealer-directory'],
    template: DirectoryTemplate,
    rootContent: ScdDirectoryTemplateContent,
    childContent: ScdDirectoryTemplateContent,
  },
  [MuiThemeType.TRADITIONALBUILDING]: {
    paths: ['/buying-guides'],
    template: DirectoryTemplate,
    rootContent: TbDirectoryTemplateContent,
    childContent: DirectoryTemplateContent,
  },
  [MuiThemeType.PERIOD_HOMES]: {
    paths: ['/buying-guides'],
    template: DirectoryTemplate,
    rootContent: PhDirectoryTemplateContent,
    childContent: PhDirectoryChildTemplateContent,
  },
};

// ===== Helper Functions =====

// Get the homepage template for a theme ID
const getHomepageTemplateByThemeId = (
  themeId: string
): React.ComponentType<any> => {
  return homepageTemplateMapping[themeId] || DefaultHomepageTemplate;
};

// Get a custom block component for a theme ID if available
const getBlockComponentByThemeId = (blockType: string, themeId: string) => {
  const blockMapping = blockComponentMappings[blockType];
  const component = blockMapping?.[themeId];

  return {
    component,
    isCustom: component !== undefined,
  };
};

// Get the article showcase component for a theme ID
const getArticleShowcaseComponent = (
  themeId: string
): React.ComponentType<any> => {
  return articleShowcaseMapping[themeId] || AcfArticleShowcaseLayout;
};

// Determine page template based on URI and theme ID
const getPageTemplateForUri = (page: any, themeId: string) => {
  // Homepage for root URI across all themes
  if (page.uri === '/') {
    return getPageTemplateByName(getTheme(themeId), 'Homepage');
  }

  // Special case for WritersDigest theme only
  if (
    themeId === MuiThemeType.WRITERSDIGEST &&
    page.uri.startsWith('/wd-competitions')
  ) {
    return getPageTemplateByName(getTheme(themeId), 'wd-page');
  }

  // Default template for all other pages
  return getPageTemplateByName(getTheme(themeId), DEFAULT_TEMPLATE_NAME);
};

// ===== Theme Configuration =====

// Headless site theme configuration.
export const getTheme = (themeId: string): ITheme => {
  // Get custom block components based on theme ID
  const topStoryBlock = getBlockComponentByThemeId('AcfTopStoryBlock', themeId);

  // Build the components object only for custom components
  const componentsObj: Record<string, any> = {};
  if (topStoryBlock.isCustom) {
    componentsObj.AcfTopStoryBlock = topStoryBlock.component;
  }

  // Include components object only if not empty
  const blocksConfig =
    Object.keys(componentsObj).length > 0 ? { components: componentsObj } : {};

  // Define article template options
  const articleTemplateOptions = {
    variant: 'small',
    disableFeaturedImage: true,
  };

  return createTheme({
    ...elementsTheme,
    _document: {
      Head: DocumentHead,
    },
    Header: getThemeHeader(themeId),
    Footer,
    extend: {
      blocks: [
        {
          name: 'acf/full-width-call-to-action',
          component: FullWidthCallToAction,
          fragment: acfFullWidthCallToActionBlockFragment,
        },
        {
          name: 'acf/categories-grid',
          component: AcfCategoriesGridBlock as any,
          fragment: acfCategoriesGridBlockFragment,
        },
        {
          name: 'acf/scriptmag-articles-showcase',
          component: getArticleShowcaseComponent(themeId),
          fragment: acfScriptmagArticlesShowcaseFragment,
        },
        {
          name: 'google-ad-tag',
          component: GoogleAdTagBlock,
        },
      ],
      queries: {
        global: [headerCardsQuery],
      },
    },
    blocks: blocksConfig,
    muiTheme: getMuiTheme(themeId),
    graphqlClient: getClientConfig(nextConfig, themeId),
    redisCache: {
      keyPrefix:
        nextConfig.serverRuntimeConfig?.multitenantRedisKeyPrefix?.[themeId] ??
        nextConfig.serverRuntimeConfig?.redisKeyPrefix,
    },
    options: {
      allowEmptyCategories: true,
      disqusSiteIdentifier:
        nextConfig.publicRuntimeConfig?.disqusSiteIdentifier[themeId],
      adConfig: nextConfig.publicRuntimeConfig?.adConfig[themeId] || {
        networkId: 0,
        domain: '',
      },
      articleListBlockCustomLayouts: articleListLayoutMappings,
    },
    pageTypes: {
      // Default to the base page types
      ...elementsTheme.pageTypes,

      article: {
        templates: [
          {
            name: DEFAULT_TEMPLATE_NAME,
            component: ArticleTemplate,
            options: articleTemplateOptions,
            queries: [postCommentsQuery, postAimFeaturedImageQuery],
          },
          {
            name: MuiThemeType.ARTSANDCRAFTSHOMES,
            component: AchArticleTemplate,
            options: articleTemplateOptions,
            queries: [postCommentsQuery, postAimFeaturedImageQuery],
          },
          {
            name: MuiThemeType.WRITERSDIGEST,
            component: WdArticleTemplate,
            options: articleTemplateOptions,
            queries: [postCommentsQuery, postAimFeaturedImageQuery],
          },
          {
            name: MuiThemeType.NUMISMATICNEWS,
            component: NnArticleTemplate,
            options: articleTemplateOptions,
            queries: [postCommentsQuery, postAimFeaturedImageQuery],
          },
          {
            name: MuiThemeType.SPORTSCOLLECTORSDIGEST,
            component: ScdArticleTemplate,
            options: articleTemplateOptions,
            queries: [postCommentsQuery, postAimFeaturedImageQuery],
          },
          {
            name: MuiThemeType.TRADITIONALBUILDINGSHOW,
            component: TbsArticleTemplate,
            options: {
              ...articleTemplateOptions,
            },
            queries: [postCommentsQuery, postAimFeaturedImageQuery],
          },
        ],
        // @ts-ignore
        getTemplate: (article) => {
          const template = getArticleTemplate(article, getTheme(themeId));

          // Get the theme configuration
          const themeConfig = getTheme(themeId);

          // Find the theme-specific template configuration from the templates array
          const themeTemplateConfig =
            themeConfig.pageTypes.article.templates.find(
              (t) => t.name === themeId
            );

          // Get the default template configuration
          const defaultTemplateConfig =
            themeConfig.pageTypes.article.templates.find(
              (t) => t.name === DEFAULT_TEMPLATE_NAME
            );

          // If we have a theme-specific template, use its options
          if (themeTemplateConfig) {
            return {
              ...template,
              component: articleTemplateMapping[themeId] || ArticleTemplate,
              options: {
                ...defaultTemplateConfig?.options,
                ...template.options,
                ...themeTemplateConfig.options,
              },
            };
          }

          // Default fallback
          return {
            ...template,
            component: articleTemplateMapping[themeId] || ArticleTemplate,
            options: {
              ...defaultTemplateConfig?.options,
              ...template.options,
            },
          };
        },
      },
      category: {
        templates: [
          {
            name: DEFAULT_TEMPLATE_NAME,
            component: CategoryTemplate,
            options: {
              subcategoryTagVariant: 'large',
            },
          },
          {
            name: MuiThemeType.ARTSANDCRAFTSHOMES,
            component: DirectoryTemplate,
            queries: [directoryCategoriesQuery],
            options: {
              subcategoryTagVariant: 'large',
            },
          },
          {
            name: MuiThemeType.PERIOD_HOMES,
            component: CategoryTemplate,
            queries: [directoryCategoriesQuery],
            options: {
              subcategoryTagVariant: 'large',
              sx: {
                [`[data-rawhtml="1"] p > a`]: {
                  color: '#a00000 !important',
                  textDecorationColor: '#a00000 !important',
                  transition: '.2s ease',
                  '&:hover': {
                    filter: 'brightness(1.6)',
                  },
                },
              },
            },
          },
          {
            name: MuiThemeType.WOODSHOPNEWS,
            component: CategoryTemplate,
            options: {
              subcategoryTagVariant: 'large',
              sx: {
                [`a.MuiTypography-root.MuiTypography-overline`]: {
                  color: 'text.primary',
                },
              },
            },
          },
        ],
        getTemplate: (category) => {
          // Get the theme configuration
          const themeConfig = getTheme(themeId);

          // Find the theme-specific template configuration from the templates array
          const themeTemplateConfig =
            themeConfig.pageTypes.category.templates.find(
              (t) => t.name === themeId
            );

          // Get the default template configuration
          const defaultTemplateConfig =
            themeConfig.pageTypes.category.templates.find(
              (t) => t.name === DEFAULT_TEMPLATE_NAME
            );

          // Get the base template
          const template = getCategoryTemplate(
            category,
            defaultThemeSettings,
            themeConfig
          );

          // Check if the current theme has directory configuration
          const directoryConfig = directoryMapping[themeId];

          // Check if this is a directory path that should use special templates
          if (directoryConfig) {
            const matchingPath = directoryConfig.paths.find((path) =>
              category.category.uri?.includes(path)
            );

            if (matchingPath) {
              // Add null check before using replace
              const pathAfterDirectory = category.category.uri
                ? category.category.uri.replace(matchingPath, '')
                : '';
              const segments = pathAfterDirectory.split('/').filter(Boolean);

              // Use the shared template if configured, otherwise use AchDirectoryTemplate
              const TemplateComponent =
                directoryConfig.template || DirectoryTemplate;

              // Root directory category
              if (segments.length === 0) {
                return {
                  ...template,
                  component: TemplateComponent,
                  // Pass the content component as a prop that will be used by the template
                  options: {
                    ...defaultTemplateConfig?.options,
                    ...template.options,
                    contentComponent: directoryConfig.rootContent,
                  },
                  queries: [
                    directoryCategoriesQuery,
                    directoryPostsQuery,
                    directoryFeaturedPostsQuery,
                    directorySponsoredPostsQuery,
                  ],
                };
              }

              // Child directory category
              if (segments.length >= 1) {
                return {
                  ...template,
                  component: TemplateComponent,
                  options: {
                    ...defaultTemplateConfig?.options,
                    ...template.options,
                    contentComponent: directoryConfig.childContent,
                  },
                  queries: [
                    directoryPostsQuery,
                    directoryFeaturedPostsQuery,
                    directorySponsoredPostsQuery,
                  ],
                };
              }
            }
          }

          // For non-directory pages or when no directory match is found,
          // apply theme-specific category template if available
          if (themeTemplateConfig) {
            return {
              ...template,
              component: themeTemplateConfig.component,
              options: {
                ...defaultTemplateConfig?.options,
                ...template.options,
                ...themeTemplateConfig.options,
              },
            };
          }

          // Default fallback
          return {
            ...template,
            options: {
              ...defaultTemplateConfig?.options,
              ...template.options,
            },
          };
        },
      },

      page: {
        templates: [
          {
            name: DEFAULT_TEMPLATE_NAME,
            component: ElementsDefaultPageTemplate,
          },
          {
            name: 'Homepage',
            component: getHomepageTemplateByThemeId(themeId),
          },
          {
            name: 'wd-page',
            component: WritersdigestPageTemplate,
          },
        ],
        getTemplate: ((page) => {
          return getPageTemplateForUri(page, themeId);
        }) as GetPageTemplate,
      },
    },
  });
};

const theme = getTheme(THEME_ID);

export default theme;
