import { crimsonText, lato } from '../utils/localFonts';
import { getDefaultTheme } from './default-theme/default-theme';

export default getDefaultTheme({
  color: {
    primary: '#727347',
    secondary: '#9a7537',
    error: '#ea252a',
    brand: {
      main: '#727347',
      text: '#fff',
    },
    text: {
      primary: '#333333',
      secondary: '#777777',
    },
  },
  font: {
    base: `${lato.style.fontFamily}`,
    sans: `${lato.style.fontFamily}`,
    serif: `${crimsonText.style.fontFamily}`,
  },
});
