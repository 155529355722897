import React, { useMemo } from 'react';

import { DisablePrefetchLink } from '@orgnc/core/components/atoms';
import {
  FeaturedImage,
  FeaturedImageButton,
} from '@orgnc/core/components/themes/Elements/components';
import { Box, Link, useTheme } from '@mui/material';
import { IArticleGridProps } from '@orgnc/core/components/blocks/AcfArticleListBlock/Layouts';

const WdArticleGridNoAdsLayout: React.FC<IArticleGridProps> = ({
  hasImageLazyLoad = true,
  posts,
}) => {
  const theme = useTheme();

  const primaryPosts = useMemo(() => {
    if (Array.isArray(posts)) {
      if (posts.length > 0) {
        return (
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: {
                xs: '1fr',
                sm: 'repeat(3, 1fr)',
              },
              gap: 3,
              padding: 0,
              mb: '4rem',
            }}
          >
            {posts.map((post) => {
              // Render the post item
              const postItem = (
                <DisablePrefetchLink
                  key={post.databaseId}
                  href={post.uri || ''}
                  passHref
                >
                  <Box
                    component="article"
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'flex-start',
                      position: 'relative',
                      boxSizing: 'border-box',
                    }}
                  >
                    {post.featuredImage?.node?.sourceUrl && (
                      <FeaturedImageButton>
                        <FeaturedImage
                          ratio="16:9"
                          loading={hasImageLazyLoad ? 'lazy' : 'eager'}
                          src={post.featuredImage.node.sourceUrl}
                        />
                      </FeaturedImageButton>
                    )}
                    <Box
                      sx={{
                        py: 0,
                        color: 'text.primary',
                      }}
                    >
                      {post?.title && (
                        <Box sx={{ mt: 2.5, mb: 1.5, lineHeight: 0.5 }}>
                          <DisablePrefetchLink href={post?.uri || '#'} passHref>
                            <Link
                              dangerouslySetInnerHTML={{ __html: post.title }}
                              variant="h4"
                              fontWeight={400}
                              sx={{
                                textDecoration: 'none',
                                color: 'inherit',
                                fontFamily:
                                  theme.typography?.fonts?.serif?.primary,
                                lineHeight: 1,
                                fontSize: '16px',
                                transition: 'color 0.3s ease-in-out',
                                '&:hover': {
                                  color: 'secondary.main',
                                },
                              }}
                            />
                          </DisablePrefetchLink>
                        </Box>
                      )}
                    </Box>
                  </Box>
                </DisablePrefetchLink>
              );

              // For all other posts, just render the post
              return postItem;
            })}
          </Box>
        );
      }
    }

    return null;
  }, [hasImageLazyLoad, posts, theme.typography?.fonts?.serif?.primary]);

  if (!primaryPosts) return null;

  return primaryPosts;
};

export default WdArticleGridNoAdsLayout;
