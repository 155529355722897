import { gql } from 'graphql-request';
import { GlobalQuery } from '@orgnc/core/lib/themes/queries';
import { IHeaderCardsQuery } from '@orgnc/core/generated/graphql-operations';
import mediaFragment from '@orgnc/core/lib/wordpress/api/fragments/mediaFragment';

const headerCardFragment = gql`
  fragment HeaderCardFragment on AcfOptionsGeneral_Generaloptions_Cards_headerCards {
    layout
    side
    titleGroup {
      text
      link
    }
    subtitleGroup {
      text
      link
    }
    imageGroup {
      image {
        ...MediaFragment
      }
      link
    }
  }
  ${mediaFragment}
`;

export const query = gql`
  query HeaderCardsQuery {
    acfOptionsGeneral {
      generalOptions {
        cards {
          showHeaderCards
          headerCards {
            ...HeaderCardFragment
          }
        }
      }
    }
  }
  ${headerCardFragment}
`;

const headerCardsQuery: GlobalQuery<IHeaderCardsQuery> = {
  name: 'HeaderCardsQuery',
  query,
};

export default headerCardsQuery;
