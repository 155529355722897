'use client';

import React, { memo, useEffect } from 'react';
import Script from 'next/script';

import _isArray from 'lodash/isArray';
import _cloneDeep from 'lodash/cloneDeep';
import { SxProps } from '@mui/system';
import { Box, useTheme } from '@mui/material';
import { useResolveUrl, useSiteSpecificComponents } from '@orgnc/core/hooks';
import { useRouter } from 'next/router';
import { IPostFullFragment } from '@orgnc/core/lib/wordpress/api/fragments/postBlocksFragment';
import { ICategoryWithPostsFragment } from '@orgnc/core/lib/wordpress/api/queries/categoryWithPosts';
import Slot = googletag.Slot;
import GeneralSize = googletag.GeneralSize;
import SizeMappingArray = googletag.SizeMappingArray;

const GoogleAdTag = memo(
  ({
    zone,
    sizes,
    mapping,
    frequency,
    preserveSpace = true,
  }: {
    zone: string;
    preserveSpace?: boolean;
    sizes?: GeneralSize;
    tags?: string[];
    mapping?: SizeMappingArray;
    frequency?: string;
  }) => {
    const { options } = useSiteSpecificComponents();
    // @ts-ignore
    const networkId = options?.adConfig?.networkId || '';
    // @ts-ignore
    const domain = options?.adConfig?.domain || '';
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const theme = useTheme();

    const { asPath } = useRouter();
    const { node } = useResolveUrl();

    let category = '';

    if (node) {
      if (asPath === '/') {
        category = 'homepage';
      } else if (node.type === 'Post') {
        const post = node as IPostFullFragment;
        if (post.primaryCategory) {
          category =
            post.primaryCategory.uri?.replace(/\/$/, '').replace(/^\//, '') ||
            '';
        }
      } else if (node.type === 'Category') {
        const categoryNode = node as ICategoryWithPostsFragment;
        category =
          categoryNode.uri?.replace(/\/$/, '').replace(/^\//, '') || '';
      }
    }

    const divId = `div-gpt-${category.replace('/', '')}-${zone}-${
      frequency || '0'
    }`;

    const sortedMapping = _cloneDeep(mapping);
    if (_isArray(sortedMapping) && sortedMapping?.length) {
      // [1024, ..], [640, ...]
      sortedMapping.sort();
    }
    let responsiveSlotSx: SxProps = {
      textAlign: 'center',
    };

    if (preserveSpace) {
      let previousViewportWidth = 0;
      if (_isArray(mapping) && mapping?.length) {
        mapping.forEach(([viewport, viewportSizes]) => {
          if (_isArray(viewport) && viewport?.length === 2) {
            const viewportWidth = viewport[0];
            let minHeight = 0;
            if (_isArray(viewportSizes)) {
              viewportSizes.forEach((sizeArr) => {
                if (
                  sizeArr &&
                  _isArray(sizeArr) &&
                  typeof sizeArr[1] === 'number' &&
                  sizeArr[1] > minHeight
                ) {
                  // eslint-disable-next-line prefer-destructuring
                  minHeight = sizeArr[1];
                }
              });
            }
            if (minHeight && !previousViewportWidth) {
              responsiveSlotSx = {
                ...responsiveSlotSx,
                ...{
                  [theme.breakpoints.up(viewportWidth)]: { minHeight },
                },
              };
            } else if (minHeight && previousViewportWidth) {
              responsiveSlotSx = {
                ...responsiveSlotSx,
                ...{
                  // previousViewportWidth is bigger
                  [theme.breakpoints.between(
                    viewportWidth,
                    previousViewportWidth
                  )]: { minHeight },
                },
              };
            }
            previousViewportWidth = viewportWidth;
          }
        });
      }
    }

    if (zone === 'fixed_bottom') {
      responsiveSlotSx = {
        ...responsiveSlotSx,
        ...{
          position: 'fixed',
          bottom: '0px',
          width: '100%',
        },
      };
    }

    useEffect(() => {
      const shared: { adSlot: Slot | null } = {
        adSlot: null,
      };
      const timeout = setTimeout(() => {
        if (!googletag) {
          return;
        }
        if (zone === 'interstitial') {
          console.log(
            'GPT init',
            divId,
            zone,
            sizes,
            mapping,
            category,
            domain,
            networkId
          );
          googletag.cmd.push(() => {
            if (!googletag) {
              return;
            }
            const adSlot = googletag.defineOutOfPageSlot(
              category
                ? `/${networkId}/${domain}/${category}`
                : `/${networkId}/${domain}`,
              googletag.enums.OutOfPageFormat.INTERSTITIAL
            );
            if (adSlot) {
              adSlot.setTargeting('adzone', zone);
              adSlot.addService(googletag.pubads());
              shared.adSlot = adSlot;
              googletag.pubads().setTargeting('path', asPath);
            } else {
              console.error(
                'Cannot define interstitial slot',
                `/${networkId}/${domain}/${category}`
              );
            }
          });
        } else {
          if (!sizes) {
            return;
          }
          console.log(
            'GPT init',
            divId,
            zone,
            sizes,
            mapping,
            category,
            domain,
            networkId
          );
          googletag.cmd.push(() => {
            if (!googletag) {
              return;
            }

            const responsiveAdSlot = googletag.defineSlot(
              category
                ? `/${networkId}/${domain}/${category}`
                : `/${networkId}/${domain}`,
              sizes,
              divId
            );
            if (!responsiveAdSlot) {
              console.error('Cannot define responsive slot', divId);
              return;
            }
            shared.adSlot = responsiveAdSlot;
            responsiveAdSlot.setTargeting('adzone', zone);
            responsiveAdSlot.addService(googletag.pubads());
            if (mapping) {
              responsiveAdSlot.defineSizeMapping(mapping);
            }
            googletag.pubads().setTargeting('path', asPath);
            googletag.enableServices();
            googletag.display(divId);
          });
        }
      }, 100);
      return () => {
        clearTimeout(timeout);
        if (shared.adSlot) {
          // console.log('Destroying slot', shared.adSlot);
          shared.adSlot.clearTargeting('adzone');
          googletag.destroySlots([shared.adSlot]);
        }
      };
    }, [category, divId, domain, mapping, networkId, sizes, zone, asPath]);

    /* Note that while the gpt.js scripts may be duplicated here, their unique ID keeps them from being repeated in the
       DOM. We tried loading it in DocumentHead and on many pages such as category pages it wasn't loading before the
       ad itself, so this is a short term workaround. */

    return (
      <>
        <Script
          strategy="beforeInteractive"
          src="https://securepubads.g.doubleclick.net/tag/js/gpt.js"
        />
        <Script id="ads-load">
          {`
      var googletag = googletag || {};
      googletag.cmd = googletag.cmd || [];
    `}
        </Script>
        {zone === 'interstitial' ? (
          <></>
        ) : (
          <Box id={divId} sx={responsiveSlotSx}></Box>
        )}
      </>
    );
  }
);
GoogleAdTag.displayName = 'GoogleAdTag';

export default GoogleAdTag;
