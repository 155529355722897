import { Box } from '@mui/material';
import { useLayout } from '@orgnc/core/hooks';
import Typography from '@mui/material/Typography';
import AppBar from '@mui/material/AppBar';
import * as React from 'react';
import { Logo } from '@orgnc/core/components/atoms';
import SocialList from '@/components/header/SocialList';
import { useEffect, useRef, useState } from 'react';
import GoogleAdTag from '@/components/GoogleAdTag';
import NavBar from './NavBar';
import { useHeaderCardColumns } from '../../../utils/useHeaderCardColumns';
import HeaderCard, { HeaderCardProps } from '../HeaderCard';

const CARD_STYLES: HeaderCardProps['sx'] = {
  title: {
    color: 'primary.main',
    fontSize: {
      sm: '8px',
      md: '10px',
    },
    transition: 'color 0.3s ease-in-out',
    '&:hover': {
      color: 'secondary.main',
    },
    textAlign: 'center',
    maxWidth: '100px',
  },
  subtitle: {
    color: 'primary.main',
    fontSize: {
      sm: '8px',
      md: '10px',
    },
    transition: 'color 0.3s ease-in-out',
    '&:hover': {
      color: 'secondary.main',
    },
    textAlign: 'center',
    maxWidth: '100px',
  },
  image: {
    margin: '0 auto',
  },
};

const Header = () => {
  const { menus, networks, logos } = useLayout();
  const appBarRef = useRef<HTMLDivElement>(null);
  const [headerHeight, setHeaderHeight] = useState<number>(0);
  const headerCardsByColumn = useHeaderCardColumns();

  useEffect(() => {
    setHeaderHeight(appBarRef?.current?.offsetHeight || 0);
  }, [appBarRef]);

  return (
    <>
      <AppBar
        ref={appBarRef}
        position="relative"
        sx={{
          backgroundColor: 'common.white',
          zIndex: ({ zIndex }) => zIndex.drawer - 1,
          mb: 4,
        }}
      >
        <Box
          sx={{
            justifyContent: 'space-between',
            mt: 1,
            mx: 2,
            display: {
              xs: 'none',
              sm: 'flex',
            },
            a: {
              textDecoration: 'unset',
              color: 'secondary.main',
            },
          }}
        >
          <SocialList networks={networks} size={'small'} />
          <Box
            sx={{
              display: 'flex',
              gap: '1rem',
            }}
          >
            {menus.HEADER_TOP_NAVIGATION?.map(
              (item) =>
                item.path && (
                  <Typography
                    key={`${item.label}.${item.path}`}
                    variant="body1"
                    component="a"
                    href={item.path}
                    // @ts-ignore - Property 'target' exists at runtime but not in type definition
                    target={item.target}
                    // @ts-ignore - Property 'target' exists at runtime but not in type definition
                    rel={item.target ? 'noopener' : undefined}
                    sx={{
                      transition: 'opacity 0.3s ease-in-out',
                      '&:hover': {
                        opacity: '.6',
                      },
                    }}
                  >
                    {item.label}
                  </Typography>
                )
            )}
          </Box>
        </Box>
        <Box
          sx={{
            display: {
              xs: 'none',
              sm: 'flex',
            },
            maxWidth: '100%',
            mx: 0,
            pr: 9,
            my: {
              md: 2,
              sm: 0,
            },
            width: '100%',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Box sx={{ display: 'flex', gap: 2 }}>
            {headerCardsByColumn?.left.map((card, idx) => (
              <HeaderCard
                key={`left-${idx}`}
                headerCard={card}
                sx={CARD_STYLES}
              />
            ))}
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              maxWidth: {
                md: '700px',
                sm: '70%',
              },
            }}
          >
            {logos.main?.sourceUrl && (
              <Logo
                sourceUrl={logos.main.sourceUrl}
                width={700}
                height={144}
                title={logos.main.altText}
                sx={{
                  maxWidth: {
                    sm: '100%',
                    md: '700px',
                  },
                  width: '100%',
                  display: 'block',
                  '& img': {
                    width: '100%',
                    height: 'auto',
                    objectFit: 'contain',
                    maxWidth: '100%',
                  },
                }}
              />
            )}
          </Box>
          <Box sx={{ display: 'flex', gap: 2 }}>
            {headerCardsByColumn?.right.map((card, idx) => (
              <HeaderCard
                key={`right-${idx}`}
                headerCard={card}
                sx={{
                  ...CARD_STYLES,
                  image: {
                    width: {
                      sm: '60px',
                      md: '110px',
                    },
                    height: '170px',
                  },
                }}
                aspectRatio="2:3"
              />
            ))}
          </Box>
        </Box>
        <NavBar
          menu={menus.MAIN_NAVIGATION}
          categoriesMenu={menus.HEADER_NAVIGATION}
          networks={networks}
          additionalMenu={menus.FOOTER_NAVIGATION}
          logo={logos.main || undefined}
          headerHeight={headerHeight}
        />
      </AppBar>
      <GoogleAdTag
        zone="header"
        sizes={[
          [728, 90],
          [970, 90],
        ]}
        mapping={[
          [
            [1024, 768],
            [
              [728, 90],
              [970, 90],
              [970, 250],
            ],
          ],
          [[768, 0], [[728, 90]]],
          [
            [0, 0],
            [
              [300, 50],
              [300, 100],
            ],
          ],
        ]}
      />
      <GoogleAdTag zone="interstitial" />
    </>
  );
};

export default Header;
