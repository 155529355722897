import { Box, useTheme } from '@mui/material';

import { ICategoryTemplateProps } from '@orgnc/core/lib/themes/templates/category';

import { CenterLayout, StackLayout } from '@orgnc/core/components/primitives';
import DirectoryTemplateContent from './default/DirectoryTemplateContent';

// Extend ICategoryTemplateProps to include contentComponent
interface ExtendedCategoryTemplateProps extends ICategoryTemplateProps {
  options?: {
    contentComponent?: React.ComponentType<ICategoryTemplateProps>;
    sx?: any;
    [key: string]: any;
  };
}

const DirectoryTemplate = ({
  options = {},
  ...otherProps
}: ExtendedCategoryTemplateProps) => {
  const theme = useTheme();

  // Use the content component passed in options, or fall back to the default
  const ContentComponent = options.contentComponent || DirectoryTemplateContent;

  return (
    <Box sx={{ pt: 5, ...(options?.sx ?? {}) }}>
      <StackLayout space={theme.spacing(4)}>
        <CenterLayout
          maxWidth={`${theme.breakpoints.values.xl}${theme.breakpoints.unit}`}
          sx={{ width: '100%' }}
        >
          <ContentComponent {...otherProps} options={options} />
        </CenterLayout>
      </StackLayout>
    </Box>
  );
};

export default DirectoryTemplate;
