import { useTheme } from '@mui/material';
import React from 'react';
import { IPageTemplateProps } from '@orgnc/core/lib/themes/templates/page';
import { CenterLayout } from '@orgnc/core/components/primitives';
import { PageContent } from '@orgnc/core/components/molecules';
import RegisteredBlockType from '@orgnc/core/components/blocks/Blocks/types';
import { NewsletterSignupBlock } from '@orgnc/core/components/themes/Elements/components/blocks';

const HortmagHomepageTemplate = ({ page }: IPageTemplateProps): JSX.Element => {
  const { breakpoints } = useTheme();
  return (
    <>
      <CenterLayout maxWidth={`${breakpoints.values.xl}${breakpoints.unit}`}>
        <PageContent
          blocks={page.blocks}
          sx={{
            my: 6,
            /**
             * FLOW CONTROL FOR ELEMENT SPACING
             *
             * Objective:
             * - To establish a consistent vertical spacing between direct sibling child elements within a container.
             * This ensures an organized flow of content and visual separation between distinct elements
             *
             * Caution:
             * Any modification to this spacing should ensure content integrity and maintain a balanced visual layout.
             */
            [`
                [data-og-block-type="${RegisteredBlockType.AcfListArticleBlock}"], 
                [data-og-block-type="${RegisteredBlockType.AcfArticleListBlock}"], 
                [data-og-block-type="${RegisteredBlockType.AcfArticleGridBlock}"]
            `]: {
              mx: 'auto',
              px: {
                xs: 2,
                sm: 3,
              },
            },
            [`
                [data-og-block-type="core/heading"]
            `]: {
              px: 3,
              mb: 2,
            },
            [`
                [data-og-block-type="core/paragraph"]
            `]: {
              px: 3,
              mb: 2,
              maxWidth: 'unset',
            },
            [`
                [data-og-block-type="core/paragraph"] p
            `]: {
              maxWidth: 'unset',
              fontSize: {
                md: '23px',
                xs: '18px',
              },
            },
          }}
          includeContentBlockStyling
        />
      </CenterLayout>
      <NewsletterSignupBlock />
    </>
  );
};

export default HortmagHomepageTemplate;
