import { crimsonText } from '../utils/localFonts';
import { getDefaultTheme } from './default-theme/default-theme';

export default getDefaultTheme({
  color: {
    primary: '#e55b2d',
    secondary: '#e55b2d',
    error: '#ea252a',
    brand: {
      main: '#e55b2d',
      text: '#fff',
    },
    text: {
      primary: '#29331a',
      secondary: '#777777',
    },
  },
  font: {
    base: `"Arial"`,
    sans: `"Arial"`,
    serif: `${crimsonText.style.fontFamily}`,
  },
});
