'use client';

import { useCallback, useEffect } from 'react';
import { useRouter } from 'next/router';

function NavigationChangeHandler() {
  const router = useRouter();
  const refreshAds = useCallback(() => {
    setTimeout(() => {
      window.googletag.cmd.push(() => {
        // Refreshing ads after route change
        window.googletag.pubads().refresh();
      });
    }, 1000);
  }, []);
  useEffect(() => {
    router.events.on('routeChangeComplete', refreshAds);
    return () => {
      router.events.off('routeChangeComplete', refreshAds);
    };
  }, [refreshAds, router]);
  return null; // This component doesn't render anything
}

export default NavigationChangeHandler;
