import { gql } from 'graphql-request';

const acfScriptmagArticlesShowcaseFragment = gql`
  fragment AcfScriptmagArticlesShowcaseBlockFragment on AcfScriptmagArticlesShowcaseBlock {
    acfScriptmagArticlesShowcase {
      items {
        image {
          sourceUrl
          title
          mediaDetails {
            width
            height
          }
        }
        subtitle
        title
        url
      }
    }
  }
`;

export default acfScriptmagArticlesShowcaseFragment;
