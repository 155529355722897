import { Box } from '@mui/material';
import { useLayout } from '@orgnc/core/hooks';
import Typography from '@mui/material/Typography';
import AppBar from '@mui/material/AppBar';
import * as React from 'react';
import { Logo } from '@orgnc/core/components/atoms';
import SocialList from '@/components/header/SocialList';
import { useEffect, useRef, useState } from 'react';
import NavBar from './NavBar';
import { useHeaderCardColumns } from '../../../utils/useHeaderCardColumns';
import HeaderCard, { HeaderCardProps } from '../HeaderCard';

const CARD_STYLES: HeaderCardProps['sx'] = {
  title: {
    color: 'brand.text',
    opacity: 0.5,
    fontSize: {
      sm: '8px',
      lg: '10px',
    },
    fontWeight: '700',
    lineHeight: 1.2,
    textDecoration: 'none',
  },
  subtitle: {
    color: 'brand.text',
    fontSize: {
      sm: '12px',
      lg: '14px',
    },
    lineHeight: 1,
    textDecoration: 'none',
  },
};

const Header = () => {
  const { menus, networks, logos, newsletter } = useLayout();
  const appBarRef = useRef<HTMLDivElement>(null);
  const [headerHeight, setHeaderHeight] = useState<number>(0);
  const headerCardsByColumn = useHeaderCardColumns();

  useEffect(() => {
    setHeaderHeight(appBarRef?.current?.offsetHeight || 0);
  }, [appBarRef]);

  return (
    <>
      <AppBar
        ref={appBarRef}
        position="relative"
        sx={{
          backgroundColor: ({ palette }) => palette.brand.header,
          zIndex: ({ zIndex }) => zIndex.drawer - 1,
        }}
      >
        <Box
          sx={{
            justifyContent: 'space-between',
            mt: 1,
            mx: 2,
            display: {
              xs: 'none',
              sm: 'flex',
            },
            a: {
              textDecoration: 'unset',
              color: 'secondary.main',
            },
          }}
        >
          <SocialList networks={networks} />
          <Box
            sx={{
              display: 'flex',
              gap: '1rem',
            }}
          >
            {menus.HEADER_TOP_NAVIGATION?.map(
              (item) =>
                item.path && (
                  <Typography
                    key={`${item.label}.${item.path}`}
                    component="a"
                    href={item.path}
                    // @ts-ignore - Property 'target' exists at runtime but not in type definition
                    target={item.target}
                    // @ts-ignore - Property 'target' exists at runtime but not in type definition
                    rel={item.target ? 'noopener' : undefined}
                    sx={{
                      transition: 'color 0.3s ease-in-out',
                      '&:hover': {
                        color: 'secondary.light',
                      },
                      fontSize: 15,
                    }}
                  >
                    {item.label}
                  </Typography>
                )
            )}
          </Box>
        </Box>
        <Box
          sx={{
            display: {
              xs: 'none',
              sm: 'flex',
            },
            maxWidth: 1300,
            mx: 'auto',
            px: 2,
            py: 2,
            width: '100%',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          {headerCardsByColumn && headerCardsByColumn.left.length > 0 && (
            <Box sx={{ display: 'flex', mr: { sm: 0, lg: 25 } }}>
              {headerCardsByColumn?.left.map((card, idx) => (
                <HeaderCard
                  key={`left-${idx}`}
                  headerCard={card}
                  sx={CARD_STYLES}
                />
              ))}
            </Box>
          )}
          <Box
            sx={{
              display: 'flex',
              mr: {
                sm: 0,
                lg: 25,
              },
            }}
          >
            <Box sx={{ width: { sm: 250, md: 345, lg: 458 }, display: 'flex' }}>
              {logos.main?.sourceUrl && (
                <Logo
                  sourceUrl={logos.main.sourceUrl}
                  width={458}
                  height="100%"
                  title={logos.main.altText}
                  sx={{}}
                />
              )}
            </Box>
          </Box>
          {headerCardsByColumn && headerCardsByColumn.right.length > 0 && (
            <Box sx={{ display: 'flex' }}>
              {headerCardsByColumn?.right.map((card, idx) => (
                <HeaderCard
                  key={`right-${idx}`}
                  headerCard={card}
                  sx={CARD_STYLES}
                />
              ))}
            </Box>
          )}
        </Box>
        <NavBar
          menu={menus.MAIN_NAVIGATION}
          categoriesMenu={menus.HEADER_NAVIGATION}
          networks={networks}
          additionalMenu={menus.FOOTER_NAVIGATION}
          newsletter={newsletter}
          logo={logos.main || undefined}
          headerHeight={headerHeight}
        />
      </AppBar>
    </>
  );
};

export default Header;
