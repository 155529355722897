import React, { useMemo } from 'react';

import { DisablePrefetchLink } from '@orgnc/core/components/atoms';
import {
  FeaturedImage,
  FeaturedImageButton,
  PostFormattedDate,
} from '@orgnc/core/components/themes/Elements/components';
import { Box, Link, useTheme } from '@mui/material';
import Typography from '@mui/material/Typography';
import { IArticleGridProps } from '@orgnc/core/components/blocks/AcfArticleListBlock/Layouts';

const SimpleGridLayout: React.FC<IArticleGridProps> = ({
  hasImageLazyLoad = true,
  posts,
}) => {
  const theme = useTheme();
  const primaryPosts = useMemo(() => {
    if (Array.isArray(posts)) {
      if (posts.length > 0) {
        return (
          <Box
            sx={{
              display: 'grid', // Grid layout
              gridTemplateColumns: {
                xs: '1fr', // One column on xs
                sm: 'repeat(3, 1fr)', // Three columns
              },
              gap: 3, // Adjust space between items
              padding: 0, // Remove padding from the list
            }}
          >
            {posts.map((post) => (
              <DisablePrefetchLink
                key={post.databaseId}
                href={post.uri || ''}
                passHref
              >
                <Box
                  component="article"
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    position: 'relative',
                    boxSizing: 'border-box',
                    '&:before': {
                      transition: 'background-color 0.3s ease-in-out',
                      content: '""',
                      position: 'absolute',
                      width: '100%',
                      height: '100%',
                      backgroundColor: 'black',
                      opacity: '.25',
                      display: 'block',
                      zIndex: ({ zIndex }) => zIndex.appBar - 2,
                    },
                  }}
                >
                  {post.featuredImage?.node?.sourceUrl && (
                    <FeaturedImageButton>
                      <FeaturedImage
                        ratio="1:1"
                        loading={hasImageLazyLoad ? 'lazy' : 'eager'}
                        src={post.featuredImage.node.sourceUrl}
                      />
                    </FeaturedImageButton>
                  )}
                  {post.primaryCategory && (
                    <Typography
                      component="a"
                      variant="caption"
                      href={post.primaryCategory.uri || ''}
                      sx={{
                        color: 'brand.text',
                        textDecoration: 'none',
                        textTransform: 'uppercase',
                        transition: 'color 0.3s ease-in-out',
                        '&:hover': {
                          color: 'primary.main',
                        },
                        fontWeight: 700,
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        p: 2,
                        zIndex: ({ zIndex }) => zIndex.appBar - 1,
                      }}
                    >
                      {post.primaryCategory.name}
                    </Typography>
                  )}
                  <Box
                    sx={{
                      position: 'absolute',
                      bottom: 0,
                      left: 0,
                      p: 2,
                      color: 'brand.text',
                      zIndex: ({ zIndex }) => zIndex.appBar - 1,
                    }}
                  >
                    {post?.title && (
                      <div>
                        <DisablePrefetchLink href={post?.uri || '#'} passHref>
                          <Link
                            dangerouslySetInnerHTML={{ __html: post.title }}
                            variant={'h4'}
                            sx={{
                              transition: 'color 0.3s ease-in-out',
                              '&:hover': {
                                color: 'primary.main',
                              },
                              textDecoration: 'none',
                              color: 'inherit',
                              fontSize: '22px',
                              fontFamily:
                                theme.typography?.fonts?.serif?.primary,
                              lineHeight: 1,
                            }}
                          />
                        </DisablePrefetchLink>
                      </div>
                    )}
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      {post?.authorTags?.nodes?.length && (
                        <DisablePrefetchLink
                          href={post?.authorTags.nodes[0]?.uri || '#'}
                          passHref
                        >
                          <Link
                            aria-label={post?.authorTags.nodes[0]?.name || ''}
                            fontWeight={700}
                            variant="caption"
                            sx={{
                              color: 'brand.text',
                              transition: 'color 0.3s ease-in-out',
                              '&:hover': {
                                color: 'primary.main',
                              },
                              textDecoration: 'none',
                              textTransform: 'uppercase',
                            }}
                          >
                            By {post?.authorTags.nodes[0]?.name}
                          </Link>
                        </DisablePrefetchLink>
                      )}
                      <strong style={{ padding: '0 4px' }}>⋅</strong>
                      <PostFormattedDate
                        date={post.date || ''}
                        modified={post.modified || ''}
                        showModifiedDate={
                          Boolean(post?.showModifiedDate) || false
                        }
                        sx={{ color: 'brand.text' }}
                      />
                    </Box>
                  </Box>
                </Box>
              </DisablePrefetchLink>
            ))}
          </Box>
        );
      }
    }

    return null;
  }, [hasImageLazyLoad, posts, theme.typography?.fonts?.serif?.primary]);

  if (!primaryPosts) return null;

  return primaryPosts;
};

export default SimpleGridLayout;
